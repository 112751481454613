import firebaseConfig from "../config/Firebase/firebaseConfig";

const base_url = `https://billecta-dot-${firebaseConfig.projectId}.uc.r.appspot.com`;
const baseOptions = {
  "Content-Type": "application/json",
};

export const createCompany = async (
  userID: string,
  orgNum: string,
  email: string
): Promise<(Response & { data: { id: string; name: string } }) | null> => {
  try {
    const response = await fetch(`${base_url}/debtor`, {
      ...baseOptions,
      ...{
        method: "POST",
        body: JSON.stringify({
          userID,
          orgNum,
          email,
        }),
      },
    });
    return response.json();
  } catch (error) {
    console.log("failed to create company", userID, error);
    return null;
  }
};

export type PaymentType = {
  paymentID?: string;
  bookingID?: string;
  checkoutID?: string;
};

export const createInvoice = async (
  userID: string,
  payment: PaymentType,
  companyID: string
): Promise<Response | null> => {
  try {
    const response = await fetch(`${base_url}/invoice`, {
      ...baseOptions,
      ...{
        method: "POST",
        body: JSON.stringify({
          userID,
          companyID,
          ...payment,
        }),
      },
    });
    return response.json();
  } catch (error) {
    console.log("failed to create company", userID, error);
    return null;
  }
};

export const createGroupInvoice = async (
  userID: string,
  groupID: string,
  groupUserID: string,
  payment: PaymentType,
  companyID: string,
  messageID: string
): Promise<Response | null> => {
  try {
    const response = await fetch(`${base_url}/groups/invoice`, {
      ...baseOptions,
      ...{
        method: "POST",
        body: JSON.stringify({
          userID,
          groupID,
          groupUserID,
          companyID,
          messageID,
          ...payment,
        }),
      },
    });
    return response.json();
  } catch (error) {
    console.log("failed to create company", userID, error);
    return null;
  }
};
