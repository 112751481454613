import { useEffect, useState } from "react";
import { useFirebase } from "../config/context";

export const useQueuePosition = (userID: string) => {
  const firebase = useFirebase();
  const [queuePosition, setQueuePosition] = useState<number | undefined>();

  const onQueueUpdate = (snapshot: firebase.database.DataSnapshot) => {
    if (snapshot.exists()) {
      let tmpQueue: string[] = [];
      snapshot.forEach((doc) => {
        if (doc.key !== null) {
          tmpQueue.push(doc.key)
        }
      })
      let queuePos = tmpQueue.indexOf(userID)
      if (queuePos !== -1) {
        if (queuePos < 5) {
          setQueuePosition(5)
        } else {
          setQueuePosition(queuePos + 1)
        }
      } else {
        setQueuePosition(undefined)
      }
    }
  }

  useEffect(() => {
    firebase.getChatPriorityQueue().on("value", onQueueUpdate)
  }, []);

  return { queuePosition };
};



  