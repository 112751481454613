import cx from "classnames";
import React, {
  ReactElement,
  cloneElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { Collapse } from "react-collapse";
import useClickOutside from "../../../../hooks/useClickOutside";
import styles from "./index.module.css";

export type Item = {
  label: string;
  value: string | number;
};

export interface Props {
  className?: string;
  items: Item[];
  open?: boolean;
  toggleBtn?: ReactElement;
  floating?: boolean; // Should dropdown be absolute
  defaultItem?: Item;
  onSelectItem: (item: Item) => void;
  selectedItem?: Item;
  secondary?: boolean;
  onDeleteItem?: (item: Item) => void;
}

const Dropdown = ({
  items,
  className,
  onSelectItem,
  toggleBtn,
  floating,
  defaultItem,
  selectedItem,
  open = false,
  secondary,
}: Props): ReactElement => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [expanded, setExpanded] = useState(open);
  const [currentItem, setCurrentItem] = useState<Item | undefined>(
    defaultItem ?? items[0]
  );

  useClickOutside(ref, () => {
    if (toggleBtn) setExpanded(false);
  });

  useEffect(() => {
    setExpanded(open);
  }, [open]);

  const selectedLabel = () => {
    if (selectedItem) {
      return selectedItem.label;
    }
    if (currentItem) {
      return currentItem.label;
    }
    return defaultItem?.label;
  };

  return (
    <div ref={ref} className={styles.dropdown}>
      {toggleBtn &&
        cloneElement(toggleBtn, {
          onClick: () => setExpanded(!expanded),
          children: selectedLabel(),
        })}
      <Collapse
        isOpened={expanded}
        theme={{
          collapse: cx(styles.content, className, {
            [styles.floating]: floating,
            [styles.secondary]: secondary,
          }),
        }}
      >
        <ul className={styles.list}>
          {items.map((item) => (
            <li key={item.value}>
              <button
                type="button"
                onClick={() => {
                  if (toggleBtn) {
                    setExpanded(false);
                    setCurrentItem(item);
                  }
                  onSelectItem(item);
                }}
                className={styles.button}
              >
                {item.label}
              </button>
            </li>
          ))}
        </ul>
      </Collapse>
    </div>
  );
};
export default Dropdown;
