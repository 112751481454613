import cx from "classnames";
import React, { InputHTMLAttributes, ReactElement, ReactNode, forwardRef, useContext } from "react";
import RadioGroupContext from "../context";
import styles from "./index.module.css";

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  icon?: ReactNode;
}

const Button = forwardRef<HTMLInputElement, Props>((props, ref): ReactElement => {
  const { className, style, name, children, icon, ...rest } = props;
  const radiogroupContext = useContext(RadioGroupContext);

  return (
    <div className={cx(styles.radio, styles[radiogroupContext?.buttonStyle ?? ""], className)} style={style}>
      <label className={cx(styles.label)}>
        <input ref={ref} className={styles.element} name={name ?? radiogroupContext?.name} {...rest} type='radio' />
        <span className={styles.mark} />
        <span className={styles.children}>
          {icon && <span className={styles.icon}>{icon}</span>}
          {children && <span>{children}</span>}
        </span>
      </label>
    </div>
  );
});

export default Button;
