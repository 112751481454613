import { zodResolver } from "@hookform/resolvers/zod";
import React, { ReactElement, ReactNode } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import CopyManager from "../../../../utils/CopyManager";
import Input from "../../atoms/Input";
import Form, { Props as FormProps } from "../../molecules/Form";
import styles from "./index.module.css";

export const schema = z.object({
  name: z.string().nonempty("Name is required"),
  number: z.number({
    invalid_type_error: "Write a number",
    required_error: "Write a number",
  }),
  expire_date: z.date({
    invalid_type_error: "Invalid date.",
    required_error: "Date is required.",
  }),
  cvv: z.number({
    invalid_type_error: "Write a number",
    required_error: "Invalid cvv",
  }),
});

export type Schema = z.infer<typeof schema>;

export interface Props extends FormProps {
  children?: ReactNode;
  className?: string;
  actions?: ReactNode;
  onComplete: (values: Schema) => void;
}

const CreditCardForm = ({ children, className, onComplete, actions, ...rest }: Props): ReactElement => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Schema>({
    mode: "onTouched",
    resolver: zodResolver(schema),
  });

  return (
    <Form.Styled columns={1} onSubmit={handleSubmit(onComplete)} footer={actions} {...rest}>
      <Input
        label={CopyManager.get("subscription_card_holder_name_placeholder")}
        {...register("name")}
        error={errors.name?.message}
        autoComplete='cc-name'
        x-autocompletetype='cc-name'
      />
      <Input
        label={CopyManager.get("subscription_card_number")}
        {...register("number", { valueAsNumber: true })}
        error={errors.number?.message}
        autoComplete='cc-number'
        x-autocompletetype='cc-number'
        pattern='\d*'
      />
      <Input
        label={CopyManager.get("subscription_card_expiry_date")}
        {...register("expire_date", { valueAsDate: true })}
        type='month'
        error={errors.expire_date?.message}
        autoComplete='cc-exp'
      />
      <Input
        label={CopyManager.get("subscription_card_cvv")}
        {...register("cvv", { valueAsNumber: true })}
        maxLength={4}
        error={errors.cvv?.message}
        autoComplete='cc-csc'
        className={styles.cvv}
      />
    </Form.Styled>
  );
};
export default CreditCardForm;
