import moment from "moment";
import React, { useEffect, useState } from "react";
import { ValueType } from "react-select";
import { useFirebase } from "../../config/Firebase";
import { useTranslations } from "../../hooks/useTranslations";
import { INameToValueMap, Trip } from "../../lib/types";
import {
  IBooking,
  IBookingTraveller,
  ITravellerOption,
} from "../../lib/types/bookings";
import TripInfo from "../TripInfo";
import Dropdown from "../generic/molecules/Dropdown";

interface IBookingListProps {
  bookingsUserID: string | null;
}

const Bookings = (props: IBookingListProps) => {
  const [originalTrips, setOriginalTrips] = useState<Array<Trip>>([]);
  const [trips, setTrips] = useState<Array<Trip>>([]);
  const [travellers, setTravellers] = useState<Array<IBookingTraveller>>([]);
  const [isPrimaryTab, setIsPrimaryTab] = useState<boolean>(true);
  const [selectedTraveller, setSelectedTraveller] = useState<ValueType<
    ITravellerOption,
    false
  > | null>(null);
  const [animate, setAnimate] = useState<boolean>(false);
  const { getCopy } = useTranslations();

  const firebase = useFirebase();

  useEffect(() => {
    setSelectedTraveller(null);
    if (props.bookingsUserID) {
      firebase.getBookings(props.bookingsUserID).on("value", processBookings);
    }
  }, [props.bookingsUserID]);

  const getDepartureTime = (booking: any) => {
    if (booking.flights && booking.flights.length > 0) {
      return booking.flights[0].departure.time;
    }

    if (booking.rental) {
      return booking.rental.pickUpDateWithTime.replace(" ", "T");
    }

    if (booking.hotel) {
      return new Date(booking.hotel.checkInDate).setHours(23, 59, 59, 999);
    }
  };

  const processBookings = (res: firebase.database.DataSnapshot) => {
    const json = res.val();
    if (!json) {
      setTravellers([]);
      setOriginalTrips([]);
      setTrips([]);
      return;
    }

    let trips: Array<Object> = [];
    let sortedTrips: Array<Trip> = [];
    let allTravellers: Array<IBookingTraveller> = [];
    let uniqueTravellers: Array<IBookingTraveller> = [];

    Object.keys(json).forEach((key) => {
      const booking: IBooking = json[key];
      const isReference =
        booking.bookingReference ||
        booking.hotelReference ||
        booking.rentalReference;

      if (!booking.payment || !isReference) {
        return;
      }

      // Trips travellers details
      const travellers = booking.travellers
        ? Object.keys(booking.travellers).map(
            (keyTraveller) => booking.travellers[keyTraveller]
          )
        : [];

      allTravellers = allTravellers.concat(travellers);

      if (booking.trips) {
        trips = trips.concat(
          booking.trips.map((trip) => {
            return {
              bookingId: key,
              flights: trip,
              passengers: travellers,
              bookingReference: booking.bookingReference,
              payment: booking.payment,
            };
          })
        );
      }

      if (booking.rental) {
        trips = trips.concat({
          bookingId: key,
          rental: booking.rental,
          passengers: travellers,
          rentalReference: booking.rentalReference,
          payment: booking.payment,
        });
      }

      if (booking.hotel) {
        trips = trips.concat({
          bookingId: key,
          hotel: booking.hotel,
          passengers: travellers,
          hotelReference: booking.hotelReference,
          payment: booking.payment,
        });
      }
    });

    allTravellers.forEach((traveller) => {
      const key =
        traveller.firstname + traveller.lastname + traveller.birthdate;
      let found = false;
      uniqueTravellers.forEach((uniqueTraveller) => {
        const uniqueKey =
          uniqueTraveller.firstname +
          uniqueTraveller.lastname +
          uniqueTraveller.birthdate;
        if (key === uniqueKey) {
          found = true;
          return;
        }
      });

      if (!found) {
        traveller.key = key;
        uniqueTravellers.push(traveller);
      }
    });

    setTravellers(uniqueTravellers);

    // Sort trips according to date F-R-H
    const timestampBookingPair: INameToValueMap = {};

    trips.forEach((booking) => {
      const depTime = getDepartureTime(booking);
      const depTimeStamp = new Date(depTime).getTime();

      timestampBookingPair[depTimeStamp] = booking;
    });

    sortedTrips = Object.keys(timestampBookingPair)
      .sort((a, b) => (a > b ? 1 : -1))
      .map((key) => timestampBookingPair[key]);

    setOriginalTrips(sortedTrips);
    setTrips(sortedTrips);
  };

  const setCurrentTab = () => {
    setIsPrimaryTab(true);
  };

  const setEarlierTab = () => {
    setIsPrimaryTab(false);
  };

  const toValueOption = (val: IBookingTraveller): ITravellerOption => {
    return {
      label: val.firstname + " " + val.lastname,
      value: val.firstname + val.lastname + val.birthdate,
    };
  };

  const onTravellerChange = (value: ValueType<ITravellerOption, false>) => {
    if (selectedTraveller?.value === value?.value) {
      return;
    }
    setSelectedTraveller(value);

    setAnimate(true);
    setTimeout(() => {
      setAnimate(false);
    }, 100);

    const selectedValue = (value as ITravellerOption).value;

    if (selectedValue === "all") {
      setTrips(originalTrips);
      return;
    }
    let filteredTrips: Array<Trip> = [];
    originalTrips.forEach((trip) => {
      trip.passengers.forEach((passenger) => {
        if (
          passenger.firstname + passenger.lastname + passenger.birthdate ===
          selectedValue
        ) {
          filteredTrips.push(trip);
          return;
        }
      });
    });
    setTrips(filteredTrips);
  };

  const getReturnTime = (trip: Trip, isPrimary: boolean) => {
    if (trip.flights || trip.rental) {
      const returnTime =
        trip.flights && trip.flights.length > 0
          ? moment(trip.flights[trip.flights.length - 1].destination.time)
          : moment(trip.rental && trip.rental.returnDate);

      return isPrimary
        ? returnTime.add(12, "hours").valueOf() > Date.now()
        : returnTime.add(12, "hours").valueOf() < Date.now();
    }

    if (trip.hotel) {
      const tripDate = moment(trip.hotel.checkOutDate);
      return isPrimary
        ? tripDate.valueOf() > Date.now()
        : tripDate.valueOf() < Date.now();
    }
  };

  const onTravellerLinkClicked = (val: IBookingTraveller) => {
    setSelectedTraveller(toValueOption(val));
    onTravellerChange(toValueOption(val));
  };

  let travellerOptions: Array<ITravellerOption> = travellers.map((t) => {
    return toValueOption(t);
  });
  travellerOptions.sort((a, b) => {
    return a.label.localeCompare(b.label);
  });
  const defaultValue: ITravellerOption = {
    value: "all",
    label: getCopy("all_travellers"),
    // key: "all",
  };
  travellerOptions = [defaultValue].concat(travellerOptions);

  const ftrips = trips.filter((t) => {
    return getReturnTime(t, isPrimaryTab);
  });

  return (
    <div className="booking-list">
      <div className="booking-wrapper">
        <div className="booking-tabs">
          <div
            className={isPrimaryTab ? "booking-tab active" : "booking-tab"}
            onClick={setCurrentTab}
          >
            {getCopy("bookings_tab_current")}
          </div>
          <div
            className={isPrimaryTab ? "booking-tab" : "booking-tab active"}
            onClick={setEarlierTab}
          >
            {getCopy("bookings_tab_previous")}
          </div>
          <div className="booking-tab select">
            <Dropdown
              floating
              toggleBtn={
                <button className="booking-tab-toggle">
                  {selectedTraveller
                    ? selectedTraveller.label
                    : defaultValue.label}
                </button>
              }
              items={travellerOptions}
              onSelectItem={(item) =>
                onTravellerChange({
                  label: item.label.toString(),
                  value: item.value.toString(),
                })
              }
              selectedItem={selectedTraveller ? selectedTraveller : undefined}
            />
          </div>
        </div>
        {ftrips.length > 0 ? (
          <ul className="booking-items">
            {ftrips.map((element, index) => (
              <li
                key={index}
                className={animate ? "empty-item" : "booking-item"}
              >
                <TripInfo
                  data={element}
                  onTravellerChange={onTravellerLinkClicked}
                />
              </li>
            ))}
          </ul>
        ) : (
          <div className="empty-notice">
            <div>
              <img
                className="empty-image"
                src={require("../../images/logo.png")}
                alt=""
              />
              <div className="empty-text">
                {getCopy("bookings_tab_empty")}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Bookings;
