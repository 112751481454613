import React, { ReactElement, ReactNode, useRef } from "react";
import useClickOutside from "../../../../hooks/useClickOutside";

import Loader from "../../atoms/Loader";
import Typography from "../../atoms/Typography";
import styles from "./index.module.css";

export interface Props {
  title?: string;
  preamble?: string;
  actions?: ReactNode;
  children?: ReactNode;
  loading?: boolean;
  error?: string | null;
  onClose?: () => void;
}

const Modal = ({ children, title, actions, preamble, onClose, loading = false, error = null }: Props): ReactElement => {
  const ref = useRef<HTMLDivElement | null>(null);

  useClickOutside(ref, () => {
    if (onClose) onClose();
  });

  return (
    <div className={styles.modal}>
      <section role='dialog' className={styles.dialog} ref={ref}>
        <header className={styles.header}>
          {title && <Typography.Title>{title}</Typography.Title>}
          {preamble && <Typography.Text>{preamble}</Typography.Text>}
        </header>
        <div className={styles.children}>{children}</div>
        {error && <span className={styles.error}>{error}</span>}

        {actions && <div className={styles.actions}>{actions}</div>}
        {loading && (
          <span className={styles.overlay}>
            <span className={styles.loader}>
              <Loader />
            </span>
          </span>
        )}
      </section>
    </div>
  );
};

export default Modal;
