import React, { ReactNode, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { useFirebase } from "../../../config/context";
import { useApp } from "../../../config/context/app-contex";
import { useGetGroup } from "../../../hooks/useGroups";
import CopyManager from "../../../utils/CopyManager";
import { Member } from "../../ChatList";
import GroupSettings from "../../generic/organisms/GroupSettings";
import Toast from "../../generic/organisms/Toast";
import { AddGroupMember, LeaveGroup, RemoveGroupMember } from "./dialogs";
import Header from "./Header";
import styles from "./index.module.css";
import { Props } from "./types";

export interface SettingsProps extends Props {
  chatId?: string | null;
}

const Settings = ({ onAbort, chatId }: SettingsProps) => {
  const firebase = useFirebase();
  const app = useApp();
  const { group, invites, addToGroup, removeFromGroup, leaveGroup } =
    useGetGroup(chatId);
  const navigate = useNavigate();

  const groupMembers = useMemo(() => {
    return Object.entries(group?.members ?? {}).map((entry) => {
      const member = entry[1] as Member;
      return { id: member.userID, isAdmin: member.admin, name: member.name };
    });
  }, [group]);

  const renderError = (error: ReactNode) => {
    app.openToast(<Toast onClose={app.closeToast}>{error}</Toast>);
  };

  const onAddGroupMember = () => {
    app.openModal(
      <AddGroupMember
        onClose={app.closeModal}
        onConfirm={async (values, showError, isLoading) => {
          if (chatId && values?.name && values.phone_number) {
            const response = await addToGroup(chatId, {
              name: values.name,
              phoneNumber: values.phone_number,
            });

            if (response?.ok) {
              app.closeModal();
            } else {
              if (isLoading) isLoading(false);
              renderError(
                <>
                  Could'nt invite member <strong>{values.name}</strong>. Please
                  try again later
                </>
              );
            }
          }
        }}
      />
    );
  };
  const onDeleteGroupMember = (
    id: string,
    memberName: string,
    groupName: string
  ) => {
    app.openModal(
      <RemoveGroupMember
        onClose={app.closeModal}
        onConfirm={async (_, __, isLoading) => {
          if (chatId) {
            const response = await removeFromGroup(chatId, id);
            if (response?.ok) {
              app.closeModal();
            } else {
              if (isLoading) isLoading(false);
              renderError(
                <>{CopyManager.get("something_went_wrong_message")}</>
              );
            }
          }
        }}
        memberName={memberName}
        groupName={groupName}
      />
    );
  };

  const onLeaveGroup = () => {
    app.openModal(
      <LeaveGroup
        onClose={app.closeModal}
        onConfirm={async (_, __, isLoading) => {
          if (chatId) {
            const response = await leaveGroup(chatId);
            if (response?.ok) {
              navigate("/dashboard");
              app.closeModal();
            } else {
              if (isLoading) isLoading(false);
              renderError(
                <>{CopyManager.get("something_went_wrong_message")}</>
              );
            }
          }
        }}
        groupName={group?.name || ""}
      />
    );
  };

  return (
    <>
      <Header
        onBack={onAbort}
        padding={false}
        name={CopyManager.get("group_settings_header")}
      />
      <GroupSettings
        className={styles.groupSettings}
        groupImage={group?.groupImageUrl}
        members={groupMembers}
        invites={invites}
        isAdmin={
          groupMembers.find(
            (member) => member.id === firebase.getCurrentUser()?.uid
          )?.isAdmin
        }
        name={group?.name ?? ""}
        onAddGroupMember={onAddGroupMember}
        onDeleteGroupMember={onDeleteGroupMember}
        onLeaveGroup={onLeaveGroup}
      />
    </>
  );
};
export default Settings;
