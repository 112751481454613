import cx from "classnames";
import React, {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  InputHTMLAttributes,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Item } from "../../molecules/Dropdown";
import Icon from "../Icon";
import styles from "./index.module.css";

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: ReactNode;
  disabled?: boolean;
  error?: ReactNode;
  icon?: ReactNode;
  onKeyDown?: () => void;
  onDeleteItem?: (item: Item) => void;
  handleSelectedItems?: (items: Item[]) => void;
  selectedItems?: Item[];
}

const Input = forwardRef<HTMLInputElement, Props>(
  (props, ref): ReactElement => {
    const {
      className,
      label,
      disabled,
      icon,
      error,
      onDeleteItem,
      selectedItems,
      ...rest
    } = props;
    const [hasFocus, setFocus] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);

    const onFocus = (event: FocusEvent<HTMLInputElement>) => {
      setFocus(true);
      if (rest.onFocus) rest.onFocus(event);
    };
    const onBlur = (event: FocusEvent<HTMLInputElement>) => {
      setFocus(false);
      if (rest.onBlur) rest.onBlur(event);
    };
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
      setIsEmpty(!event.target.value);
      if (rest.onChange) rest.onChange(event);
    };

    const stateCn = useMemo(
      () => ({
        [styles.focus]: hasFocus,
        [styles.disabled]: disabled,
        [styles.error]: error,
        [styles.empty]: isEmpty,
      }),
      [hasFocus, disabled, error, isEmpty]
    );

    useEffect(() => {
      if (props.defaultValue === undefined && props.value === undefined)
        setIsEmpty(true);
      else setIsEmpty(false);
    }, [props.value, props.defaultValue]);

    return (
      <div className={cx(styles.input, className, stateCn)}>
        <label>
          <span className={cx(styles.label, stateCn)}>{label}</span>
          <div className={styles.elementWrapper}>
            <input
              ref={ref}
              disabled={disabled}
              {...rest}
              className={cx(styles.element, stateCn)}
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={onChange}
            />
            {selectedItems && selectedItems?.length > 0 && (
              <ul className={styles.selectedItems}>
                {selectedItems?.map((item) => (
                  <li className={styles.selectedItemWrapper} key={item.value}>
                    <span className={styles.selectedItem}>{item.value}</span>
                    <button
                      className={styles.removeItemButton}
                      onClick={() => {
                        onDeleteItem && onDeleteItem(item);
                      }}
                    >
                      <Icon.Close className={styles.removeIcon} />
                    </button>
                  </li>
                ))}
              </ul>
            )}
            {icon && <span className={styles.icon}>{icon}</span>}
          </div>
          {error && (
            <span className={styles.errorMessage}>
              <Icon.Close className={styles.errorIcon} />
              {error}
            </span>
          )}
        </label>
      </div>
    );
  }
);

export default Input;
