import cx from "classnames";
import React, { HTMLAttributes, ReactElement, ReactNode } from "react";
import Typography from "../../atoms/Typography";
import Item from "./Item";
import styles from "./index.module.css";

export interface Props extends HTMLAttributes<HTMLFormElement> {
  columns?: 1 | 2;
  footer?: ReactNode;
  autoComplete?: "on" | "off";
  error?: ReactNode;
}

const Form = ({ children, className, error, ...rest }: Props): ReactElement => {
  const { onSubmit } = rest;
  return (
    <form
      noValidate
      {...rest}
      onSubmit={(event) => {
        event.preventDefault();
        if (onSubmit) onSubmit(event);
      }}
      className={cx(styles.form, className)}>
      {children}
      {error && <Typography.Text>{error}</Typography.Text>}
    </form>
  );
};

const Styled = ({ children, className, footer, error, columns = 2, ...rest }: Props): ReactElement => (
  <Form {...rest} className={cx(styles.styled, className)}>
    <div className={cx(styles.content, { [styles.single]: columns === 1, [styles.double]: columns === 2 })}>
      {children}
    </div>
    {error && (
      <Typography.Text level={2} className={styles.error}>
        {error}
      </Typography.Text>
    )}
    {footer && <div className={styles.footer}>{footer}</div>}
  </Form>
);

Form.Item = Item;
Form.Styled = Styled;
export default Form;
