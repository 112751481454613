import cx from "classnames";
import React, { HTMLAttributes, ReactElement, ReactNode, useState } from "react";
import { Collapse } from "react-collapse";
import { useFirebase } from "../../../../config/context";
import { useApp } from "../../../../config/context/app-contex";
import { useDeletionRequests } from "../../../../hooks/useDeletionRequest";
import { useTranslations } from "../../../../hooks/useTranslations";
import CopyManager from "../../../../utils/CopyManager";
import Button from "../../../generic/atoms/Button";
import Modal from "../Modal";
import Toast from "../Toast";
import styles from "./index.module.css";

export type User = {
  email: string;
  firstName: string;
  lastName: string;
};

export interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  user: User;
  expanded: boolean;
  onLogout: () => void;
}

const Nav = ({ children, className, user, expanded = true, onLogout, ...rest }: Props): ReactElement => {

  const {getCopy} = useTranslations();
  const app = useApp();
  const firebase = useFirebase();
  const {requestedDeletion} = useDeletionRequests();


  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }
  
  const onDeleteAccount = () => {
    const usr = firebase.getCurrentUser()
    if (usr?.uid !== "" && usr?.uid !== undefined) {
      app.openModal(
        <DeleteAccount
          onClose={app.closeModal}
          onConfirm={async () => {
            await timeout(2500);
            let msg = getCopy("delete_account_success")
            try {
              await firebase.addDeletionRequest(usr.uid)
            } catch (error) {
              msg = getCopy("something_wrong")
            }
            app.closeModal();
            app.openToast(
              <Toast onClose={app.closeToast}>
                {msg}
              </Toast>
            );
          }}
        />
      );
    }
  };

  interface DeleteAccProps {
    onClose: () => void;
    onConfirm: () => void;
  }

  const DeleteAccount = ({onClose, onConfirm} : DeleteAccProps) => {
    const [loading, isLoading] = useState(false);
    const [error] = useState<string | null | undefined>(null);
  
    return (
      <Modal
        title={CopyManager.get("delete_account_title")}
        preamble={CopyManager.get("delete_account_text")}
        onClose={onClose}
        loading={loading}
        error={error}
        actions={
          <>
            <Button variant="secondary" onClick={onClose}>
              {CopyManager.get("delete_account_cancel")}
            </Button>
            <Button
              danger
              onClick={() => {
                isLoading(true);
                onConfirm();
              }}
            >
              {CopyManager.get("delete_account_confirm")}
            </Button>
          </>
        }
      >
      </Modal>
    );
  };

  const dis = requestedDeletion === null || requestedDeletion === true

  return (
    <Collapse isOpened={expanded} theme={{ collapse: cx(styles.nav, className) }}>
      {React.Children.map(children, (child) => (
        <>
          <div className={styles.button}>{child}</div>
          <hr className={styles.divider} />
        </>
      ))}
      <div className={styles.user}>
        {user.email && (
          <span className={styles.userDetails}>
            <span>{user.email}</span>
          </span>
        )}
        <button className={dis ? cx(styles.button, styles.disabled) : styles.button} type='button' onClick={onDeleteAccount}>
          {getCopy("delete_account_label")}
        </button>
        <button className={styles.button} type='button' onClick={onLogout}>
          {getCopy("login_action_logout")}
        </button>
      </div>
    </Collapse>
  );
};
export default Nav;
