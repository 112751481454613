import firebaseConfig from "../config/Firebase/firebaseConfig";

const base_url = `https://onboarding-dot-${firebaseConfig.projectId}.uc.r.appspot.com`;
const baseOptions = {
  "Content-Type": "application/json",
};

export const requestOnboarding = async (
  userID: string,
): Promise<any> => {
  try {
    await fetch(`${base_url}/onboardings/${userID}/false`, {
      ...baseOptions,
      ...{
        method: "POST",
        body: JSON.stringify({}),
        mode: 'no-cors'
      },
    });
  } catch (error) {
    console.log("failed to onboard user ", userID, error);
    return null;
  }
};