import React, { ChangeEvent, Component, FormEvent } from "react";
import { withFirebaseHOC } from "../../config/Firebase";
import { IFirebase } from "../../config/Firebase/type";
import CopyManager from "../../utils/CopyManager";
import Filename from "../generic/atoms/Filename";

export interface IChatInputProps {
  firebase: IFirebase;
  groupId: string;
  sendImage(file: File): Promise<void>
  sendText(message: string): Promise<void>
}

export interface IChatInputState {
  userId: string;
  userName: string | null;
  message: string;
  file?: File;
  imagePreviewUrl: string | ArrayBuffer | null;
}

class ChatInput extends Component<IChatInputProps, IChatInputState> {
  fileInput: HTMLInputElement | null;
  chatInput: HTMLTextAreaElement | null;

  constructor(props: IChatInputProps) {
    super(props);
    const user = this.props.firebase.getCurrentUser();

    this.state = {
      userId: user ? user.uid : "",
      userName: user ? user.displayName : "",
      message: "",
      file: undefined,
      imagePreviewUrl: "",
    };
    this.fileInput = null;
    this.chatInput = null;
  }

  handleMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      message: e.target.value,
    });
  };

  onMessageSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.sendMessage();
  };

  sendMessage = () => {
    const { file, imagePreviewUrl, message } = this.state;

    if (file && imagePreviewUrl) {
      return this.props.sendImage(file)
        .then(() => this.resetImages())
        .catch((err) => console.log("failed to sendImage"))
    }
    if (message && message.trim()) {
      return this.props.sendText(message)
        .then(() => {
          this.setState(() => ({
            message: "",
          }));
          if (this.chatInput) {
            this.chatInput.style.height = "auto";
            this.chatInput.style.height = this.getInputHeight();
          }
        })
        .catch((err) => { console.log("sendText Error", err)});
    }
  };

  handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      this.handleImageState(e.target.files[0]);
    }
  };

  handleImagePaste = (event: any) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;
    for (let index = 0; index < items.length; index++) {
      const item = items[index];
      if (item.kind === "file" && item.type.split("/")[0] === "image") {
        this.handleImageState(item.getAsFile());
        break;
      }
    }
  };

  handleImageState = (file: File) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  resetImages = () => {
    this.setState({ file: undefined, imagePreviewUrl: null }, () => {
      if (this.fileInput) {
        this.fileInput.value = "";
      }
      if (this.chatInput) {
        this.chatInput.style.height = "auto";
        this.chatInput.style.height = this.getInputHeight();
      }
    });
  };

  inputChange = (evt: React.KeyboardEvent) => {
    const target = evt.currentTarget as HTMLInputElement;
    if (evt.key === "Enter" && !evt.getModifierState("Shift")) {
      const text = target.value.trim();
      if (text.length > 0) {
        this.sendMessage();
      }
      target.value = "";
    }
    setTimeout(() => {
      target.style.height = "auto";
      target.style.height = this.getInputHeight();
    }, 0);
  };

  getInputHeight = () => {
    if (this.chatInput) {
      return this.chatInput.scrollHeight < 26 ? "26px" : this.chatInput.scrollHeight + "px";
    }
    return "26px"
  };

  render() {
    return (
      <div className='chat-input'>
        <div className='chat-input-wrapper'>
          {!this.state.file && (
            <div
              className='send-image-button'
              onClick={() => {
                const fileObj = document.getElementById("selectedFile");
                fileObj && fileObj.click();
              }}></div>
          )}
          {!this.state.file && (
            <textarea
              ref={(ref) => (this.chatInput = ref)}
              className='chat-input-teaxtarea'
              placeholder={CopyManager.get("chat_input_placeholder")}
              value={this.state.message}
              onChange={this.handleMessageChange}
              onPaste={this.handleImagePaste}
              onKeyDown={(evt) => {
                // This is where we autosize the field
                const target = evt.currentTarget;
                // On keydown when Enter pressed but shift button not we have to prevent its default action which insert \n in text.
                if (evt.key === "Enter" && !evt.getModifierState("Shift")) {
                  evt.preventDefault();
                }
                setTimeout(() => {
                  target.style.height = "auto";
                  target.style.height = this.getInputHeight();
                }, 0);
              }}
              rows={1}
              onKeyUp={this.inputChange}></textarea>
          )}
          {this.state.file && (
            <div className='image-upload-preview'>
              <Filename name={this.state.file.name} onDelete={this.resetImages} />
            </div>
          )}
          <div className='send-message-wrapper'>
            <input
              type='file'
              id='selectedFile'
              style={{ display: "none" }}
              onChange={this.handleImageChange}
              ref={(ref) => (this.fileInput = ref)}
              accept='image/*'
            />

            <div className='send-message-button' onClick={this.sendMessage}></div>
          </div>
        </div>
        <hr />
      </div>
    );
  }
}

export default withFirebaseHOC(ChatInput);
