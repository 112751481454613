import cx from "classnames";
import React, { ReactElement, useReducer } from "react";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import StepSlider from "../../molecules/StepSlider";
import OptionList from "./OptionList";
import styles from "./index.module.css";

import CopyManager from "../../../../utils/CopyManager";
import Loader from "../../atoms/Loader";
import Amex from "./assets/amex.svg";
import CartesBancaires from "./assets/cartes_bancaires.svg";
import Diners from "./assets/diners.svg";
import Discover from "./assets/discover.svg";
import Jcb from "./assets/jcb.svg";
import Mastercard from "./assets/mastercard.svg";
import UnionPay from "./assets/unionpay.svg";
import Visa from "./assets/visa.svg";

export type Company = {
  id: string;
  name: string;
  orgNum: string;
};

export type Card = {
  id: string;
  name: string;
  number: string;
  brand: string;
};

export interface Props {
  className?: string;
  companys: Company[];
  cards?: Card[];
  loading?: boolean;
  onAbort: () => void;
  onRemoveCard: (id: string) => void;
  onAddCard: () => void;
  onAddCompany: () => void;
  onRemoveCompany: (id: string) => void;
  onPayWithCard: (card: Card) => void;
  onPayWithCompany: (company: Company) => void;
}

enum View {
  Start = "start",
  Card = "card",
  Company = "company",
}

const reducer = (state: { current: View; prev: View }, action: { payload: View }) => {
  const { payload } = action;
  return { ...state, ...{ current: payload, prev: state.current } };
};

const Logos = {
  amex: Amex,
  visa: Visa,
  cartes_bancaires: CartesBancaires,
  diners: Diners,
  discover: Discover,
  jcb: Jcb,
  mastercard: Mastercard,
  unionpay: UnionPay,
};

const Payment = ({
  className,
  onAbort,
  onRemoveCard,
  onAddCard,
  onRemoveCompany,
  onAddCompany,
  onPayWithCard,
  onPayWithCompany,
  companys,
  cards,
  loading = false,
}: Props): ReactElement => {
  const [view, dispatch] = useReducer(reducer, { current: View.Start, prev: View.Start });

  return (
    <div className={cx(styles.payment, className, { [styles.loading]: loading })}>
      {loading && (
        <span className={styles.loader}>
          <Loader />
        </span>
      )}
      <StepSlider
        currentIndex={view.current === View.Start ? 0 : 1}
        onPrev={async () => {
          dispatch({ payload: View.Start });
          return true;
        }}
        navigation={
          <Button variant='secondary' onClick={onAbort} grow={false}>
            Avbryt
          </Button>
        }>
        <OptionList title={CopyManager.get("payment_options_v2_title")}>
          <Button icon={<Icon.Money />} className={styles.optionBtn} onClick={() => dispatch({ payload: View.Card })}>
            {CopyManager.get("payment_options_v2_card")}
          </Button>
          <Button
            icon={<Icon.Invoice />}
            className={styles.optionBtn}
            onClick={() => dispatch({ payload: View.Company })}>
            {CopyManager.get("payment_options_v2_invoice")}
          </Button>
        </OptionList>
        <>
          {(view.current === View.Company || view.prev === View.Company) && (
            <OptionList title={CopyManager.get("payment_options_v2_invoice")}>
              {companys.map((company) => (
                <div className={styles.item} key={company.id}>
                  <Button icon={<Icon.Points />} className={styles.optionBtn} onClick={() => onPayWithCompany(company)}>
                    <span className={styles.name}>{company.name}</span>
                  </Button>
                  <Button
                    icon={<Icon.Remove className={styles.removeIcon} />}
                    grow={false}
                    className={styles.removeBtn}
                    onClick={() => onRemoveCompany(company.id)}>
                    {CopyManager.get("delete")}
                  </Button>
                </div>
              ))}
              <Button icon={<Icon.Add />} className={styles.addBtn} onClick={onAddCompany}>
                {CopyManager.get("invoice_payment_add_new_org")}
              </Button>
            </OptionList>
          )}
          {(view.current === View.Card || view.prev === View.Card) && (
            <OptionList title={CopyManager.get("payment_options_v2_card")}>
              {cards?.map((card) => (
                <div className={styles.item} key={card.id}>
                  <button
                    className={cx(styles.optionBtn, styles.cardBtn)}
                    key={card.id}
                    onClick={() => onPayWithCard(card)}>
                    <span>
                      {/* @ts-ignore */}
                      <img src={Logos[card.brand] as string} alt={card.brand} className={styles.logo} loading='lazy' />
                    </span>
                    <span className={styles.name}>{card.name}</span>
                    <span className={cx(styles.name, styles.cardNumber)}>{card.number}</span>
                  </button>

                  <Button
                    icon={<Icon.Remove className={styles.removeIcon} />}
                    grow={false}
                    className={styles.removeBtn}
                    onClick={() => onRemoveCard(card.id)}>
                    {CopyManager.get("delete")}
                  </Button>
                </div>
              ))}
              <Button icon={<Icon.Add />} className={styles.addBtn} onClick={onAddCard}>
                {CopyManager.get("payment_options_add_new_card")}
              </Button>
            </OptionList>
          )}
        </>
      </StepSlider>
    </div>
  );
};
export default Payment;
