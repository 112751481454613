import React, { HTMLAttributes, ReactElement, useRef } from "react";
import useClickOutside from "../../../../hooks/useClickOutside";
import Icon from "../../atoms/Icon";
import Slider from "../../molecules/Slider";
import styles from "./index.module.css";

export interface Props extends HTMLAttributes<HTMLDivElement> {
  images: string[];
  onClose: () => void;
}

const LightRoom = ({ children, images, onClose, ...rest }: Props): ReactElement => {
  const dialogRef = useRef<HTMLDivElement | null>(null);

  useClickOutside(dialogRef, () => {
    if (images.length === 1) onClose();
  });

  return (
    <div className={styles.lightRoom}>
      <button type='button' className={styles.closeBtn} onClick={onClose}>
        <Icon.Close />
      </button>
      <div role='dialog' className={styles.dialog} ref={dialogRef}>
        <div className={styles.images}>
          <Slider navigation={!images.length} pagination={!images.length}>
            {images.map((image) => (
              <img src={image} alt='' key={image} className={styles.image} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default LightRoom;
