import React, { useState } from "react";
import { PaymentType } from "../../../../api/api-invoices";
import { useApp } from "../../../../config/context/app-contex";
import { useInvoice } from "../../../../hooks/useInvoice";
import CopyManager from "../../../../utils/CopyManager";
import Toast from "../../../generic/organisms/Toast";
import { AddNewCompanyForm } from "../dialogs";

export interface Props {
  payment: PaymentType;
  onComplete: (companyId: string) => void;
}

const NewCompanyPayment = ({ onComplete, payment }: Props) => {
  const app = useApp();
  const invoices = useInvoice();
  const [companyId, setCompanyId] = useState<string | null>(null);

  const showToastError = (error?: string) => {
    app.openToast(
      <Toast onClose={app.closeToast}>
        {CopyManager.get("something_wrong")}
      </Toast>
    );
  };

  return (
    <AddNewCompanyForm
      onClose={app.closeModal}
      onConfirm={async (
        values,
        showError,
        isLoading,
        [setValues, setShouldPay]
      ) => {
        if (isLoading) isLoading(true);

        if (!companyId) {
          const response = await invoices.createCompany(
            values?.org_nr ?? "",
            values?.email ?? ""
          );
          if (response.ok) {
            setValues({
              org_nr: values?.org_nr ?? "",
              email: values?.email ?? "",
              name: response.company?.name,
            });
            setShouldPay(true);
            setCompanyId(response.company?.id ?? null);
            if (isLoading) isLoading(false);
          } else {
            console.log("error")
            showToastError();
          }
        } else if (values?.name) {
          if (isLoading) isLoading(true);
          onComplete(companyId);
        }
      }}
    />
  );
};
export default NewCompanyPayment;
