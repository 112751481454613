import cx from "classnames";
import React, { HTMLAttributes, ReactElement, ReactNode } from "react";
import Typography from "../../atoms/Typography";
import styles from "./index.module.css";

export interface Props extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  body?: string;
  actions?: ReactNode;
}

const Dialog = ({ children, title, body, actions, className, ...rest }: Props): ReactElement => {
  return (
    <div role='dialog' className={cx(styles.dialog, className)}>
      {title && <Typography.Title level={2}>{title}</Typography.Title>}

      <div className={styles.children}>
        {body && <Typography.Text level={1} dangerouslySetInnerHTML={{ __html: body }} />}
        {children}
      </div>

      {actions && <div className={styles.actions}>{actions}</div>}
    </div>
  );
};

export default Dialog;
