import React, { useEffect, useRef, useState } from "react";
import CopyManager from "../../../utils/CopyManager";
import Button from "../../generic/atoms/Button";
import Typography from "../../generic/atoms/Typography";
import CompanyForm, {
  Schema as CompanySchema,
} from "../../generic/organisms/CompanyForm";
import Member, {
  Imperative,
  Schema as MemberSchema,
} from "../../generic/organisms/CreateGroupForm/forms/member";
import CreditCardForm, {
  Schema as CreditCardSchema,
} from "../../generic/organisms/CreditCardForm";
import Modal from "../../generic/organisms/Modal";

export interface Props<T = unknown, U = unknown> {
  onConfirm: (
    values?: T,
    showError?: (error?: string | null) => void,
    isLoading?: (loading: boolean) => void,
    ...args: U[]
  ) => void;
  onClose: () => void;
}

export const AddNewCreditCard = ({
  onConfirm,
  onClose,
}: Props<CreditCardSchema>) => {
  const [loading, isLoading] = useState(false);
  const [error, showError] = useState<string | null | undefined>(null);

  return (
    <Modal
      title={CopyManager.get("card_payment_add_new_card")}
      onClose={onClose}
      loading={loading}
      error={error}
    >
      <CreditCardForm
        onComplete={async (values) => {
          isLoading(true);
          onConfirm(values, showError, isLoading);
        }}
        actions={
          <>
            <Button type="button" variant="secondary" onClick={onClose}>
              {CopyManager.get("card_payment_button_cancel")}
            </Button>
            <Button type="submit">
              {CopyManager.get("card_payment_button_save")}
            </Button>
          </>
        }
      />
    </Modal>
  );
};

export const AddGroupMember = ({ onConfirm, onClose }: Props<MemberSchema>) => {
  const formRef = useRef<Imperative>(null);
  const [loading, isLoading] = useState(false);
  const [error, showError] = useState<string | null | undefined>(null);

  const validate = async () => {
    if (await formRef.current?.validate()) {
      isLoading(true);
      onConfirm(formRef.current?.values(), showError, isLoading);
    }
  };

  return (
    <Modal
      title={CopyManager.get("group_settings_add_member")}
      onClose={onClose}
      loading={loading}
      error={error}
      actions={
        <>
          <Button onClick={onClose} variant="secondary">
            {CopyManager.get("group_settings_remove_member_cancel")}
          </Button>
          <Button onClick={validate}>
            {CopyManager.get("group_settings_add_member_button")}
          </Button>
        </>
      }
    >
      <Member ref={formRef} />
    </Modal>
  );
};

export interface RemoveMemberProps extends Props {
  memberName: string;
  groupName: string;
}

export const RemoveGroupMember = ({
  onConfirm,
  onClose,
  memberName,
  groupName,
}: RemoveMemberProps) => {
  const [loading, isLoading] = useState(false);
  const [error, showError] = useState<string | null | undefined>(null);

  return (
    <Modal
      title={CopyManager.get("group_settings_remove_member_modal")}
      onClose={onClose}
      loading={loading}
      error={error}
      actions={
        <>
          <Button variant="secondary" onClick={onClose}>
            {CopyManager.get("group_settings_remove_member_cancel")}
          </Button>
          <Button
            danger
            onClick={() => {
              isLoading(true);
              onConfirm(undefined, showError, isLoading);
            }}
          >
            {CopyManager.get("remove")}
          </Button>
        </>
      }
    >
      <Typography.Text>
        Are you sure you want to remove {memberName} from {groupName}? This
        action is irreversible.
      </Typography.Text>
    </Modal>
  );
};


export interface LeaveGroupProps extends Props {
  groupName: string;
}

export const LeaveGroup = ({ onConfirm, onClose, groupName }: LeaveGroupProps) => {
  const [loading, isLoading] = useState(false);
  const [error, showError] = useState<string | null | undefined>(null);

  return (
    <Modal
      title={CopyManager.get("group_settings_leave_group_title")}
      onClose={onClose}
      loading={loading}
      error={error}
      actions={
        <>
          <Button variant="secondary" onClick={onClose}>
            {CopyManager.get("group_settings_leave_group_cancel")}
          </Button>
          <Button
            danger
            onClick={() => {
              isLoading(true);
              onConfirm(undefined, showError, isLoading);
            }}
          >
            {CopyManager.get("group_settings_leave_group_button")}
          </Button>
        </>
      }
    >
      <Typography.Text>
        {CopyManager.get("group_settings_leave_group_alert")} {groupName}{"?"}
      </Typography.Text>
    </Modal>
  );
};

export const AddNewCompanyForm = ({
  onConfirm,
  onClose,
  defaultValues,
}: Props<
  CompanySchema,
  [(values?: CompanySchema) => void, (pay: boolean) => void]
> & {
  defaultValues?: CompanySchema;
}) => {
  const [loading, isLoading] = useState(false);
  const [error, showError] = useState<string | null | undefined>(null);
  const [values, setValues] = useState<CompanySchema | undefined>(
    defaultValues
  );
  const [shouldPay, setShouldPay] = useState(false);

  useEffect(() => {
    if (defaultValues) setValues(defaultValues);
  }, [defaultValues]);

  return (
    <Modal
      title={CopyManager.get("pay_with_business")}
      preamble={CopyManager.get("pay_with_business_invoice_message")}
      loading={loading}
      error={error}
      onClose={onClose}
    >
      <CompanyForm
        onComplete={(values) => {
          onConfirm(values, showError, isLoading, [setValues, setShouldPay]);
        }}
        defaultValues={values}
        disabled={shouldPay}
        actions={
          <>
            <Button type="button" variant="secondary" onClick={onClose}>
              {CopyManager.get("pay_with_business_cancel")}
            </Button>
            <Button type="submit">
              <>
                {!shouldPay
                  ? CopyManager.get("pay_with_business_add_button")
                  : CopyManager.get("pay_with_business_pay_button")}
              </>
            </Button>
          </>
        }
      />
    </Modal>
  );
};
