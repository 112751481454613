import moment from "moment";
import React from "react";
import { useFirebase } from "../../../config/context";
import { useApp } from "../../../config/context/app-contex";
import {
  GBonusProgram,
  GroupTraveller,
  Traveller,
} from "../../../lib/types/traveller";
import CopyManager from "../../../utils/CopyManager";
import Typography from "../../generic/atoms/Typography";
import InqueryForm, { Schema } from "../../generic/organisms/InqueryForm";
import Modal from "../../generic/organisms/Modal";
import TravellersForm, {
  TravellerSchema,
} from "../../generic/organisms/TravellersForm";
import styles from "./index.module.css";

export interface Props {
  messageID?: string | null;
  chatId?: string;
  onAbort: () => void;
  onComplete?: () => void;
}

export const Inquery = ({ onAbort, messageID }: Props) => {
  const firebase = useFirebase();
  const app = useApp();

  const saveInquiry = async (uid: string, values: Schema) => {
    console.log(values)
    const { destination: dest, depart: dep } = values;
    return await firebase.saveBookingRequest(uid, {
      ...values,
      ...{
        destinations: dest.name.join(","),
        regions: dest.regions,
        depart: dep.join(","),
      },
    });
  };

  const setMessageComplete = async (uid: string, messageID: string) => {
    return await firebase.setMessageComplete(uid, messageID);
  };

  const sendMessageBookingRequest = async (uid: string) => {
    setTimeout(async () => {
      try {
        return firebase.sendChatflightsMessage(uid, CopyManager.get("award_form_completion_automessage"))
      } catch (err) {
        console.log("failed to send award_form_completion_automessage", err)
      }
   }, 2000);
  }

  return (
    <>
      <Typography.Title level={2} className={styles.title}>
        {CopyManager.get("new_booking_request")}
      </Typography.Title>
      <InqueryForm
        onComplete={async (values) => {
          const uid = firebase.getCurrentUser()?.uid;
          if (uid) {
            try {
              await saveInquiry(uid, values)
              if (messageID) {
                await setMessageComplete(uid, messageID)
              }
              await sendMessageBookingRequest(uid)
            }
            catch(err) {
              console.log(err)
            }
            finally {
              onAbort()
            }
          }
        }}
        onAbort={onAbort}
        onShowInfo={(title, body) =>
          app.openModal(
            <Modal
              title={title?.toString()}
              children={body}
              onClose={() => app.closeModal()}
            />
          )
        }
      />
    </>
  );
};

export const Travellers = ({ onAbort, messageID, chatId }: Props) => {
  const firebase = useFirebase();

  const saveTravellers = async (uid: string, values: TravellerSchema[]) => {
    if (chatId) {
      return saveGroupTravellers(chatId, values);
    }
    return savePrivateTravellers(uid, values);
  };

  const saveGroupTravellers = async (
    groupID: string,
    values: TravellerSchema[]
  ) => {
    const travellers = values.map((t) => {
      let traveller = {
        birthDate: moment(t.birthdate).format("DDMMYYYY"),
        fnames: t.firstname,
        lname: t.lastname,
        sex: t.gender,
        nationality: "",
        ppExpiresAt: "",
        ppNumber: "",
        createdAt: Date.now(),
      } as GroupTraveller;
      if (t.bonus_program_number && t.bonus_program) {
        let bonusPrograms: GBonusProgram[] = [];
        bonusPrograms.push({
          ID: t.bonus_program_number,
          name: t.bonus_program,
          account: t.bonus_number ?? "",
          balance: 0,
        });
        traveller.bonusPrograms = bonusPrograms;
      }
      return traveller;
    });
    return firebase.addGroupTravellers(groupID, travellers);
  };

  const savePrivateTravellers = async (
    uid: string,
    values: TravellerSchema[]
  ) => {
    const travellers = values.map((t) => {
      let traveller = {
        birthdate: moment(t.birthdate).format("DDMMYYYY"),
        firstname: t.firstname,
        lastname: t.lastname,
        sex: t.gender,
      } as Traveller;
      if (t.bonus_program_number) {
        let bonusProgram = {
          [t.bonus_program_number]: {
            bonuscode: t.bonus_number,
            balance: "0",
            addedorUpdatedAt: moment().valueOf(),
          },
        };
        traveller = { ...traveller, ...{ bonusprograms: bonusProgram } };
      }
      return traveller;
    });
    await firebase.addTravellers(uid, travellers);
  };

  const setMessageComplete = async (uid: string, messageID: string) => {
    return await firebase.setMessageComplete(uid, messageID);
  };

  const sendMessageTravellerSaved = async (uid: string) => {
    setTimeout(async () => {
      try {
        return firebase.sendTextMessage(uid, CopyManager.get("future_bookings_message"))
      } catch (err) {
        console.log("failed to send future_bookings_message", err)
      }
   }, 2000);
  }

  return (
    <>
      <Typography.Title level={2} className={styles.title}>
        {CopyManager.get("travellers_title")}
      </Typography.Title>
      <TravellersForm
        onComplete={async (values) => {
          const uid = firebase.getCurrentUser()?.uid;
          if (uid) {
            try {
              await saveTravellers(uid, values);
              if (messageID) {
                await setMessageComplete(uid, messageID);
              }
              await sendMessageTravellerSaved(uid)
            } catch (err) {
              console.log(err);
            } finally {
              onAbort();
            }
          }
          onAbort();
        }}
        onAbort={onAbort}
      />
    </>
  );
};

const Form = {
  Inquery,
  Travellers,
};

export default Form;
