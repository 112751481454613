import React, { HTMLAttributes, ReactElement } from "react";
import Icon from "../../../../atoms/Icon";
import IconButton from "../../../../atoms/IconButton";
import Typography from "../../../../atoms/Typography";
import styles from "./index.module.css";

export interface Props extends HTMLAttributes<HTMLButtonElement> {}

const LabelButton = ({ children, ...rest }: Props): ReactElement => (
  <Typography.Text className={styles.labelButton} light={false}>
    <Typography.Bold>{children}</Typography.Bold>
    <IconButton {...rest}>
      <Icon.Information />
    </IconButton>
  </Typography.Text>
);

export default LabelButton;
