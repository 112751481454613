import React, { forwardRef, MouseEvent, ReactElement, useEffect, useState } from "react";
import Icon from "../Icon";
import Input, { Props as InputProps } from "../Input";
import styles from "./index.module.css";

export interface Props extends InputProps {
  visible?: boolean;
}

const PasswordInput = forwardRef<HTMLInputElement, Props>((props, ref): ReactElement => {
  const { visible, ...rest } = props;
  const [isVisible, setIsVisible] = useState(visible);

  const toggle = (event: MouseEvent<HTMLButtonElement>) => {
    setIsVisible(!isVisible);
    event.nativeEvent.preventDefault();
  };

  // Make sure that we don't loose focus on input when toggle
  const eventTrigger = (event: MouseEvent<HTMLButtonElement>) => {
    event.nativeEvent.stopImmediatePropagation();
    event.nativeEvent.stopPropagation();
    event.nativeEvent.preventDefault();
  };

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  return (
    <Input
      ref={ref}
      type={isVisible ? "text" : "password"}
      icon={
        <button
          type='button'
          onClick={toggle}
          className={styles.toggleBtn}
          onMouseDown={eventTrigger}
          onMouseUp={eventTrigger}
          tabIndex={-1}>
          {isVisible ? <Icon.EyeHide /> : <Icon.EyeShow />}
        </button>
      }
      className={styles.passwordInput}
      {...rest}
    />
  );
});

export default PasswordInput;
