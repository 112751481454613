import cx from "classnames";
import React, { ReactElement, useEffect, useState } from "react";

import Button from "../../../atoms/Button";
import Typography from "../../../atoms/Typography";
import DigitsInput from "../../../molecules/DigitsInput";

import CopyManager from "../../../../../utils/CopyManager";
import Logo from "../../../atoms/Logo";
import Form from "../../../molecules/Form";
import styles from "./index.module.css";

export interface Props {
  className?: string;
  onComplete?: (values: number) => boolean;
  resendPin?: () => void;
}

const Passcode = ({
  className,
  onComplete,
  resendPin,
  ...rest
}: Props): ReactElement => {
  const [enbledLoginBtn, setEnabledLoginBtn] = useState(false);
  const [validPin, setValidPin] = useState(true);
  const [userPin, setUserPin] = useState<number | null>(null);
  const [mounted, setMounted] = useState(false);
  const inputs = 4;

  const enteredPin = (values: string[]) => {
    setEnabledLoginBtn(values.length === inputs);
    const pin = parseInt(values.join(""));
    setUserPin(pin);
  };

  const submitPin = (userPin: number) => {
    if (onComplete) {
      onComplete(userPin);

      const isPinValid = onComplete(userPin);
      if (isPinValid) {
        setUserPin(null);
      }
      setValidPin(isPinValid);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 1000);
  }, []);

  const resetValidation = () => {
    setValidPin(true);
  };

  return (
    <section
      className={cx(styles.passCode, { [styles.fadeIn]: mounted }, className)}
    >
      <div className={styles.logo}>
        <Logo />
      </div>
      <header className={styles.header}>
        <Typography.Title level={1}>
          {CopyManager.get("passcode_title")}
        </Typography.Title>
        <Typography.Text>
          {CopyManager.get("passcode_description")}
        </Typography.Text>
      </header>
      <Form
        onSubmit={(event) => {
          const form = event.target;

          if (form) {
            const data = new FormData(form as HTMLFormElement);
            const values: string[] = [];
            for (const [value] of data) {
              if (value.toString().length) values.push(value.toString());
            }

            if (values.length === inputs && userPin) {
              submitPin(userPin);
            }
          }
          event.nativeEvent.preventDefault();
        }}
        className={styles.form}
      >
        {!validPin && <span className={styles.invalid}>{CopyManager.get("passcode_incorrect")}</span>}
        <DigitsInput
          onValueChange={(values) => {
            enteredPin(values);
          }}
          items={inputs}
          validPin={validPin}
          resetValidation={resetValidation}
        />
        <Typography.Text className={styles.noCode}>
          {CopyManager.get("passcode_not_received")}{" "}
          <button
            className={styles.resendBtn}
            type="button"
            onClick={resendPin}
          >
            {CopyManager.get("passcode_resend")}
          </button>
        </Typography.Text>
        <Button
          type="submit"
          className={cx(styles.button, {
            [styles.disabled]: !enbledLoginBtn,
          })}
          disabled={!enbledLoginBtn}
        >
          {CopyManager.get("login_action_login")}
        </Button>
      </Form>
    </section>
  );
};
export default Passcode;
