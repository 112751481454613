import React, { useEffect, useRef } from "react";
import Loader from "../../../generic/atoms/Loader";
import styles from "./index.module.css";

export interface Props {
  url: string;
  onComplete: () => void;
}

const AuthenticationComplete = "3DS-authentication-complete";

const CardAuth = ({ url, onComplete }: Props) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    const onMessage = (event: any) => {
      if (event.data === AuthenticationComplete) onComplete();
    };

    window.addEventListener("message", onMessage);
    return () => window.addEventListener("message", onMessage);
  }, [iframeRef.current]);

  return (
    <div className={styles.cardAuth}>
      <iframe title='3d_secure' src={url} width='100%' height='100%' className={styles.iframe} ref={iframeRef} />
      <span className={styles.loader}>
        <Loader />
      </span>
    </div>
  );
};
export default CardAuth;
