export enum ActionName {
  TravellersAndUser = "travellersAndUser",
  AwardForm = "awardForm",
  Payment = "payment",
  PaymentBooking = "paymentBooking",
  CheckoutBooking = "checkoutBooking",
  GroupInvite = "group-invite",
}
export enum AuthorType {
  User = "user",
  Agent = "agent",
  System = "system",
}
export enum ProcessState {
  Completed = "COMPLETED",
  Pending = "PENDING",
  Failed = "FAILED",
}
export enum MessageType {
  Text = "text",
  Image = "image",
  TextAlts = "text_alts"
}
export enum IMessageActionStatus {
  New = "new",
  Completed = "completed",
  Expired = "expired",
  CompletedBackground = "completed-background",
}
