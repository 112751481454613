import { zodResolver } from "@hookform/resolvers/zod";

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import { useForm } from "react-hook-form";
import { z } from "zod";
import CopyManager from "../../../../../utils/CopyManager";
import Checkbox from "../../../atoms/Checkbox";
import Input from "../../../atoms/Input";
import AutoCompleteMulti from "../../../molecules/AutoCompleteMulti";
import { Item } from "../../../molecules/Dropdown";
import Form from "../../../molecules/Form";
import { Imperative, Props } from "./types";

export const schema = z.object({
  points: z.number({
    invalid_type_error: "Write a number",
    required_error: "Write a number",
  }),
  vouchers: z.string(),
  depart: z.string()
    .array()
    .refine((val) => {
      return val.length > 0
    },
    {
      message: "Departure is required"
    }
  ),
  depart_date: z.date(),
  return_date: z.coerce
    .date()
    .min(new Date(), { message: "Future date required" }),
  travellers: z.number({
    invalid_type_error: "Write a number",
    required_error: "Write a number",
  }),
  days_on_location: z.number({
    invalid_type_error: "Write a number",
    required_error: "Write a number",
  }),
  cabin_class: z
    .string()
    .array()
    .nonempty({
      message: "Cabin class is required.",
    }),
  more: z.string().optional(),
});

export type DetailsSchema = z.infer<typeof schema>;
export interface DetailsProps extends Props {
  usePoints: boolean;
  airports: { label: string; value: string }[];
}

const DetailsForm = forwardRef<Imperative<DetailsSchema>, DetailsProps>(
  (props, ref) => {
    const { onShowInfo, usePoints, ...rest } = props;
    const [selectedItems, setSelectedItems] = useState<Item[]>([]);

    const {
      trigger,
      getValues,
      setValue,
      register,
      reset,
      formState: { errors },
    } = useForm<DetailsSchema>({
      mode: "onTouched",
      resolver: zodResolver(schema),
      defaultValues: {
        depart: [],
        cabin_class: [],
        vouchers: "",
      },
    });

    useImperativeHandle(ref, () => ({
      async validate() {
        return await trigger();
      },
      values() {
        return getValues();
      },
      resetForm() {
        return reset();
      },
    }));

    useEffect(() => {
      // @ts-ignore
      setValue("points", usePoints ? undefined : 0);
    }, [usePoints]);

    useEffect(() => {
      if (getValues().depart.length === 0) setSelectedItems([]);
    }, [getValues().depart]);

    const onAirportSelect = (item: any) => {
      setValue("depart", [...getValues().depart, item.value]);
      setSelectedItems(
        getValues().depart.map((item) => ({ value: item, label: item }))
      );
    };

    const deleteAirport = (item: Item) => {
      setValue(
        "depart",
        getValues().depart.filter((i) => i !== item.value)
      );

      setSelectedItems(
        getValues()
          .depart.map((item) => ({ value: item, label: item }))
          .filter((i) => i.value !== item.value)
      );
    };

    const handleSelectedItems = (childData: Item[]) => {
      setSelectedItems(childData);
    };

    return (
      <Form.Styled columns={1} {...rest}>
        {CopyManager.get("booking_request_label_answer_questions")}
        {usePoints && (
          <>
            <Input
              label={CopyManager.get("booking_request_num_points")}
              inputMode="numeric"
              pattern="[0-9]*"
              {...register("points", { valueAsNumber: true })}
              error={errors.points?.message}
            />
            <Input
              label={CopyManager.get("booking_request_vouchers")}
              {...register("vouchers")}
              error={errors.vouchers?.message}
            />
          </>
        )}
        <AutoCompleteMulti
          {...register("depart")}
          label={CopyManager.get("booking_request_departure")}
          items={props.airports}
          error={errors.depart?.message}
          autoComplete="off"
          onSelectItem={(item) => onAirportSelect(item)}
          onDeleteItem={(item) => deleteAirport(item)}
          selectedItems={selectedItems}
          handleSelectedItems={handleSelectedItems}
          onSearch={(value: String) => {
            const iataMatches = props.airports.filter((item) => {
              return item.label
                .toLowerCase()
                .slice(0, 3)
                .includes(value.toLowerCase());
            });
            const cityMatches = props.airports.filter((item) => {
              return item.label
                .toLowerCase()
                .slice(4)
                .includes(value.toLowerCase());
            });
            return iataMatches
              .concat(cityMatches)
              .filter((value, index, arr) => {
                return arr.indexOf(value) === index;
              });
          }}
        />
        <Input
          label={CopyManager.get("booking_request_departure_date")}
          {...register("depart_date", { valueAsDate: true })}
          type="date"
          min={new Date().toLocaleDateString("sv-se")}
          max="2999-12-31"
          error={errors.depart_date?.message}
        />
        <Input
          label={CopyManager.get("booking_request_return_date")}
          {...register("return_date", { valueAsDate: true })}
          type="date"
          min={new Date().toLocaleDateString("sv-se")}
          max="2999-12-31"
          error={errors.return_date?.message}
        />
        <Input
          label={CopyManager.get("booking_request_days_on_location")}
          inputMode="numeric"
          pattern="[0-9]*"
          {...register("days_on_location", { valueAsNumber: true })}
          error={errors.days_on_location?.message}
        />
        <Input
          label={CopyManager.get("booking_request_pax")}
          inputMode="numeric"
          pattern="[0-9]*"
          {...register("travellers", { valueAsNumber: true })}
          error={errors.travellers?.message}
        />
        <Form.Item
          label={
            <label>
              {CopyManager.get("booking_request_cabin_class")}
            </label>
          }
          name="cabin_class"
          error={errors.cabin_class?.message}
        >
          <Checkbox.Group buttonStyle="button" name="cabin_class">
            <Checkbox.Item value="economy" {...register("cabin_class")}>
              {CopyManager.get("booking_request_cabin_class_economy")}
            </Checkbox.Item>
            <Checkbox.Item value="business" {...register("cabin_class")}>
              {CopyManager.get("booking_request_cabin_class_business")}
            </Checkbox.Item>
            <Checkbox.Item value="first-class" {...register("cabin_class")}>
              {CopyManager.get("booking_request_cabin_class_first")}
            </Checkbox.Item>
          </Checkbox.Group>
        </Form.Item>
        <Input
          label={CopyManager.get("booking_request_anything_else")}
          {...register("more")}
          error={errors.more?.message}
        />
      </Form.Styled>
    );
  }
);

export default DetailsForm;
