import cx from "classnames";
import React, { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import Loader from "../../../atoms/Loader";
import styles from "./index.module.css";

interface Props {
  id: string;
  name?: ReactNode;
  isUser: boolean;
  images: string[];
  small?: boolean;
  onClick: () => void;
}

const ImageItem = ({ id, name, isUser, images, small = false, onClick }: Props): ReactElement => {
  const [loaded, setLoaded] = useState(false);
  const imageRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    setLoaded(imageRef.current?.complete ?? false);
    imageRef.current?.addEventListener("load", () => setLoaded(true));
  }, [imageRef]);

  return (
    <button
      className={cx(styles.imageItem, { [styles.user]: isUser })}
      onClick={onClick}
      style={{ marginTop: `${10 * Math.max(images.length - 1, 0)}px` }}>
      {name && <span className={styles.name}>{name}</span>}

      <div className={styles.images}>
        {images.map((src, index) => (
          <div
            className={cx(styles.preview, { [styles.overlay]: index })}
            key={src}
            style={{
              transform: isUser
                ? `translate(-${16 * index}px,-${10 * index}px)`
                : `translate(${16 * index}px,-${10 * index}px)`,
              zIndex: images.length - index,
            }}>
            {!loaded && (
              <div className={styles.imgLoader}>
                <Loader />
              </div>
            )}

            <img
              src={src}
              loading='lazy'
              className={cx(styles.image, {
                [styles.loading]: !loaded,
                [styles.loaded]: loaded,
                [styles.small]: small,
              })}
              alt=''
              ref={imageRef}
            />
          </div>
        ))}
      </div>
    </button>
  );
};

interface PendingProps {
  isUser: boolean;
}

export const ImageItemPending = ({isUser}: PendingProps) => {

  return (
    <button
      className={cx(styles.imageItem, { [styles.user]: isUser })}
      style={{ marginTop: "10px" }}>
      <div className={styles.images}>
        <div
          className={cx(styles.preview, styles.overlay)}
          style={{
            transform: isUser
              ? `translate(-16px,-10px)`
              : `translate(16px,-10px)`,
          }}>
          {(
            <div className={styles.imgLoader}>
              <Loader />
            </div>
          )}
          <img
            loading='lazy'
            className={cx(styles.image, styles.loading)}
            alt=''
          />
        </div>
      </div>
    </button>
  );
}

export default ImageItem;
