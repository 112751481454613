import { StoreGlobal } from "../App";

import EN from "../lib/en.json";
import { INameToValueMap } from "../lib/types";

const translations = {
    en: EN
};

export default class CopyManager {
    static getLanguageCode = (): string => {
        const systemLanguage = navigator.language || "en";
        const languageCode = systemLanguage.substring(0, 2);
        if(["en", "sv"].indexOf(languageCode) === -1) {
            return "en";
        }
        return languageCode;
    };

    static getFallback(id: string): string {
        const translation: INameToValueMap = translations.en;
        if (translation[id]) {
            return translation[id];
        }
        return  id;
    }

    static get = (id: string): string => {
        try {
            let result: string;
            const translation: INameToValueMap = (
                StoreGlobal[CopyManager.getLanguageCode()]);
            if (translation && translation[id]) {
                result = translation[id];
            } else {
                result = CopyManager.getFallback(id);
            }
            return result;
        } catch (error) {
            console.log(error)
            return CopyManager.getFallback(id);
        }
    };
}
