import React from "react";
import { Traveller } from "../../lib/types";
import Typography from "../generic/atoms/Typography";

type TravellersProps = {
   travellers: Array<Traveller>,
   travellerSelected: (value: Traveller) => void,
};

export const Travellers = (props: TravellersProps) => {

  return (
    <div className="travellers-card">
      <div className="travellers">
        {props.travellers.map((t, index) => {
          const lastTraveller = index === props.travellers.length -1
          const text = !lastTraveller ? `${t.firstname} ${t.lastname} / ` : `${t.firstname} ${t.lastname}`
          return (
            <Typography.Text
              key={index}
              as="a"
              level={2}
              className="traveller"
              onClick={() => props.travellerSelected(t)}>
              {text}
            </Typography.Text>
          )
        })}
      </div>
    </div>
  );
}

    