import cx from "classnames";
import React, { HTMLAttributes, ReactElement } from "react";
import Button from "../Button";
import Icon from "../Icon";
import styles from "./index.module.css";

export interface Props extends HTMLAttributes<HTMLSpanElement> {
  onDelete: () => void;
  name: string;
}

export const Filename = ({ className, name, children, onDelete, ...rest }: Props): ReactElement => (
  <span className={cx(styles.fileName, className)}>
    {name}
    {children}
    <Button icon={<Icon.Delete className={styles.removeIcon} />} onClick={onDelete} />
  </span>
);

export default Filename;
