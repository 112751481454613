import cx from "classnames";
import React, { ReactElement, ReactNode, useRef } from "react";

import useClickOutside from "../../../../hooks/useClickOutside";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import styles from "./index.module.css";

export enum ToastVariant {
  Primary = "primary",
  Error = "error",
}

export interface Props {
  children: ReactNode;
  className?: string;
  variant?: ToastVariant;
  onClose: () => void;
}

const Toast = ({ children, className, variant = ToastVariant.Primary, onClose }: Props): ReactElement => {
  const ref = useRef<HTMLDivElement | null>(null);

  useClickOutside(ref, () => {
    onClose();
  });

  return (
    <aside className={cx(styles.toast, className)}>
      <div className={cx(styles.content, styles[variant])} ref={ref}>
        <div>{children}</div>
        <Button className={styles.closeBtn} icon={<Icon.Close className={styles.closeIcon} />} onClick={onClose} />
      </div>
    </aside>
  );
};

export default Toast;
