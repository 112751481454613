import cx from "classnames";
import React, { ReactElement } from "react";
import { useTranslations } from "../../../../hooks/useTranslations";
import AppDetails from "../../atoms/AppDetails";
import Button from "../../atoms/Button";
import Logo from "../../atoms/Logo";
import Typography from "../../atoms/Typography";
import styles from "./index.module.css";

export interface Props {
  className?: string;
}

const NoSupport = ({ className }: Props): ReactElement => {
  const {getCopy, loaded} = useTranslations();

  if (!loaded) {
    return <></>
  }
  return (
    <section className={cx(styles.noSupport, className)}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <header>
        <Typography.Title className={styles.heading}>
          {getCopy('desktop_no_support_title')}
        </Typography.Title>
        <Typography.Text className={styles.text}>
          {getCopy('desktop_no_support_text')}
        </Typography.Text>
      </header>
      <div className={styles.list}>
        <div className={styles.platform}>
          <AppDetails label='Chatflights for iPhone' />
          <Button
            as='a'
            target='_blank'
            href='https://apps.apple.com/us/app/chatflights/id1291252056'
            className={styles.btn}>
            {getCopy('desktop_no_support_apple_store')}
          </Button>
        </div>
        <div className={styles.platform}>
          <AppDetails label='Chatflights for Android' />
          <Button
            as='a'
            target='_blank'
            href='https://play.google.com/store/apps/details?id=com.chatflights'
            className={styles.btn}
            grow>
            {getCopy('desktop_no_support_apple_store')}
          </Button>
        </div>
      </div>
    </section>
  );
};
export default NoSupport;
