import cx from "classnames";
import React, { ReactElement, ReactNode } from "react";
import styles from "./index.module.css";

export type PolymorphicProps<Element extends React.ElementType, Props> = Props &
  Omit<React.ComponentProps<Element>, "as"> & {
    as?: Element;
  };

const defaultElement = "button";
export interface Props {
  children?: ReactNode;
  className?: string;
  icon?: ReactNode;
  danger?: boolean;
  grow?: boolean;
  variant?: "primary" | "secondary";
}

const Button = <Element extends React.ElementType = typeof defaultElement>(
  props: PolymorphicProps<Element, Props>
): ReactElement => {
  const {
    children,
    danger = false,
    variant = "primary",
    className,
    icon,
    grow = true,
    as: Component = defaultElement,
    ...rest
  } = props;
  const cn = cx(styles.button, className, {
    [styles.secondary]: variant === "secondary",
    [styles.withIcon]: icon,
    [styles.danger]: danger,
    [styles.grow]: grow,
  });
  return (
    <>
      {rest.href ? (
        <a {...rest} className={cn}>
          {icon && <span className={styles.icon}>{icon}</span>}
          {children && <span className={styles.children}>{children}</span>}
        </a>
      ) : (
        /* @ts-expect-error */
        <Component {...rest} className={cn}>
          {icon && <span className={styles.icon}>{icon}</span>}
          {children && <span className={styles.children}>{children}</span>}
        </Component>
      )}
    </>
  );
};

export default Button;
