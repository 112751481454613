import React, {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  ReactElement,
  useRef,
  useState,
} from "react";
import useClickOutside from "../../../../hooks/useClickOutside";
import Input, { Props as InputProps } from "../../atoms/Input";
import Dropdown, { Props as DropdownProps, Item } from "../Dropdown";
import styles from "./index.module.css";

export interface SearchProps {
  onSearch: (value: String) => { label: string; value: string | number }[];
}

export interface Props extends InputProps, DropdownProps, SearchProps {}

const AutoCompleteMutli = forwardRef<HTMLInputElement, Props>(
  (props, ref): ReactElement => {
    const {
      items,
      onChange,
      open,
      onFocus,
      onSelectItem,
      onDeleteItem,
      handleSelectedItems,
      selectedItems,
      onSearch,
      error,
      ...rest
    } = props;
    const [dropdownOpen, setDropdownOpen] = useState(open);
    const [dropdownItems, setDropdownItems] = useState(items);
    const [currentValue, setCurrentValue] = useState<string | undefined>();

    const wrapperRref = useRef<HTMLDivElement | null>(null);

    const searchValues = (value: string) => {
      if (value.length) {
        setDropdownItems(onSearch(value));
        setDropdownOpen(true);
      } else {
        setDropdownOpen(false);
      }
    };

    const onChangeInputValues = (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      if (value) {
        searchValues(value);
      }
      setCurrentValue(value);
      if (onChange) onChange(event);
    };

    const onFocusInput = (event: FocusEvent<HTMLInputElement>) => {
      searchValues((currentValue ?? "")?.toString());
      if (onFocus) onFocus(event);
    };

    const isNewDestination = (value: string | number) => {
      const duplicate =
        selectedItems && selectedItems.filter((item) => item.value === value);
      return duplicate?.length === 0;
    };

    const onDropdownSelectItem = (item: Item) => {
      setDropdownOpen(false);
      if (isNewDestination(item.value)) {
        handleSelectedItems &&
          selectedItems &&
          handleSelectedItems([...selectedItems, item]);
        onSelectItem(item);
      } else {
        console.log("duplicate - destination already selected");
      }
      setCurrentValue("");
    };

    useClickOutside(wrapperRref, () => setDropdownOpen(false));

    return (
      <div className={styles.autoComplete} ref={wrapperRref}>
        <Input
          ref={ref}
          className={styles.autoCompleteInput}
          onChange={onChangeInputValues}
          onFocus={onFocusInput}
          value={currentValue || ""}
          onDeleteItem={onDeleteItem}
          label={rest.label}
          selectedItems={selectedItems}
          error={error}
        />
        <div className={styles.dropdown}>
          <Dropdown
            items={dropdownItems}
            onSelectItem={onDropdownSelectItem}
            open={dropdownOpen}
            secondary

            // {...rest}
          />
        </div>
      </div>
    );
  }
);

export default AutoCompleteMutli;
