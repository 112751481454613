import cx from "classnames";
import React, { ReactElement } from "react";
import { Props as IContextProps, RadioGroupProvider } from "../context";
import styles from "./index.module.css";

export interface Props extends IContextProps {
  className?: string;
}

const Group = ({ className, children, align, singleLine, ...rest }: Props): ReactElement => (
  <RadioGroupProvider {...rest}>
    <div
      className={cx(styles.radioGroup, className, styles[align ?? ""], {
        [styles.gap]: rest.buttonStyle === "button" || !rest.buttonStyle,
        [styles.singleLine]: singleLine,
      })}>
      {children}
    </div>
  </RadioGroupProvider>
);
export default Group;
