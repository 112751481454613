import { useEffect, useState } from "react";
import { requestOnboarding } from "../api/api-onboarding";
import { useFirebase } from "../config/context";
import { useGetSingleChatMessages } from "./useChatMessages";

export const useOnboarding = (userID: string) => {
  const firebase = useFirebase();
  const { messages } = useGetSingleChatMessages();
  // const [onBoardingInProgress, setOnBoardingInProgress] = useState<boolean | null>(null)
  const [lastOnboarded, setLastOnboarded] = useState<number | null>(null)
  const [adminTimestamp, setAdminTimestamp] = useState<number | null>(null);
  const [agentAttached, setAgentAttached] = useState<boolean | null>(null);
  const [ownerAttached, setOwnerAttached] = useState<boolean | null>(null);
  const [inQueue, setInQueue] = useState<boolean | null>(null);
  const [showKeyboard, setShowKeyboard] = useState<boolean>(true);

  useEffect(() => {
    const lastMsg = messages[messages.length - 1]
    if (lastMsg && lastMsg.alts && lastMsg.alts.length > 0) {
      const selectedAlt = lastMsg.alts.find((alt) => { return alt.selected })
      if (selectedAlt) {
        setShowKeyboard(selectedAlt ? selectedAlt.keyboardVisible : false)
      } 
    } else {
      setShowKeyboard(true)
    }
    const onboardingMessages = messages.filter((msg) => {
      return msg.alts && msg.alts.length > 0
    })
    const lastonBoardingMessage = onboardingMessages[onboardingMessages.length - 1]
    if (lastonBoardingMessage) {
      setLastOnboarded(lastonBoardingMessage.timestamp)
    } else {
      setLastOnboarded(1000) //has not been onboarded
    }
  }, [messages])

  const onboardUser = () => {
    setShowKeyboard(false)
    requestOnboarding(userID)
      .catch((error) => {
        console.log(error)
      })
  }


  const onAdminTimestampUpdate = (snapshot: firebase.database.DataSnapshot) => {
    if (snapshot.exists()) {
      if (snapshot.val()) {
        setAdminTimestamp(snapshot.val())
      }
    }
  }

  const onAgentTimestampUpdate = (snapshot: firebase.database.DataSnapshot) => {
    if (snapshot.exists() && snapshot.val()) {
      if (!adminTimestamp) {
        setAdminTimestamp(snapshot.val())
      }
      else if (adminTimestamp && snapshot.val() > adminTimestamp) {
        setAdminTimestamp(snapshot.val())
      }
    }
  }

  const onStickyAgentUpdate = (snapshot: firebase.database.DataSnapshot) => {
    if (snapshot.exists()) {
      setAgentAttached(true)
    } else {
      setAgentAttached(false)
    }
  }

  const onOwnerUpdate = (snapshot: firebase.database.DataSnapshot) => {
    if (snapshot.exists()) {
      setOwnerAttached(true)
    } else {
      setOwnerAttached(false)
    }
  }

  const onQueueUpdate = (snapshot: firebase.database.DataSnapshot) => {
    if (snapshot.exists()) {
      setInQueue(true)
    } else {
      setInQueue(false)
    }
  }

  useEffect(() => {
    const tenDaysAgo = Date.now() - (10 * 24 * 60 * 60 * 1000)
    if (adminTimestamp &&
        adminTimestamp < tenDaysAgo &&
        lastOnboarded &&
        lastOnboarded < tenDaysAgo &&
        agentAttached === false &&
        inQueue === false &&
        ownerAttached === false) {
          onboardUser()
    }
  }, [adminTimestamp, agentAttached, inQueue, lastOnboarded, ownerAttached]);

  useEffect(() => {
    firebase.getLastAdminTsQuery(userID).on("value", onAdminTimestampUpdate)
    firebase.getLastAgentTsQuery(userID).on("value", onAgentTimestampUpdate)
    firebase.getStickyAgent(userID).on("value", onStickyAgentUpdate)
    firebase.getOwner(userID).on("value", onOwnerUpdate)
    firebase.getWaitingSince(userID).on("value", onQueueUpdate)
  }, []);

  return { showKeyboard };
};
