import cx from "classnames";
import React, { ReactElement } from "react";
import { CheckboxGroupProvider, Props as IContextProps } from "../context";
import styles from "./index.module.css";

export interface Props extends IContextProps {
  /**
   * Classname
   */
  className?: string;
}

const Group = ({ className, children, singleLine = false, ...rest }: Props): ReactElement => (
  <CheckboxGroupProvider {...rest}>
    <div
      className={cx(styles.checkboxGroup, styles.gap, className, {
        [styles.singleLine]: singleLine,
      })}>
      {children}
    </div>
  </CheckboxGroupProvider>
);

export default Group;
