import React, { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import ChatInput from "../../../ChatInput";
import styles from "./index.module.css";

interface Props {
  userID: string;
  children: ReactNode;
  groupId: string;
  latestMessageTime: number | undefined;
  sendImage(file: File): Promise<void>
  sendText(text: string): Promise<void>
  fetchMore: () => void;
  hasMoreData: boolean;
  dataLength: number;
  batchSize: number;
  showLoader?: boolean;
  showEndMessage?: boolean;
  showKeyboard: boolean;
}

const Chat = (props: Props): ReactElement => {
  const bottomRef = useRef<HTMLSpanElement | null>(null);
  const scrollRef = useRef<null | HTMLDivElement>(null);
  const topRef = useRef<HTMLDivElement | null>(null);

  const [itemsLength, setItemsLength] = useState<number>(props.dataLength);
  const [loading, setLoading] = useState(true);
  const [existingScrollHeight, setExistingScrollHeight] = useState(0);

  useEffect(() => {
    if (props.dataLength > 0 || props.dataLength === 0) {
      setLoading(false);
    }
    scrollToBottom();
  }, []);

  useEffect(() => {
    // new msg addition
    if (props.dataLength === itemsLength + 1) {
      scrollToBottom();
      setItemsLength(props.dataLength);
      return;
    }
    // fetchMore case
    if (props.dataLength > itemsLength) {
      scrollTo(existingScrollHeight);
      setItemsLength(props.dataLength);
    }
  }, [props.dataLength]);

  const scrollTo = (prevScrollHeight: number) => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight - prevScrollHeight,
        behavior: "auto"
      });
    }
  };

  const scrollToBottom = () => {
    if (bottomRef) {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const getExistingScrollHeight = () => {
    let existingScrollHeight = 0;
    if (scrollRef && scrollRef.current) {
      existingScrollHeight = scrollRef.current.scrollHeight;
    }

    return existingScrollHeight;
  };

  const scrollbarVisible = () => {
    if (scrollRef && scrollRef.current) {
      return scrollRef.current.scrollHeight > scrollRef.current.clientHeight;
    }
    return false;
  };

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const { hasMoreData, fetchMore } = props;
    // set this height to keep scroll position for scrollTo after older messages are fetched
    const existingScrollHeight = getExistingScrollHeight();
    setExistingScrollHeight(existingScrollHeight);
    if (scrollbarVisible() && e.currentTarget.scrollTop === 0 && hasMoreData) {
      fetchMore()
    }
  };

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [props.latestMessageTime]);

  const renderTopElement = () => {
    const { showLoader, showEndMessage, hasMoreData } = props;

    if (loading && showLoader) {
      const message = "Loading...";
      <p className="ta-c endMessage">{message}</p>;
    } else if (!hasMoreData && showEndMessage) {
      const message = "You've seen it all:)";
      return <p className="ta-c endMessage">{message}</p>;
    } else {
      return <></>;
    }
  };

  return (
    <div className={styles.chat}>
      <div ref={scrollRef} onScroll={handleScroll} className={styles.content}>
        <div ref={topRef}>{renderTopElement()}</div>
        {props.children}
        <span ref={bottomRef} />
      </div>
      { props.showKeyboard &&
        <ChatInput
          groupId={props.groupId}
          sendImage={props.sendImage}
          sendText={props.sendText}
        /> }
    </div>
  );
};

export default Chat;
