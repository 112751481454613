import cx from "classnames";
import React, { Children, ReactElement, useEffect, useRef, useState } from "react";
import Icon from "../../atoms/Icon";
import styles from "./index.module.css";

export interface Props {
  className?: string;
  children: ReactElement | ReactElement[];
  navigation?: boolean;
  pagination?: boolean;
  currentIndex?: number;
  onNext?: () => void;
  onPrev?: () => void;
  onChange?: (index: number) => void;
  onChangeComplete?: () => void;
}

export const Slider = ({
  className = "",
  children,
  navigation,
  pagination,
  onNext,
  onPrev,
  onChange,
  onChangeComplete,
  currentIndex = 0,
  ...rest
}: Props): ReactElement => {
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const [slideIndex, setSlideIndex] = useState(currentIndex);

  const next = () => {
    const index = Math.min(slideIndex + 1, Children.count(children) - 1);
    setSlideIndex(index);
    if (onChange) onChange(index);
  };
  const prev = () => {
    const index = Math.max(slideIndex - 1, 0);
    setSlideIndex(index);
    if (onChange) onChange(index);
  };

  useEffect(() => {
    const index = Math.max(Math.min(currentIndex, Children.count(children) - 1), 0);

    setSlideIndex(index);
    if (onChange) onChange(index);
  }, [currentIndex, children, onChange]);

  return (
    <div className={cx(styles.slider, className, { [styles.padding]: pagination || navigation })} {...rest}>
      <div className={styles.wrapper} ref={sliderRef}>
        <div
          className={styles.slides}
          style={{
            transform: `translateX(-${slideIndex * (sliderRef.current?.getBoundingClientRect().width ?? 0)}px)`,
          }}
          onTransitionEnd={(event) => {
            if (event.nativeEvent.propertyName && onChangeComplete) onChangeComplete();
          }}>
          {Children.map(children, (child) => (
            <div className={styles.slide}>{child}</div>
          ))}
        </div>
      </div>

      {pagination && (
        <div className={styles.pagination}>
          {Children.map(children, (child, index) => (
            <span className={cx(styles.paginationDot, { [styles.active]: index === slideIndex })} />
          ))}
        </div>
      )}
      {navigation && (
        <>
          <button
            onClick={next}
            type='button'
            className={cx(styles.nextBtn, { [styles.disabled]: slideIndex === Children.count(children) - 1 })}>
            <Icon.Chevron style={{ transform: "scaleX(-1)" }} />
          </button>
          <button onClick={prev} type='button' className={cx(styles.prevBtn, { [styles.disabled]: slideIndex === 0 })}>
            <Icon.Chevron />
          </button>
        </>
      )}
    </div>
  );
};

export default Slider;
