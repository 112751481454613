import cx from "classnames";
import Markdown from 'markdown-to-jsx';
import React, { ReactElement, ReactNode } from "react";
import { IMessageActionStatus } from "../../../../../lib/types/enums";
import { IMessageAction } from "../../../../../lib/types/messages";
import Icon from "../../../atoms/Icon";
import Logo from "../../../atoms/Logo";
import styles from "./index.module.css";

export type Message = {
  id: string;
  name?: ReactNode;
  isUser: boolean;
  content?: ReactNode;
  markdown?: string;
  action?: IMessageAction;
  isAgent: boolean;
  hiddenText?: boolean
};

interface Props {
  message: Message;
  onActionClick?: (action: IMessageAction, messageId: string) => void;
}

const ActionIcon = ({ message, onActionClick }: Props) => {
  if (!message.action || message.action.status.toLowerCase() === IMessageActionStatus.Expired) return <></>;

  if (
    message.action.status.toLowerCase() === IMessageActionStatus.Completed ||
    message.action.status.toLowerCase() === IMessageActionStatus.CompletedBackground
  )
    return (
      <span className={styles.actionBtn}>
        <Icon.Checkmark className={styles.actionIconCheckmark} />
      </span>
    );

  return (
    <button
      type='button'
      className={styles.actionBtn}
      onClick={() => {
        if (onActionClick && message.action) onActionClick(message.action, message.id);
      }}>
      <Icon.PointArrow className={styles.actionIconArrow} />
    </button>
  );
};

const ChatItem = ({ message, onActionClick }: Props): ReactElement => {
  const { isUser, action, content, markdown, hiddenText, name, isAgent } = message;

  return (
    <>
      { markdown && hiddenText === false && <SharedQuestion content={content} isUser={isUser} /> }
      <div className={cx(styles.chatItem, { [styles.user]: isUser, [styles.action]: !!action && !name, [styles.sharedAnswer]: markdown && hiddenText === false})}>
        {name && (
          <span className={styles.name}>
            {isAgent && <Logo className={styles.logo} />}
            {name}
          </span>
        )}
        <span className={styles.content}>
          {markdown ?
            markdown && hiddenText === false ?
              <div className={cx([styles.sharedAnswer, { [styles.user]: isUser}])}><Markdown>{markdown}</Markdown></div>
              : <Markdown>{markdown}</Markdown>
              : content}
          <ActionIcon message={message} onActionClick={onActionClick} />
        </span>
      </div>
    </>
  );
};

interface SharedProps {
  content: ReactNode;
  isUser: boolean
}

const SharedQuestion = ({content, isUser}: SharedProps): ReactElement => {

  return (
    <>
      <div className={cx(styles.sharedQuestion, {[styles.user]: isUser})}>{content}</div>
    </>
  )
}

export default ChatItem;
