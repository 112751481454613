import React, { ReactElement, useEffect, useState } from "react";
import { useFirebase } from "../../../../config/context";
import CopyManager from "../../../../utils/CopyManager";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import { Props as FormProps } from "../../molecules/Form";
import Traveller, { Imperative, Schema } from "./forms/traveller";
import styles from "./index.module.css";

export interface TravellerSchema extends Schema {}

export interface Props extends FormProps {
  onComplete: (travellers: TravellerSchema[]) => void;
  onAbort: () => void;
}

const TravellersForm = ({
  children,
  className,
  onComplete,
  onAbort,
  ...rest
}: Props): ReactElement => {
  const firebase = useFirebase();

  const [count, setCount] = useState(0);
  const [travellers, setTravellers] = useState<string[]>([count.toString()]);
  const [formRefs] = useState(new Map<string, Imperative | null>());
  const [bonusPrograms, setBonusPrograms] = useState<Array<{ name: string }>>(
    []
  );

  useEffect(() => {
    firebase
      .getBonusPrograms()
      .once("value")
      .then((bps) => {
        setBonusPrograms(bps.val() as Array<{ name: string }>);
      });
  }, []);

  const save = async () => {
    let isValid = true;

    for (let key of formRefs.keys()) {
      if (formRefs.get(key) && !(await formRefs.get(key)?.validate()))
        isValid = false;
    }

    if (isValid) {
      const travellers: TravellerSchema[] = [];
      for (let key of formRefs.keys()) {
        const ref = formRefs.get(key);
        if (ref) travellers.push(ref.values());
      }
      onComplete(travellers ?? []);
    }
  };

  const deleteTraveller = (key: string) => {
    setTravellers(travellers.filter((id, index) => key !== id));
    formRefs.delete(key);
  };

  const addTraveller = () => {
    const c = count + 1;
    setCount(c);
    setTravellers([...travellers, c.toString()]);
  };

  const cancelForm = () => {
    onAbort();

    setTimeout(() => {
      setTravellers([count.toString()]);
      setCount(0);
    }, 500);
  };

  return (
    <div className={styles.travellersForm}>
      <div className={styles.travellers}>
        {travellers.map((key: string, index: number) => {
          return (
            <div key={key} className={styles.traveller}>
              <Traveller
                ref={(el) => formRefs.set(key, el)}
                index={index + 1}
                onDelete={index ? () => deleteTraveller(key) : undefined}
                bonusPrograms={bonusPrograms}
              />
            </div>
          );
        })}
        <Button
          icon={<Icon.Add className={styles.addIcon} />}
          className={styles.addBtn}
          onClick={addTraveller}
          grow={false}
        >
          {CopyManager.get("travellers_action_add")}
        </Button>
      </div>
      <div className={styles.footer}>
        <Button variant="secondary" onClick={cancelForm} grow={false}>
          {CopyManager.get("cancel")}
        </Button>
        <Button onClick={save} grow={false}>
          {CopyManager.get("save")}
        </Button>
      </div>
    </div>
  );
};
export default TravellersForm;
