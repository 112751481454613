import debounce from "lodash/debounce";
import { useEffect, useState } from "react";
import { useFirebase } from "../config/context";

export const useAgentTyping = (userID: string) => {
  const firebase = useFirebase();
  const [snapshot, setSnapshot] = useState<firebase.database.DataSnapshot | null>(null);
  const [updateCounter, setUpdateCounter] = useState<number>(0);
  const [typing, setTyping] = useState<boolean>(false);
  const delay = 3000;

  const resetTyping = debounce(function () {
    setTyping(false);
  }, delay);



  const onTypingUpdate = (snapshot: firebase.database.DataSnapshot) => {
    if (snapshot.exists()) {
      setSnapshot(snapshot);
    }
  }

  useEffect(() => {
    if (snapshot) {
      const val = snapshot.val()
      const timestamps = Object.keys(val)
        .map(key => ({id: key, timestamp: val[key]}))
        .sort((a, b) => b.timestamp - a.timestamp);
      
      if (timestamps && (timestamps[0].id === userID)) {
        return;
      }
      if (updateCounter !== 0) {
        setTyping(true)
        resetTyping()
      }
      setUpdateCounter(updateCounter + 1)
    }
  }, [snapshot]);

  useEffect(() => {
    firebase.getTypingTimestamps(userID).on("value", onTypingUpdate)
  }, []);

  return { typing };
};
