import firebase, { firestore } from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { BookingRequest } from "../../../lib/types/bookingRequest";
import { InvoiceOrg } from "../../../lib/types/invoices";
import { GroupTraveller, Traveller } from "../../../lib/types/traveller";
import firebaseConfig from "../firebaseConfig";

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const User = {
  userId: () => {
    const user = firebase.auth().currentUser;
    return user ? user.uid : "";
  },
};

const Firebase = {
  setLanguageCode: (languageCode: string) => {
    firebase.auth().languageCode = languageCode;
  },
  getAppLocale: (languageCode: string) => {
    return firebase.database().ref(`appLocale/${languageCode}`);
  },
  signInWithEmailAndPassword: (email: string, password: string) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  },
  signupWithEmail: (email: string, password: string) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  },
  signOut: () => {
    return firebase.auth().signOut();
  },
  onAuthStateChanged: (
    user: firebase.Observer<any, Error> | ((a: firebase.User | null) => any)
  ) => {
    return firebase.auth().onAuthStateChanged(user);
  },
  getUser: (userId: string) => {
    return firebase.database().ref(`/users/${userId}`);
  },
  getMessages: (userId: string, limit: number) => {
    return firebase
      .database()
      .ref(`conversations/${userId}/messages`)
      .orderByKey()
      .limitToLast(limit);
  },
  getMessagesBefore: (userId: string, lastDocID: string, limit: number) => {
    return firebase
      .database()
      .ref(`conversations/${userId}/messages`)
      .orderByKey()
      .endAt(lastDocID)
      .limitToLast(limit);
  },
  getCompany: (companyId: string) => {
    return firebase.database().ref(`/static/companies/${companyId}`);
  },
  getBookings: (userId: string) => {
    return firebase.database().ref(`users/${userId}/bookings/`);
  },
  getService: (serviceId: string) => {
    return firebase.database().ref(`static/services/${serviceId}`);
  },
  getCurrentUser: () => {
    const u = firebase.auth().currentUser;

    return u;
  },
  isLoggedIn: () => {
    return firebase.auth().currentUser ? true : false;
  },
  sendTextMessage: (userId: string, message: string) => {
    return firebase.database().ref(`/conversations/${userId}/messages`).push({
      authorID: userId,
      messageType: "text",
      text: message,
      timestamp: firebase.database.ServerValue.TIMESTAMP,
    });
  },
  sendChatflightsMessage: (userId: string, message: string) => {
    return firebase.database().ref(`/conversations/${userId}/messages`).push({
      authorID: "Chatflights",
      messageType: "text",
      text: message,
      timestamp: firebase.database.ServerValue.TIMESTAMP,
    });
  },
  sendImageMessage: (userId: string, file: File): Promise<void> => {
    let reader = new FileReader();
    reader.onloadend = () => {
      localStorage.setItem("lastUploadedImage", reader.result as string);
    };
    reader.readAsDataURL(file);

    const imageVersionMessage =
      "Vi har skickat dig en bild! Om du inte kan se den så behöver du uppdatera Chatflights-appen, gör det genom att ladda ner Chatflights igen från Appstore eller Google Play."; // can remove when all apps upgraded to latest version.
    const messagesRef = firebase
      .database()
      .ref(`/conversations/${userId}/messages`);
    let newMessageRef: firebase.database.Reference;
    let messageId: string | null;

    newMessageRef = messagesRef.push();
    messageId = newMessageRef.key;

    const extenstion = file.name.split(".").pop();
    const sessionId = userId + "@" + messageId + "." + extenstion;

    return new Promise((resolve) =>
      newMessageRef
        .set({
          authorID: userId,
          text: imageVersionMessage,
          timestamp: firebase.database.ServerValue.TIMESTAMP,
          messageType: "image",
          processState: "PENDING",
        })
        .then(() => {
          if (messageId != null) {
            let fileRef = firebase
              .storage()
              .ref("chatImages")
              .child(`${sessionId}`);

            let uploadTask = fileRef.put(file, { contentType: file.type });

            uploadTask.on(
              "state_changed",
              (snapshot: firebase.storage.UploadTaskSnapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                let progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");
                switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log("Upload is paused");
                    break;
                  case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log("Upload is running");
                    break;
                }
              },
              (error) => {
                console.log("Handle unsuccessful uploads");
                // Handle unsuccessful uploads
                messagesRef.child(`/${messageId}/processState`).set("FAILED");
                console.log("Upload Error", error);
              },
              () => {
                console.log("Handle successful uploads on complete");
                resolve();
              }
            );
          }
        })
    );
  },
  getLastImage: () => {
    return localStorage.getItem("lastUploadedImage");
  },

  // Groups
  getGroups: (userID: string) => {
    return firebase
      .firestore()
      .collection("groups")
      .where("membersList", "array-contains", userID)
      .orderBy("created", "desc");
  },

  getGroupMessages: (groupID: string, limit: number) => {
    return firebase
      .firestore()
      .collection("groups")
      .doc(groupID)
      .collection("messages")
      .orderBy("createdAt", "desc")
      .limit(limit);
  },
  getGroupMessagesBefore: (
    groupID: string,
    limit: number,
    firstMessageCreatedAt: firebase.firestore.Timestamp
  ) => {
    return firebase
      .firestore()
      .collection("groups")
      .doc(groupID)
      .collection("messages")
      .orderBy("createdAt", "desc")
      .startAfter(firstMessageCreatedAt)
      .limit(limit);
  },

  sendGroupTextMessage: (
    groupId: string,
    userId: string,
    userName: string | null,
    message: string
  ) => {
    return firebase
      .firestore()
      .collection("groups")
      .doc(groupId)
      .collection("messages")
      .add({
        authorID: userId,
        authorName: userName,
        authorType: "user",
        text: message,
        createdAt: firestore.FieldValue.serverTimestamp(),
      });
  },

  sendGroupImageMessage: async (
    groupID: string,
    filename: string,
    userId: string,
    userName: string | null,
    file: File
  ): Promise<void> => {
    let reader = new FileReader();
    reader.onloadend = () => {
      localStorage.setItem("lastUploadedImage", reader.result as string);
    };
    reader.readAsDataURL(file);

    const imageVersionMessage =
      "Vi har skickat dig en bild! Om du inte kan se den så behöver du uppdatera Chatflights-appen, gör det genom att ladda ner Chatflights igen från Appstore eller Google Play."; // can remove when all apps upgraded to latest version.

    return new Promise((resolve) => {
      //   newMessageRef = database().ref(`conversations/${userId}/messages`).push();
      // messageId = newMessageRef.key;

      const imageRef = firebase
        .storage()
        .ref("chatImages")
        .child(groupID + "_" + userId + "@" + uuidv4());

      let uploadTask = imageRef.put(file, { contentType: file.type });

      uploadTask.on(
        "state_changed",
        (snapshot: firebase.storage.UploadTaskSnapshot) => {
          let progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          //downloadUrl = snapshot.downloadURL;
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          console.log("Handle unsuccessful uploads");
          // Handle unsuccessful uploads
          //messagesRef.child(`/${messageId}/processState`).set("FAILED");
          console.log("Upload Error", error);
        },
        () => {
          console.log("Handle successful uploads on complete");
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            firebase
              .firestore()
              .collection("groups")
              .doc(groupID)
              .collection("messages")
              .add({
                authorID: userId,
                authorName: userName,
                authorType: "user",
                text: imageVersionMessage,
                createdAt: firestore.FieldValue.serverTimestamp(),
                attachment: {
                  type: file.type,
                  name: file.name,
                  url: downloadURL,
                },
              });
          });
          resolve();
        }
      );
    });
  },

  getLastMessageQuery: (userID: string) => {
    return firebase
      .database()
      .ref(`conversations/${userID}/messages`)
      .orderByChild("timestamp")
      .limitToLast(1);
  },

  getLastAdminTsQuery: (userID: string) => {
    return firebase
      .database()
      .ref(`conversations/${userID}/lastAdminMessageTimestamp`);
  },

  getLastAgentTsQuery: (userID: string) => {
    return firebase
      .database()
      .ref(`conversations/${userID}/lastAgentMessageTS`);
  },

  getLastOnlineAtQuery: (userID: string) => {
    return firebase.database().ref(`conversations/${userID}/lastOnlineAt`);
  },

  getGroupBookings: (groupUserID: string) => {
    return firebase.database().ref(`users/${groupUserID}/bookings`);
  },

  getGroupDetails: (groupID: string) => {
    return firebase.firestore().collection("groups").doc(groupID);
  },

  getInvites: (groupID: string) => {
    return firebase
      .firestore()
      .collection("groups")
      .doc(groupID)
      .collection("invites");
  },

  saveBookingRequest: (userID: string, br: BookingRequest) => {
    return firebase
      .database()
      .ref(`users/${userID}/awardQuestions/`)
      .push({
        createdAtTimestamp: firebase.database.ServerValue.TIMESTAMP,
        isRead: false,
        optionsResponse: [
          {
            question: "Where do you want to go?",
            response: br.destinations,
            selectedOptionsValue: br.regions,
          },
          {
            question: "What do you want to use?",
            selectedOptionsValue: [br.payment],
            selectedOptions: [br.payment],
          },
          {
            question: "Additional services?",
            selectedOptionsValue: br.services,
          },
        ],
        response: [
          {
            answer: br.points,
            question: "Number of points",
          },
          {
            answer: br.vouchers,
            question: "Vouchers",
          },
          {
            answer: br.depart,
            question: "From where are you travelling?",
          },
          {
            answer: br.depart_date.toLocaleDateString("sv-se"),
            question: "Earliest date of departure",
          },
          {
            answer: br.return_date.toLocaleDateString("sv-se"),
            question: "Latest date of return",
          },
          {
            answer: br.days_on_location,
            question: "Minimum days at destination",
          },
          {
            answer: br.travellers,
            question: "No. of travellers > 2 yrs",
          },
          {
            answer: br.cabin_class.toString(),
            question: "Booking Class",
          },
        ],
        status: "new",
      });
  },
  setMessageComplete: (userID: string, messageID: string) => {
    return firebase
      .database()
      .ref(`conversations/${userID}/messages/${messageID}/action/status`)
      .set("COMPLETED");
  },
  addTravellers: (userID: string, travellers: Traveller[]) => {
    travellers.forEach((traveller) => {
      firebase.database().ref(`users/${userID}/travellers/`).push(traveller);
    });
  },
  addGroupTravellers: (groupID: string, travellers: GroupTraveller[]) => {
    const batch = firebase.firestore().batch();
    travellers.forEach((traveller) => {
      const docRef = firebase
        .firestore()
        .collection("groups")
        .doc(groupID)
        .collection("travellers")
        .doc();

      batch.set(docRef, traveller);
    });
    return batch.commit();
  },
  getBonusPrograms: () => {
    return firebase.database().ref("static/bonusprograms/");
  },
  getAirports: () => {
    return firebase.database().ref("/static/airports");
  },
  removeInvoiceOrganization: (invoiceID: string, userId: string) => {
    const organizationsRef = firebase
      .database()
      .ref(`users/${userId}/invoiceOrganizations`);
    return organizationsRef.child(invoiceID).remove();
  },
  getInvoiceOrganizations: async (userID: string): Promise<InvoiceOrg[]> => {
    return await firebase
      .database()
      .ref(`users/${userID}/invoiceOrganizations`)
      .orderByChild("invoiced")
      .equalTo(true)
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          return Object.entries(snapshot.val()).map(([key, value]) => {
            const v: any = value;
            return {
              email: v["email"],
              id: key,
              name: v["name"],
              orgNum: v["orgNum"],
            };
          }) as InvoiceOrg[];
        } else {
          return [];
        }
      })
      .catch(() => []);
  },
  updateReadCursor: async (
    userID: string,
    groupID: string,
    timestamp: number
  ) => {
    return await firebase
      .firestore()
      .collection("groups")
      .doc(groupID)
      .update({ [`members.${userID}.readCursor`]: timestamp });
  },
  getChatPriorityQueue: () => {
    return firebase.database().ref("chatPriorityQueue").orderByValue();
  },
  getPriority: (userID: string) => {
    return firebase
      .database()
      .ref(`conversations/${userID}/isFollowUpRequired`);
  },
  prioritizeUser: (userID: string) => {
    return firebase
      .database()
      .ref(`conversations/${userID}/isFollowUpRequired`)
      .set(true);
  },
  updatedSession: (userID: string, pin: number, verified: boolean) => {
    return firebase.database().ref(`users/${userID}/chatflightSession`).set({
      pin: pin,
      timestamp: firebase.database.ServerValue.TIMESTAMP,
      verified: verified,
    });
  },
  getSession: (userID: string) => {
    return firebase.database().ref(`users/${userID}/chatflightSession`);
  },
  addAltsSelected: (userID: string, messageID: string, altIndex: number) => {
    return firebase
      .database()
      .ref(
        "/conversations/" +
          userID +
          "/messages/" +
          messageID +
          "/alts/" +
          altIndex +
          "/selected"
      )
      .set(true);
  },
  getStickyAgent: (userID: string) => {
    return firebase.database().ref(`conversations/${userID}/currentStickyAgent`)
  },
  getOwner: (userID: string) => {
    return firebase.database().ref(`conversations/${userID}/ownerAgent`)
  },
  getWaitingSince: (userID: string) => {
    return firebase.database().ref(`conversations/${userID}/userWaitingTS`)
  },
  getTypingTimestamps: (userID: string) => {
    return firebase.database().ref(`conversations/${userID}/lastTypingTimestamp`)
  },
  addDeletionRequest: (userID: string) => {
    firebase
      .firestore()
      .collection("userDeletionRequests")
      .doc(userID)
      .set({
        userID: userID,
        created: firestore.FieldValue.serverTimestamp(),
        processed: false,
      })
  },
  getUserDeletionRequest: (userID: string) => {
    return firebase
      .firestore()
      .collection("userDeletionRequests")
      .doc(userID)
  }
};

export default Firebase;
