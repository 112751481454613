import { useEffect, useState } from "react";
import { useFirebase } from "../config/context";
import { useApp } from "../config/context/app-contex";
import { MessageType, ProcessState } from "../lib/types/enums";
import { IGroupMessage, IMessage } from "../lib/types/messages";

export const useGetSingleChatMessages = (listen: boolean = true) => {
  const firebase = useFirebase();
  const app = useApp();
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true)
  const limit = 25

  const fetchOlderMessages = () => {
    if (hasMore) {
      const uid = firebase.getCurrentUser()?.uid;
      if (uid) {
        firebase.getMessagesBefore(uid, messages[0].id,limit).once("value")
          .then((res) => {
            if (res.numChildren() === 0) {
              setHasMore(false)
              return
            }
            const msgs = Array<IMessage>();
            let i = 0
            res.forEach((child: firebase.database.DataSnapshot) => {
              if (i !== limit -1) { // Workaround for old firebase library without support for endBefore
                msgs.push({
                  id: child.key,
                  ...child.val(),
                } as IMessage);
              }
              i ++;
            });
            setMessages((prevState) => [...msgs, ...prevState]);
          })
      }
    }
  }

  useEffect(() => {
    const uid = firebase.getCurrentUser()?.uid;

    const onMessages = (snapshot: any) => {
      const allMessages = Array<IMessage>();
      const messages = Array<IMessage>();
      snapshot.forEach((childSnaphot: any) => {
        allMessages.push({
          id: childSnaphot.key,
          ...childSnaphot.val(),
        });
      });
      let images: Array<string> = [];
      let imagesObject: Array<{ src: string }> = [];

      allMessages.forEach((message) => {
        if (message.isArchived) {
          return;
        }

        if (message.messageType === MessageType.Image) {
          if (message.processState !== ProcessState.Failed) {
            const image = message.imageUrl && message.imageUrl.real ? message.imageUrl.real : firebase.getLastImage();
            images.push(image);
            imagesObject.push({ src: image });
            messages.push(message);
          }
        } else {
          messages.push(message);
        }
      });
      setMessages(messages);
    };

    if (app.userLoggedIn && uid) {
      if (listen) firebase.getMessages(uid, limit).on("value", onMessages);
      else firebase.getMessages(uid, limit).once("value", onMessages);
    }

    return () => {
      firebase.getMessages(firebase.getCurrentUser()?.uid ?? "", limit).off("value", onMessages);
    };
  }, [listen, app.userLoggedIn]);

  return { messages, fetchOlderMessages, hasMore };
};

export const useGetGroupChatMessages = (chatId: string | null = null) => {
  const firebase = useFirebase();
  const [messages, setMessages] = useState<IGroupMessage[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true)
  const limit = 25

  const fetchOlderMessages = () => {
    if (hasMore) {
      firebase.getGroupMessagesBefore(chatId ?? "", limit, messages[0].createdAt)
        .get()
        .then((snap) => {
          if (snap.docs.length === 0) {
            setHasMore(false)
            return
          }
          console.log(snap.docs.map((el, i) => [i, el.data()]))
          const oldMessages: IGroupMessage[] = snap.docs.flatMap((element) => ({
            id: element.id,
            ...element.data()
          } as IGroupMessage ));
          setMessages((prevState) => [...oldMessages.reverse(), ...prevState]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    const unsubscribe = firebase.getGroupMessages(chatId ?? "", limit).onSnapshot(
      (snapshot) => {
        let messages: IGroupMessage[] = [];
        snapshot.forEach((doc) => {
          const data = doc.data() as IGroupMessage;
          data.id = doc.id;
          messages.unshift(data);
        });
        setMessages(messages);
      },
      (error) => {
        console.log(error);
      }
    );
    return () => unsubscribe();
  }, [chatId]);

  return { messages, fetchOlderMessages, hasMore };
};
