import cx from "classnames";
import React, { ReactElement } from "react";
import { useTranslations } from "../../../../../hooks/useTranslations";
import AppDetails from "../../../atoms/AppDetails";
import Typography from "../../../atoms/Typography";
import LoginForm, { Props as FormProps } from "../../../organisms/LoginForm";
import styles from "./index.module.css";

export interface Props extends FormProps {}

const Form = ({ className, ...rest }: Props): ReactElement => {

  const {getCopy, loaded} = useTranslations()

  if (loaded) {
    return (
      <section className={cx(styles.form, className)}>
        <header className={styles.header}>
          <Typography.Title level={1}>
            {getCopy("login_header")}
          </Typography.Title>
          <Typography.Text>{getCopy("login_subheader")}</Typography.Text>
        </header>
        <LoginForm {...rest} />
        <div>
          <Typography.Title level={4}>
            {getCopy("login_forgot_password_question")}
          </Typography.Title>
          <Typography.Text light={false}>
            {getCopy("login_forgot_password_description")}{" "}
            <a
              href="https://apps.apple.com/us/app/chatflights/id1291252056"
              target="_blank"
              rel="noreferrer"
            >
              Apple App Store
            </a>{" "}
            {getCopy("or") } {" "}
            <a
              href="https://play.google.com/store/apps/details?id=com.chatflights"
              target="_blank"
              rel="noreferrer"
            >
              Google Play.
            </a>
          </Typography.Text>
        </div>
        <AppDetails label={getCopy("app_rating_text")} />
        <Typography.Text light={false}>
          <Typography.Bold>{getCopy("login_catchphrase")}</Typography.Bold>
        </Typography.Text>
      </section>
    );
  } else {
    return <></>
  }
};
export default Form;
