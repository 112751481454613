import React, { HTMLAttributes, ReactElement } from "react";
import { IFirebase } from "../../../config/Firebase/type";
import { withFirebaseHOC } from "../../../config/context";
import { useApp } from "../../../config/context/app-contex";
import { useGetSingleChatMessages } from "../../../hooks/useChatMessages";
import { useGroups } from "../../../hooks/useGroups";
import CopyManager from "../../../utils/CopyManager";
import Toast from "../../generic/organisms/Toast";
import Dashboard from "../../generic/templates/Dashboard";
import Avatar from "./assets/avatar.svg";
import { NewCreateGroup } from "./dialogs";

export interface Props extends HTMLAttributes<HTMLDivElement> {
  firebase: IFirebase;
}

const DashboardPage = ({
  className,
  children,
  firebase,
  ...rest
}: Props): ReactElement => {
  const { messages } = useGetSingleChatMessages();
  const { createGroup, groups, fetching } = useGroups();
  const app = useApp();
  const user = firebase.getCurrentUser();

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const onCreateNewGroup = async () => {
    app.openModal(
      <NewCreateGroup
        onClose={app.closeModal}
        onConfirm={async (values, showError, isLoading) => {
          isLoading(true);
          const reponse = await createGroup(
            values.group_name,
            values.members.map((member) => ({
              name: member.name,
              phoneNumber: member.phone_number,
            }))
          );
          if (reponse?.ok) {
            await timeout(2500);
            app.closeModal();
          } else {
            app.openToast(
              <Toast onClose={app.closeToast}>
                {CopyManager.get("something_wrong")}
              </Toast>
            );
          }
          isLoading(false);
        }}
      />
    );
  };

  return (
    <Dashboard
      onCreateNewGroup={onCreateNewGroup}
      user={{
        firstName: user?.displayName ?? "",
        lastName: "",
        email: user?.email || "",
      }}
      onLogout={firebase.signOut}
      fetchingGroups={fetching}
      conversations={[
        ...[
          {
            group: false,
            id: "private",
            img: <img src={Avatar} alt="Chatflights avatar" />,
            name: CopyManager.get("private_chat"),
            preamble: `${messages.at(-1)?.text ?? ""}`,
            update: new Date(messages.at(-1)?.timestamp ?? 0),
            unread: false,
            link: "/chat",
          },
        ],
        ...groups.map((group) => {
          return {
            group: true,
            id: group.id,
            img: <img src={group.groupImageUrl} alt="Group avatar" />,
            name: group.name,
            preamble:
              group.lastMessage ? group.lastMessage.authorName + ": " + group.lastMessage.text : "",
            update: group.lastMessage ? group.lastMessage.createdAt.toDate() : group.created.toDate(),
            unread: user && group.lastMessage
              ? group.members[user.uid].readCursor <
              group.lastMessage.createdAt.seconds * 1000 &&
                group.lastMessage.authorName !== group.members[user.uid].name
              : false,
            link: "/chat/" + group.id,
          };
        }),
      ]}
    />
  );
};
export default withFirebaseHOC(DashboardPage);
