import cx from "classnames";
import React, { Children, ReactElement, ReactNode } from "react";
import Typography from "../../../atoms/Typography";
import styles from "./index.module.css";

export interface Props {
  children?: ReactNode;
  className?: string;
  title: string;
}

const Payment = ({ className, children, title, ...rest }: Props): ReactElement => {
  return (
    <div className={cx(styles.optionList, className)}>
      <header className={styles.header}>
        <Typography.Title>{title}</Typography.Title>
      </header>
      <ul className={styles.list}>
        {Children.map(children, (child) => (
          <li className={styles.option}>{child}</li>
        ))}
      </ul>
    </div>
  );
};
export default Payment;
