import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { useFirebase } from "../../../../config/context";
import { Props as FormProps } from "../../molecules/Form";
import StepSlider from "../../molecules/StepSlider";
import DestinationForm, {
  DestinationSchema as DestinationFormSchema,
} from "./forms/desination-form";
import DetailsForm, {
  DetailsSchema as DetailsFormSchema,
} from "./forms/details-form";
import { Imperative } from "./forms/types";

export interface Props extends FormProps {
  children?: ReactNode;
  className?: string;
  onComplete: (values: Schema) => void;
  onAbort: () => void;
  onShowInfo: (title: ReactNode, body: ReactNode) => void;
}

export interface Schema extends DestinationFormSchema, DetailsFormSchema {}

export interface IAirport {
  tz: string;
  iata: string;
  city: string;
  country: string;
}

const InqueryForm = ({
  children,
  className,
  onComplete,
  onAbort,
  onShowInfo,
  ...rest
}: Props): ReactElement => {
  const destinationRef = useRef<Imperative<DestinationFormSchema> | null>(null);
  const detailsRef = useRef<Imperative<DetailsFormSchema> | null>(null);
  const currentRef = useRef<Imperative<unknown> | null>(destinationRef.current);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [airports, setAirports] = useState<IAirport[]>([]);
  const firebase = useFirebase();

  useEffect(() => {
    firebase
      .getAirports()
      .once("value")
      .then((aps) => {
        setAirports(Object.values(aps.val()) as IAirport[]);
      });
  }, []);

  const onNext = async () => {
    if (currentIndex === 0) currentRef.current = destinationRef.current;
    if (currentIndex === 1) currentRef.current = detailsRef.current;

    const valid = await currentRef.current?.validate();

    return Promise.resolve(valid ?? true);
  };

  const onChange = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <StepSlider
      currentIndex={currentIndex}
      onComplete={() => {
        if (destinationRef.current && detailsRef.current) {
          const values = {
            ...destinationRef.current.values(),
            ...detailsRef.current.values(),
          };
          onComplete(values);
        }
      }}
      destinationRef={destinationRef.current}
      detailsRef={detailsRef.current}
      onAbort={onAbort}
      onNext={onNext}
      onChange={onChange}
    >
      <DestinationForm
        ref={destinationRef}
        onShowInfo={onShowInfo}
        airports={airports.map((a) => {
          return { label: `${a.iata} ${a.city}, ${a.country}`, value: a.iata };
        })}
      />
      <DetailsForm
        ref={detailsRef}
        onShowInfo={onShowInfo}
        usePoints={true}
        airports={airports.map((a) => {
          return { label: `${a.iata} ${a.city}, ${a.country}`, value: a.iata };
        })}
      />
    </StepSlider>
  );
};
export default InqueryForm;
