import { zodResolver } from "@hookform/resolvers/zod";
import React, { ReactElement, ReactNode, useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import CopyManager from "../../../../utils/CopyManager";
import Input from "../../atoms/Input";
import Form, { Props as FormProps } from "../../molecules/Form";

export const schema = z.object({
  org_nr: z.string().nonempty("Organization number required."),
  email: z.string().email("Please enter a valid email").nonempty("Email is required."),
  name: z.string().nonempty("Name required.").optional(),
});

export type Schema = z.infer<typeof schema>;

export interface Props extends FormProps {
  children?: ReactNode;
  className?: string;
  actions?: ReactNode;
  defaultValues?: Schema;
  values?: Schema;
  disabled?: boolean;
  onComplete: (values: Schema) => void;
}

const CompanyForm = ({
  children,
  className,
  onComplete,
  actions,
  defaultValues = { org_nr: "", email: "" },
  disabled = false,
  ...rest
}: Props): ReactElement => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Schema>({
    mode: "onTouched",
    resolver: zodResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    setValue("name", defaultValues.name);
    setValue("org_nr", defaultValues.org_nr);
    setValue("email", defaultValues.email);
  }, [defaultValues]);

  return (
    <Form.Styled columns={1} onSubmit={handleSubmit(onComplete)} footer={actions} {...rest}>
      <Input label={CopyManager.get("invoice_new_org_label_orgNumber")} {...register("org_nr")} error={errors.org_nr?.message} disabled={disabled} />
      <Input label={CopyManager.get("invoice_new_org_label_email")} {...register("email")} error={errors.email?.message} disabled={disabled} />
      {defaultValues.name && (
        <Input
          label={CopyManager.get("invoice_new_org_label_orgName")}
          {...register("name")}
          error={errors.name?.message}
          disabled
          defaultValue={defaultValues.name}
        />
      )}
    </Form.Styled>
  );
};
export default CompanyForm;
