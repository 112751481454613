import { zodResolver } from "@hookform/resolvers/zod";
import React, { forwardRef, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import CopyManager from "../../../../../utils/CopyManager";
import Button from "../../../atoms/Button";
import Icon from "../../../atoms/Icon";
import Input from "../../../atoms/Input";
import Typography from "../../../atoms/Typography";
import Form from "../../../molecules/Form";
import styles from "./index.module.css";

export const schema = z.object({
  name: z.string().nonempty("Name required."),
  phone_number: z
    .string()
    .nonempty("Number required.")
    .refine(
      (number) => /^\+(?:[0-9] ?){6,14}[0-9]$/.test(number),
      "Please add number in format +[country_code]XXXXXXXXX"
    ),
});

export type Schema = z.infer<typeof schema>;

export interface Imperative {
  validate: () => Promise<boolean>;
  values: () => Schema;
}

export interface Props {
  index?: number;
  onDelete?: (index?: number) => void;
}

const Member = forwardRef<Imperative, Props>((props, ref) => {
  const { index, onDelete, ...rest } = props;

  const {
    trigger,
    getValues,
    register,
    formState: { errors },
  } = useForm<Schema>({
    mode: "onTouched",
    resolver: zodResolver(schema),
  });

  useImperativeHandle(ref, () => ({
    async validate() {
      return await trigger();
    },
    values() {
      return getValues();
    },
  }));

  return (
    <div className={styles.memberForm}>
      {index && (
        <Typography.Title level={4} className={styles.title}>
          {CopyManager.get("group_member")} {index === 1 ? "" : index}
          {onDelete && (
            <Button
              icon={<Icon.Remove className={styles.removeIcon} />}
              className={styles.removeBtn}
              onClick={() => onDelete(index)}
              grow={false}>
               {CopyManager.get("delete")}
            </Button>
          )}
        </Typography.Title>
      )}
      {/* ^\d{10}$ */}
      <Form.Styled columns={1} {...rest}>
        <Input label={CopyManager.get("create_group_member_name")} {...register("name")} error={errors.name?.message} />
        <Input
          label={CopyManager.get("phone_number")}
          placeholder='+46XXXXXXXXX'
          type='tel'
          {...register("phone_number")}
          error={errors.phone_number?.message}
        />
      </Form.Styled>
    </div>
  );
});

export default Member;
