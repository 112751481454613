import React, { ReactElement, useEffect, useState } from "react";
import ChatMessages, { Props as IMessages } from ".";
import { useFirebase } from "../../../../config/context";
import { useGetGroupChatMessages } from "../../../../hooks/useChatMessages";
import { GroupDetails } from "../../../../lib/types/group";
import Chat from "../../../generic/organisms/Chat";

export interface Props extends Omit<IMessages, "messages"|"onFetchOlderClick"|"hasMore"> {
  userId: string;
  groupId: string;
  group: GroupDetails | null;
}

const GroupChat = ({ groupId, userId, group, ...rest }: Props): ReactElement => {
  const { messages, fetchOlderMessages, hasMore } = useGetGroupChatMessages(groupId);
  const userName = group ? group.members[userId].name : "";
  const firebase = useFirebase();
  const [lastMessageTS, setLastMessageTS] = useState<number | undefined>();

  const sendGroupTextMessage = (text: string) => {
    return firebase.sendGroupTextMessage(groupId, userId, userName, text)
  }

  const sendGroupImageMessage = (file: File) => {
    return firebase.sendGroupImageMessage(groupId, file.name, userId, userName, file)
  }

  useEffect(() => {
    const mostRecentMsg = messages[messages.length -1]
    if (mostRecentMsg && mostRecentMsg.createdAt) {
      setLastMessageTS(mostRecentMsg.createdAt.seconds * 1000)
    }
  }, [messages])

  useEffect(() => {
    if (lastMessageTS) {
      firebase.updateReadCursor(userId, groupId, lastMessageTS)
    }
  }, [lastMessageTS])

  return (
    <>
      <Chat
        userID={userId}
        groupId={groupId}
        latestMessageTime={lastMessageTS}
        sendImage={sendGroupImageMessage}
        sendText={sendGroupTextMessage}
        dataLength={messages.length}
        fetchMore={fetchOlderMessages}
        hasMoreData={hasMore}
        batchSize={100}
        showLoader={true}
        showEndMessage={true}
        showKeyboard={true}
      >
        <ChatMessages
          {...rest}
          messages={messages.map((message) => ({
            ...message,
            ...{
              isUser: userId === message.authorID,
              timestamp: message.createdAt ? new Date(message.createdAt.seconds * 1000) : new Date(),
              messageType: "text",
              authorName: userId !== message.authorID ? message.authorName : undefined,
              markdown: message.textMarkdown,
              hiddenText: message.textMarkdown && message.text ? false : undefined
            },
          }))}
        />
      </Chat>
    </>
  );
};
export default GroupChat;
