import { useEffect, useState } from "react";
import { useFirebase } from "../config/context";

export const usePriority = (userID: string) => {
  const firebase = useFirebase();
  const [priority, setPriority] = useState<boolean>();

  const onPriorityUpdate = (snapshot: firebase.database.DataSnapshot) => {
    if (snapshot.exists()) {
      if (snapshot.val()) {
        setPriority(true)
      } else {
        setPriority(false)
      }
    }
  }

  useEffect(() => {
    firebase.getPriority(userID).on("value", onPriorityUpdate)
  }, []);

  return { priority };
};
