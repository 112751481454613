import qs from "qs";
import firebaseConfig from "../config/Firebase/firebaseConfig";
import { Card, CardIntentResponse, PaymentIntentResponse, ResponseCard } from "./types";

const baseOptions = {
  "Content-Type": "application/json",
};

const stripe_base_url = `https://stripe-dot-${firebaseConfig.projectId}.appspot.com`;

export const getCards = async (userID: string): Promise<ResponseCard[] | null> => {
  try {
    const response = await fetch(`${stripe_base_url}/getCreditCards/${userID}/`, {
      ...baseOptions,
      ...{ method: "GET" },
    });
    return response.json();
  } catch (error) {
    console.log("failed to get cards for user", userID, error);
    return null;
  }
};

export const getCardToken = async (
  card: Card,
  key: string
): Promise<{ id?: string; error?: { code: string; type: string, message: string } } | null> => {
  const expMonth = card.expire_date.getMonth();
  const expYear = card.expire_date.getFullYear();
  const data = {
    "card[number]": card.number,
    "card[exp_month]": expMonth,
    "card[exp_year]": expYear,
    "card[cvc]": card.cvv,
    "card[name]": card.name,
  };
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: `Bearer ${key}` },
    body: qs.stringify(data),
  };
  try {
    const response = await fetch("https://api.stripe.com/v1/tokens", requestOptions);
    return response.json();
  } catch (error) {
    console.log("Failed to generate token", error);
    return null;
  }
};

export const setupIntent = async (userID: string, token: string): Promise<CardIntentResponse | null> => {
  try {
    const response = await fetch(`${stripe_base_url}/setupIntent/${userID}/${token}`, {
      ...baseOptions,
      ...{ method: "POST" },
    });
    return response.json();
  } catch (error) {
    console.log("failed to setupIntent with user", userID, error);
    return null;
  }
};

export const confirmSetupIntent = async (
  userID: string,
  setupIntentID: string,
  paymentMethodID: string
): Promise<CardIntentResponse | null> => {
  try {
    const response = await fetch(
      `${stripe_base_url}/confirmSetupIntent/${userID}/${setupIntentID}/${paymentMethodID}`,
      {
        ...baseOptions,
        ...{ method: "POST" },
      }
    );
    return response.json();
  } catch (error) {
    console.log("failed to confirm setupIntent with user", userID, error);
    return null;
  }
};

export const detachPaymentMethod = async (userID: string, paymentID: string): Promise<Response | null> => {
  try {
    const response = await fetch(`${stripe_base_url}/detachPaymentMethod/${paymentID}/${userID}`, {
      ...baseOptions,
      ...{ method: "POST" },
    });
    return response.json();
  } catch (error) {
    console.log("failed to detachPaymentMethod with user", userID, paymentID, error);
    return null;
  }
};

export const createPaymentIntent = async (
  userID: string,
  bookingID: string,
  bookingType: string,
  paymentMethodID: string,
  messageID: string
): Promise<PaymentIntentResponse | null> => {
  try {
    const response = await fetch(`${stripe_base_url}/create-payment-intent`, {
      ...baseOptions,
      ...{
        method: "POST",
        body: JSON.stringify({
          userID,
          bookingID,
          bookingType,
          paymentMethodID,
          messageID,
        }),
      },
    });
    return response.json();
  } catch (error) {
    console.log("failed to create payment intent with user", userID, error);
    return null;
  }
};

export const createGroupPaymentIntent = async (
  userID: string,
  bookingType: string,
  bookingID: string,
  cardID: string,
  groupID: string,
  groupUserID: string,
  messageID: string
): Promise<PaymentIntentResponse | null> => {
  try {
    const response = await fetch(`${stripe_base_url}/groups/create-payment-intent`, {
      ...baseOptions,
      ...{
        method: "POST",
        body: JSON.stringify({
          userID,
          bookingType,
          bookingID,
          groupUserID,
          messageID,
          groupID,
          paymentMethodID: cardID,
        }),
      },
    });
    return response.json();
  } catch (error) {
    console.log("failed to confirm payment intent with group", userID, error);
    return null;
  }
};

export const confirmPaymentIntent = async (
  userID: string,
  paymentIntentID: string,
  paymentMethodID: string
): Promise<PaymentIntentResponse | null> => {
  try {
    const response = await fetch(
      `${stripe_base_url}/confirmPaymentIntent/${userID}/${paymentIntentID}/${paymentMethodID}`,
      {
        ...baseOptions,
        ...{
          method: "POST",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("failed to confirm payment intent with user", userID, error);
    return null;
  }
};

export const retrievePaymentIntent = async (
  userID: string,
  type: string,
  paymentMethodID: string
): Promise<PaymentIntentResponse | null> => {
  try {
    const response = await fetch(`${stripe_base_url}/retrievePaymentIntent/${userID}/${type}/${paymentMethodID}`, {
      ...baseOptions,
      ...{
        method: "POST",
      },
    });
    return response.json();
  } catch (error) {
    console.log("failed to retrieve payment intent with user", userID, error);
    return null;
  }
};
