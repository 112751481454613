import React, { ReactElement, useState } from "react";
import CopyManager from "../../../../utils/CopyManager";

import Button from "../../../generic/atoms/Button";
import Dialog from "../../../generic/molecules/Dialog";
import Slider from "../../../generic/molecules/Slider";
import styles from "./index.module.css";

export interface Props {
  onAbort: () => void;
  onConfirm: () => void;
}

const GetHelp = ({ onAbort, onConfirm }: Props): ReactElement => {
  const [currentIndex, setCurrentIndex] = useState(0);
  return (
    <div className={styles.closed}>
      <Slider currentIndex={currentIndex}>
        <Dialog
          className={styles.view}
          title={CopyManager.get("week_work_title")}
          body={CopyManager.get("week_work_body")}
          actions={
            <>
              <Button variant="secondary" onClick={onAbort}>
                {CopyManager.get("cancel_button_text")}
              </Button>
              <Button variant="secondary" onClick={() => setCurrentIndex(1)}>
                {CopyManager.get("in_hurry")}
              </Button>
            </>
          }
        />
        <Dialog
          className={styles.view}
          title={CopyManager.get("confirm_title")}
          body={CopyManager.get("chat_queue_message")}
          actions={
            <>
              <Button variant="secondary" onClick={onConfirm}>
                {CopyManager.get("yes")}
              </Button>
              <Button variant="secondary" onClick={() => setCurrentIndex(0)}>
                {CopyManager.get("no")}
              </Button>
            </>
          }
        />
      </Slider>
    </div>
  );
};
export default GetHelp;
