import React, { ReactElement } from "react";
import { getLocalDate } from "../../../../../utils/date";
import styles from "./index.module.css";

interface Props {
  date: Date;
}

const Time = ({ date }: Props): ReactElement => {
  return (
    <time className={styles.time}>
      {getLocalDate(date, {
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      })}
    </time>
  );
};

export default Time;
