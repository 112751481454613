import cx from "classnames";
import React, {
  Children,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import CopyManager from "../../../../utils/CopyManager";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import IconButton from "../../atoms/IconButton";
import Typography from "../../atoms/Typography";
import { DestinationSchema } from "../../organisms/InqueryForm/forms/desination-form";
import { DetailsSchema } from "../../organisms/InqueryForm/forms/details-form";
import { Imperative } from "../../organisms/InqueryForm/forms/types";
import styles from "./index.module.css";

export interface Props {
  className?: string;
  children: ReactElement | ReactElement[];
  navigation?: ReactNode;
  pagination?: boolean;
  currentIndex?: number;
  onNext?: () => Promise<boolean>;
  onPrev?: () => Promise<boolean>;
  onChange?: (index: number) => void;
  onComplete?: () => void;
  onAbort?: () => void;
  destinationRef?: Imperative<DestinationSchema> | null;
  detailsRef?: Imperative<DetailsSchema> | null;
}

export const StepSlider = ({
  className = "",
  children,
  navigation,
  currentIndex,
  onComplete,
  onNext,
  onPrev,
  onChange,
  onAbort,
  destinationRef,
  detailsRef,
  ...rest
}: Props): ReactElement => {
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const [slideIndex, setSlideIndex] = useState(currentIndex ?? 0);
  const [isLastSlide, setIsLastSlide] = useState(false);

  const next = () => {
    const index = Math.min(slideIndex + 1, Children.count(children) - 1);
    setSlideIndex(index);
    if (onChange) onChange(index);
  };
  const prev = () => {
    const index = Math.max(slideIndex - 1, 0);
    setSlideIndex(index);
    if (onChange) onChange(index);
  };
  const complete = () => {
    if (onComplete) onComplete();
    setTimeout(() => {
      setSlideIndex(0);
      destinationRef?.resetForm();
      detailsRef?.resetForm();
    }, 2000);
  };

  useEffect(() => {
    setIsLastSlide(!!(slideIndex === Children.count(children) - 1));
  }, [slideIndex, children]);

  useEffect(() => {
    if (currentIndex) setSlideIndex(currentIndex);
  }, [currentIndex]);

  const cancelForm = () => {
    if (onChange) onChange(0);
    if (onAbort) onAbort();
    setTimeout(() => {
      setSlideIndex(0);
      destinationRef?.resetForm();
      detailsRef?.resetForm();
    }, 2000);
  };

  return (
    <div className={cx(styles.stepSlider, className)}>
      <div className={styles.slider} ref={sliderRef} {...rest}>
        <div
          className={styles.slides}
          style={{
            transform: `translateX(-${
              slideIndex *
              (sliderRef.current?.getBoundingClientRect().width ?? 0)
            }px)`,
          }}
        >
          {Children.map(children, (child) => (
            <div className={styles.slide}>{child}</div>
          ))}
        </div>
      </div>
      <div role="navigation" className={styles.navigation}>
        <div className={styles.pagination}>
          {slideIndex > 0 && (
            <IconButton
              className={styles.prevBtn}
              onClick={async () => {
                let proceed = true;
                if (onPrev) proceed = await onPrev();

                if (!proceed) return;
                prev();
              }}
            >
              <Icon.Direction />
            </IconButton>
          )}
          <Typography.Text as="span" className={styles.paginationLabel}>
            {slideIndex + 1} / {Children.count(children)}
          </Typography.Text>
        </div>
        <Button variant="secondary" onClick={cancelForm} grow={false}>
          {CopyManager.get("cancel")}
        </Button>
        {onComplete && (
          <Button
            grow={false}
            onClick={async () => {
              let proceed = true;
              if (onNext) proceed = await onNext();

              if (!proceed) return;
              isLastSlide ? complete() : next();
            }}
          >
            {CopyManager.get("continue")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default StepSlider;
