import cx from "classnames";
import React, { HTMLAttributes, ReactElement } from "react";
import Typography from "../Typography";
import styles from "./index.module.css";

export interface Props extends HTMLAttributes<HTMLDivElement> {
  label: string;
}

const AppDetails = ({ className, label, ...rest }: Props): ReactElement => (
  <div className={cx(styles.appDetails, className)} {...rest}>
    <svg width='51' height='51' viewBox='0 0 51 51' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='51' height='51' rx='10' fill='#439FE2' />
      <g clipPath='url(#clip0_1260_506)'>
        <path
          d='M37.8014 15.3035L35.0981 18.0148C32.4214 15.3309 28.7913 13.8234 25.0064 13.8239C21.2214 13.8244 17.5917 15.3329 14.9157 18.0175C12.2397 20.7021 10.7366 24.3429 10.7371 28.139C10.7376 31.9352 12.2417 35.5756 14.9184 38.2595L12.2133 40.9797C10.5324 39.2946 9.19879 37.294 8.28874 35.092C7.3787 32.89 6.91001 30.5297 6.90942 28.1461C6.90825 23.332 8.81386 18.7147 12.2071 15.3098C15.6002 11.9049 20.2031 9.99141 25.0029 9.99023C29.8028 9.98906 34.4065 11.9003 37.8014 15.3035Z'
          fill='white'
        />
        <path
          d='M42.3701 19.8125L33.7769 28.4311L29.2493 23.892L26.5425 26.6158L31.0682 31.1567L29.5393 32.6901C28.3368 33.8938 26.7072 34.5698 25.0082 34.5698C23.3092 34.5698 21.6797 33.8938 20.4771 32.6901L17.7738 35.4032C19.6939 37.3248 22.2957 38.4041 25.0082 38.4041C27.7207 38.4041 30.3225 37.3248 32.2426 35.4032L45.0751 22.5327L42.3701 19.8125Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_1260_506'>
          <rect width='38.1538' height='31' fill='white' transform='translate(6.9231 10)' />
        </clipPath>
      </defs>
    </svg>
    <div className={styles.info}>
      <Typography.Text light={false}>{label}</Typography.Text>
      <div className={styles.stars}>
        <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M15.6477 5.81818H10.422L8.78117 0.475636C8.68804 0.191273 8.42565 0 8.13 0C7.83435 0 7.57195 0.191273 7.47882 0.475636L5.77143 5.81818H0.64039C-0.10761 5.81818 -0.10761 6.54545 0.173999 6.82327L4.373 9.91709L2.673 15.0844C2.57913 15.3702 2.67743 15.6844 2.91617 15.864C3.15639 16.0429 3.48161 16.0458 3.7233 15.8705L8.13 12.6815L12.5367 15.8705C12.6557 15.9564 12.7961 16 12.9351 16C13.0785 16 13.2226 15.9549 13.3438 15.864C13.5826 15.6844 13.6809 15.3702 13.587 15.0844L11.887 9.91709L16.0853 6.82327C16.3683 6.54545 16.3683 5.81818 15.6477 5.81818Z'
            fill='#F98135'
          />
        </svg>
        <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M15.6477 5.81818H10.422L8.78117 0.475636C8.68804 0.191273 8.42565 0 8.13 0C7.83435 0 7.57195 0.191273 7.47882 0.475636L5.77143 5.81818H0.64039C-0.10761 5.81818 -0.10761 6.54545 0.173999 6.82327L4.373 9.91709L2.673 15.0844C2.57913 15.3702 2.67743 15.6844 2.91617 15.864C3.15639 16.0429 3.48161 16.0458 3.7233 15.8705L8.13 12.6815L12.5367 15.8705C12.6557 15.9564 12.7961 16 12.9351 16C13.0785 16 13.2226 15.9549 13.3438 15.864C13.5826 15.6844 13.6809 15.3702 13.587 15.0844L11.887 9.91709L16.0853 6.82327C16.3683 6.54545 16.3683 5.81818 15.6477 5.81818Z'
            fill='#F98135'
          />
        </svg>
        <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M15.6477 5.81818H10.422L8.78117 0.475636C8.68804 0.191273 8.42565 0 8.13 0C7.83435 0 7.57195 0.191273 7.47882 0.475636L5.77143 5.81818H0.64039C-0.10761 5.81818 -0.10761 6.54545 0.173999 6.82327L4.373 9.91709L2.673 15.0844C2.57913 15.3702 2.67743 15.6844 2.91617 15.864C3.15639 16.0429 3.48161 16.0458 3.7233 15.8705L8.13 12.6815L12.5367 15.8705C12.6557 15.9564 12.7961 16 12.9351 16C13.0785 16 13.2226 15.9549 13.3438 15.864C13.5826 15.6844 13.6809 15.3702 13.587 15.0844L11.887 9.91709L16.0853 6.82327C16.3683 6.54545 16.3683 5.81818 15.6477 5.81818Z'
            fill='#F98135'
          />
        </svg>
        <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M15.6477 5.81818H10.422L8.78117 0.475636C8.68804 0.191273 8.42565 0 8.13 0C7.83435 0 7.57195 0.191273 7.47882 0.475636L5.77143 5.81818H0.64039C-0.10761 5.81818 -0.10761 6.54545 0.173999 6.82327L4.373 9.91709L2.673 15.0844C2.57913 15.3702 2.67743 15.6844 2.91617 15.864C3.15639 16.0429 3.48161 16.0458 3.7233 15.8705L8.13 12.6815L12.5367 15.8705C12.6557 15.9564 12.7961 16 12.9351 16C13.0785 16 13.2226 15.9549 13.3438 15.864C13.5826 15.6844 13.6809 15.3702 13.587 15.0844L11.887 9.91709L16.0853 6.82327C16.3683 6.54545 16.3683 5.81818 15.6477 5.81818Z'
            fill='#F98135'
          />
        </svg>
        <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M15.6477 5.81818H10.422L8.78117 0.475636C8.68804 0.191273 8.42565 0 8.13 0C7.83435 0 7.57195 0.191273 7.47882 0.475636L5.77143 5.81818H0.64039C-0.10761 5.81818 -0.10761 6.54545 0.173999 6.82327L4.373 9.91709L2.673 15.0844C2.57913 15.3702 2.67743 15.6844 2.91617 15.864C3.15639 16.0429 3.48161 16.0458 3.7233 15.8705L8.13 12.6815L12.5367 15.8705C12.6557 15.9564 12.7961 16 12.9351 16C13.0785 16 13.2226 15.9549 13.3438 15.864C13.5826 15.6844 13.6809 15.3702 13.587 15.0844L11.887 9.91709L16.0853 6.82327C16.3683 6.54545 16.3683 5.81818 15.6477 5.81818Z'
            fill='#F98135'
          />
        </svg>
      </div>
    </div>
  </div>
);

export default AppDetails;
