import cx from "classnames";
import React, {
  HTMLAttributes,
  ReactElement,
  useReducer,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFirebase } from "../../../config/context";
import { useApp } from "../../../config/context/app-contex";
import { useGetGroup } from "../../../hooks/useGroups";
import { ActionName } from "../../../lib/types/enums";
import { IMessageAction } from "../../../lib/types/messages";
import CopyManager from "../../../utils/CopyManager";
import Slider from "../../generic/molecules/Slider";
import LightRoom from "../../generic/organisms/LightRoom";
import GroupChat from "./Chats/GroupChat";
import SingleChat from "./Chats/SingleChat";
import GetHelp from "./GetHelp";
import Header from "./Header";
import Payments, { Data as PaymentData } from "./Payments/payments";
import Form from "./forms";
import Settings from "./group-settings";
import styles from "./index.module.css";

export interface Props extends HTMLAttributes<HTMLDivElement> {}

enum View {
  Chat,
  TravellersForm,
  AwardForm,
  Settings,
  Payment,
}

const reducer = (
  state: { current: View; prev: View },
  action: {
    payload: View;
    payment?: PaymentData | null;
    message?: string | null;
  }
) => {
  const { payload, payment, message } = action;
  return {
    ...state,
    ...{ current: payload, prev: state.current, payment, message },
  };
};

const ChatPage = ({ className, children, ...rest }: Props): ReactElement => {
  const [view, dispatch] = useReducer(reducer, {
    current: View.Chat,
    prev: View.Chat,
    payment: null,
    message: null,
  });
  const [lightroom, setLightroom] = useState<string[] | null>(null);
  const navigate = useNavigate();
  const firebase = useFirebase();
  const app = useApp();
  const params = useParams();
  const chatId = params.id;
  const { group } = useGetGroup(chatId);
  const [showGetHelp, setShowGetHelp] = useState(false);

  const onActionClick = (action: IMessageAction, messageId: string) => {
    const groupDetails = {
      groupId: chatId,
      groupUserId: group?.groupUserID,
      messageId,
    };

    if (ActionName.TravellersAndUser === action.name) {
      dispatch({
        payload: View.TravellersForm,
        payment: null,
        message: messageId,
      });
    }
    if (ActionName.AwardForm === action.name) {
      dispatch({ payload: View.AwardForm, payment: null, message: messageId });
    }
    if (ActionName.CheckoutBooking === action.name) {
      dispatch({
        payload: View.Payment,
        payment: {
          ...{ id: action.checkoutID, type: "checkouts" },
          ...groupDetails,
        },
      });
    }
    if (ActionName.Payment === action.name) {
      dispatch({
        payload: View.Payment,
        payment: {
          ...{ id: action.paymentID, type: "payments" },
          ...groupDetails,
        },
      });
    }
    if (ActionName.PaymentBooking === action.name) {
      dispatch({
        payload: View.Payment,
        payment: {
          ...{ id: action.bookingID, type: "bookings" },
          ...groupDetails,
        },
      });
    }
    if (ActionName.GroupInvite === action.name && action.groupID) {
      navigate(`/chat/${action.groupID}`);
    }
  };

  const groupMenuLinks = () => (
    <button type="button" onClick={() => dispatch({ payload: View.Settings })}>
      {CopyManager.get("group_menu_link_title")}
    </button>
  );

  const privateMenuLinks = () => (
    <button
      type="button"
      onClick={() => dispatch({ payload: View.AwardForm, payment: null })}
    >
      {CopyManager.get("new_booking_request")}
    </button>
  );

  const prioritizeUser = () => {
    const uid = firebase.getCurrentUser()?.uid;
    if (uid) {
      firebase.prioritizeUser(uid).finally(() => setShowGetHelp(false));
    }
  };

  return (
    <>
      {lightroom && (
        <LightRoom images={lightroom} onClose={() => setLightroom(null)} />
      )}
      {!showGetHelp ? (
        <Slider currentIndex={View.Chat === view.current ? 0 : 1}>
          <div className={styles.view} key="chat">
            {app.userLoggedIn && (
              <>
                {chatId ? (
                  <>
                    <Header
                      onBack={() => navigate("/dashboard")}
                      name={group?.name}
                      navLinks={groupMenuLinks()}
                    />
                    <GroupChat
                      groupId={chatId}
                      group={group}
                      userId={firebase.getCurrentUser()?.uid ?? ""}
                      onActionClick={onActionClick}
                      onImageClick={(images) => setLightroom(images)}
                      onGetHelpClick={() => setShowGetHelp(true)}
                    />
                  </>
                ) : (
                  <>
                    <Header
                      onBack={() => navigate("/dashboard")}
                      name={group?.name}
                      navLinks={privateMenuLinks()}
                    />
                    <SingleChat
                      userId={firebase.getCurrentUser()?.uid ?? ""}
                      onActionClick={onActionClick}
                      onImageClick={(images) => setLightroom(images)}
                      onGetHelpClick={() => setShowGetHelp(true)}
                    />
                  </>
                )}
              </>
            )}
          </div>
          <div className={cx(styles.view, styles.form)} key="settings">
            {(View.TravellersForm === view.current ||
              View.TravellersForm === view.prev) && (
              <Form.Travellers
                messageID={view.message}
                onAbort={() => dispatch({ payload: View.Chat })}
                chatId={chatId}
              />
            )}
            {(View.AwardForm === view.current ||
              View.AwardForm === view.prev) && (
              <Form.Inquery
                messageID={view.message}
                onAbort={() => dispatch({ payload: View.Chat })}
              />
            )}
            {(View.Settings === view.current ||
              View.Settings === view.prev) && (
              <Settings
                onAbort={() => dispatch({ payload: View.Chat })}
                chatId={chatId}
              />
            )}
            {(View.Payment === view.current || View.Payment === view.prev) &&
              view.payment && (
                <Payments
                  onAbort={() => dispatch({ payload: View.Chat })}
                  onComplete={() => dispatch({ payload: View.Chat })}
                  payment={view.payment}
                />
              )}
          </div>
        </Slider>
      ) : (
        <div className={styles.view}>
          <GetHelp
            onAbort={() => setShowGetHelp(false)}
            onConfirm={() => prioritizeUser()}
          />
        </div>
      )}
    </>
  );
};
export default ChatPage;
