import React, { useState } from "react";
import { withFirebaseHOC } from "../../../config/Firebase";
import { IFirebase } from "../../../config/Firebase/type";
import Loader from "../../generic/atoms/Loader";
import Logo from "../../generic/atoms/Logo";
import Slider from "../../generic/molecules/Slider";
import Form from "../../generic/templates/Login/Form";
import styles from "./index.module.css";

export interface ILoginProps {
  firebase: IFirebase;
}

const usePasscode = false;

export const LoginPage = (props: ILoginProps) => {
  const [error, setError] = useState("");
  const [slide, setSlide] = useState(0);

  const loginCredentials = (email: string, password: string) => {
    if (!usePasscode) {
      props.firebase
        .signInWithEmailAndPassword(email, password)
        .catch((error: firebase.FirebaseError) => {
          setError(error.message);
        });
    } else {
      setSlide(1);
      setTimeout(() => setSlide(2), 3000);
    }
  };

  return (
    <>
      <div className={styles.logo}>
        <Logo />
      </div>
      <Slider currentIndex={slide}>
        <Form
          onComplete={({ email, password }) =>
            loginCredentials(email, password)
          }
          error={error}
        />
        <div className={styles.loader}>
          <Loader />
        </div>
        <div className={styles.loader}>
          <Loader />
        </div>
      </Slider>
    </>
  );
};

export default withFirebaseHOC(LoginPage);
