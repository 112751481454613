import { useEffect, useState } from "react";
import { useFirebase } from "../config/context";
import { DeletionRequest } from "../lib/types";

export const useDeletionRequests = () => {
  const firebase = useFirebase();
  const userID = firebase.getCurrentUser()?.uid;
  const [requestedDeletion, setRequestedDeletion] = useState<boolean | null>(null)

  useEffect(() => {
    if (userID) {
      firebase
        .getUserDeletionRequest(userID)
        .onSnapshot((snap: any) => {
          const request = snap.data() as DeletionRequest
          if (request) {
            setRequestedDeletion(true)
          } else {
            setRequestedDeletion(false)
          }
        })
    }
  }, [userID]);

  return { requestedDeletion };
};
