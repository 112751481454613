import React, { HTMLAttributes, ReactElement } from "react";
import styles from "./index.module.css";

import cx from "classnames";
export interface Props extends HTMLAttributes<HTMLHRElement> {
  className?: string;
}

export const Divider = ({ className = "", ...rest }: Props): ReactElement => (
  <hr className={cx(styles.hr, className)} {...rest} />
);

export default Divider;
