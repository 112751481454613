import React, { ReactElement, ReactNode } from "react";
import styles from "./index.module.css";

interface Props {
  index: number;
  children?: ReactNode;
  onActionClick?: () => void;
}

const QueueItem = ({ children, index, onActionClick }: Props): ReactElement => {
  return (
    <span className={styles.queueItem}>
      Din plats i kön är {index}.{" "}
      <button type='button' className={styles.button} onClick={onActionClick}>
        När får jag hjälp?
      </button>
    </span>
  );
};

export default QueueItem;
