import { useEffect, useState } from "react";
import { useFirebase } from "../config/context";
import { useApp } from "../config/context/app-contex";
import useInterval from "./useInterval";


interface ChatflightsSession {
  timestamp: number;
  pin: number;
  verified: boolean;
}

export const useChatfligtsSession = () => {
  const app = useApp();
  const firebase = useFirebase();
  const [session, setSession] = useState<ChatflightsSession | null>(null);
  const [sessionValid, setSessionValid] = useState<boolean>(true);
  const userID = firebase.getCurrentUser()?.uid;

  const generatatePin = (userID: string) => {
    const pin = Math.floor(1000 + Math.random() * 9000);
    firebase.updatedSession(userID, pin, false);
    return firebase.sendChatflightsMessage(userID, pin.toString());
  };

  const validatePin = (userPin: number) => {
    if (userID && session && session.pin === userPin) {
      firebase.updatedSession(userID, userPin, true);
      setSessionValid(true);
      return true;
    }
    return false;
  };

  const isSessionValid = () => {
    const fiveDaysAgo = new Date();
    fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 5);
    if (session === null) return false;
    if (session.timestamp < fiveDaysAgo.getTime()) return false;
    if (!session.verified) return false;
    return true;
  };

  useEffect(() => {
    if (userID) {
      const ref = firebase.getSession(userID);
      const listener = ref.on("value", (snapshot) => {
        setSession(snapshot.val() as ChatflightsSession);
      });
      return () => {
        ref.off("value", listener);
      };
    }
  }, [userID]);

  const checkSession = () => {
    if (!isSessionValid() && app.userLoggedIn) {
      setSessionValid(false);
    }
  };
  // initially wait 10 minutes, then loop checksession every minute
  useInterval(checkSession, 60000 * 10, 60000)
  return { sessionValid, generatatePin, validatePin };
};
