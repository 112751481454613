import React, { createContext, ReactElement, ReactNode } from "react";

export type ValueType = string | ReadonlyArray<string> | number | undefined;

interface ICheckboxGroupContext {
  buttonStyle?: "button" | "label";
}
export interface Props {
  buttonStyle?: "button" | "label";
  children?: ReactNode;
  align?: "left" | "right" | "center";
  singleLine?: boolean;
  name?: string;
}

const CheckboxGroupContext = createContext<ICheckboxGroupContext | undefined>(undefined);

function CheckboxGroupProvider({ children, buttonStyle }: Props): ReactElement {
  return (
    <CheckboxGroupContext.Provider
      value={{
        buttonStyle: buttonStyle ?? "label",
      }}>
      {children}
    </CheckboxGroupContext.Provider>
  );
}

export default CheckboxGroupContext;
export { CheckboxGroupProvider };
