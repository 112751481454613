import React, { createContext, ReactElement, ReactNode, useContext, useEffect, useState } from "react";
import { useFirebase } from ".";

export type IAppContext = {
  openModal: (children: ReactNode) => void;
  closeModal: () => void;

  openToast: (children: ReactNode, autoClose?: boolean) => void;
  closeToast: () => void;

  userLoggedIn: boolean | null;
};

export interface Props {
  children: ReactNode;
}
const AppContext = createContext<IAppContext | null>(null);

const AppProvider = ({ children }: Props): ReactElement => {
  const firebase = useFirebase();
  const [modalContent, setModalContent] = useState<ReactNode | null>();
  const [toastContent, setToastContent] = useState<ReactNode | null>();
  const [userLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);

  useEffect(() => {
    const unsubscribe = firebase.onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
        console.log("Logged in", new Date().toLocaleString());
      } else {
        setIsLoggedIn(false);
        console.log("Logged out");
      }
    });

    return unsubscribe;
  }, []);

  return (
    <AppContext.Provider
      value={{
        openModal: setModalContent,
        closeModal: () => setModalContent(null),
        openToast: (children, autoClose = false) => {
          setToastContent(children);
          if (autoClose) setTimeout(() => setToastContent(null), 5000);
        },
        closeToast: () => setToastContent(null),
        userLoggedIn,
      }}>
      {children}
      {modalContent}
      {toastContent}
    </AppContext.Provider>
  );
};

export const useApp = (): IAppContext => {
  return useContext(AppContext) as IAppContext;
};

export default AppContext;
export { AppProvider };
