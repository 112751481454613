export type Card = {
  number: string;
  name: string;
  expire_date: Date;
  cvv: number;
};

export type GroupInvite = {
  name: string;
  phoneNumber: string;
};

export type ResponseCard = {
  id: string;
  card: {
    brand: string;
    country: string;
    exp_month: number;
    exp_year: number;
    last4: string;
  };
  billing_details: {
    name: string;
  };
};

export enum IntentStatus {
  RequiresPaymentMethod = "requires_payment_method",
  RequiresConfirmation = "requires_confirmation",
  RequiresAction = "requires_action",
  Processing = "processing",
  Canceled = "canceled",
  Succeeded = "succeeded",
}

export interface PaymentIntentResponse {
  id: string;
  object: "payment_intent";
  amount: number;
  capture_method: "automatic";
  client_secret: string;
  confirmation_method: "automatic";
  created: 1524505326;
  currency: string;
  customer: null;
  description: string;
  payment_method: { id: string };
  payment_method_types: string[];
  status: IntentStatus;
  next_action: {
    redirect_to_url: { url: string };
  };
  return_url: string;
  last_payment_error: ErrorResponse;
}

export interface CardIntentResponse {
  id: string;
  object: string;
  client_secret: string;
  created: number;
  customer: string;
  latest_attempt: string;
  livemode: string;
  message: string;
  metadata: {};
  next_action?: {
    redirect_to_url: {
      return_url: string;
      url: string;
    };
  } | null;
  payment_method: { id: string };
  payment_method_options: {
    card: {
      request_three_d_secure: string;
    };
  };
  payment_method_types: string[];
  status: IntentStatus;
  usage: "off_session" | "on_session";
  last_setup_error: ErrorResponse;
}

export type ErrorResponse = {
  message: string;
  type: "api_error" | "card_error" | "idempotency_error" | "invalid_request_error";
  code:
    | "setup_intent_authentication_failure"
    | "authentication_required"
    | "card_declined"
    | "subscription_payment_intent_requires_action"
    | "expired_card";
  payment_method: { id: string };
};
