import React, { useState } from "react";
import { useApp } from "../../../../config/context/app-contex";
import { Response, useInvoice } from "../../../../hooks/useInvoice";
import { PaymentResponse, usePayment } from "../../../../hooks/usePayment";
import CopyManager from "../../../../utils/CopyManager";
import Payment from "../../../generic/organisms/Payment";
import Toast from "../../../generic/organisms/Toast";
import { AddNewCreditCard } from "../dialogs";
import { Props } from "../types";
import CardAuth from "./card-auth";
import NewCompanyPayment from "./new-company-payment";

export type Data = {
  type: "payments" | "bookings" | "checkouts";
  id: string;
  groupId?: string;
  groupUserId?: string;
  messageId: string;
};

export interface PaymentsProps extends Props {
  payment?: Data;
}

const Payments = ({
  onAbort,
  onComplete,
  payment: paymentData,
}: PaymentsProps) => {
  const app = useApp();
  const payment = usePayment();
  const invoice = useInvoice();

  const [cardAuthUrl, setCardAuthUrl] = useState<string | null>(null);

  const showToastError = (error?: string) => {
    app.openToast(
      <Toast onClose={app.closeToast}>
        {CopyManager.get("something_wrong")}
      </Toast>
    );
  };

  const invoicePayment = {
    paymentID: paymentData?.type === "payments" ? paymentData.id : undefined,
    bookingID: paymentData?.type === "bookings" ? paymentData.id : undefined,
    checkoutID: paymentData?.type === "checkouts" ? paymentData.id : undefined,
  };

  const onAddNewCard = () => {
    app.openModal(
      <AddNewCreditCard
        onClose={app.closeModal}
        onConfirm={async (values, showError, isLoading) => {
          if (values) {
            const response = await payment.addCard({
              ...values,
              ...{ number: values.number.toString() },
            });
            if (showError) showError();
            if (response?.ok) app.closeModal();
            else {
              if (isLoading) isLoading(false);
              app.openToast(
                <Toast onClose={app.closeToast}>{response?.error}</Toast>
              );
            }
          }
        }}
      />
    );
  };

  const onAddNewCompany = () => {
    app.openModal(
      <NewCompanyPayment
        onComplete={(companyId) => {
          onPayWithInvoice(companyId);
        }}
        payment={invoicePayment}
      />
    );
  };

  const onRemoveCard = async (id: string) => {
    await payment.removeCard(id);
  };

  const onCreatePayment = async (paymentID: string) => {
    if (!paymentData) return;
    let response: PaymentResponse | null = null;

    const { id, type, groupId, groupUserId, messageId } = paymentData;

    if (paymentData.groupId) {
      if (groupUserId && groupId)
        response = await payment.createGroupPayment(
          paymentID,
          id,
          type,
          groupId,
          groupUserId,
          messageId
        );
    } else {
      response = await payment.createPayment(paymentID, id, type, messageId);
    }

    if (!response?.ok) {
      showToastError();
    } else if (response) {
      if (response.nextAction?.url) {
        setCardAuthUrl(response.nextAction?.url);
      } else if (onComplete) {
        onComplete();
      }
    }
  };

  const onValidate3DSecurePayment = async () => {
    if (!paymentData) return;

    let response: PaymentResponse | null = null;
    if (paymentData.groupUserId) {
      response = await payment.getGroupPayment(
        paymentData.groupUserId,
        paymentData.id,
        paymentData.type
      );
    } else {
      response = await payment.getPayment(
        paymentData.groupUserId ?? paymentData.id,
        paymentData.type
      );
    }

    if (!response?.ok) {
      showToastError();
    } else if (onComplete) {
      onComplete();
    }
  };

  const onPayWithInvoice = async (companyId: string) => {
    if (!paymentData) return;
    const { groupId, groupUserId, messageId } = paymentData;

    let response: Response | null = null;
    if (paymentData.groupId) {
      if (groupUserId && groupId)
        response = await invoice.createGroupInvoice(
          groupId,
          groupUserId,
          invoicePayment,
          companyId,
          messageId
        );
    } else {
      response = await invoice.createInvoice(invoicePayment, companyId);
    }

    if (!response?.ok) {
      showToastError();
    } else if (response && onComplete) {
      app.closeModal();
      onComplete();
    }
  };

  return (
    <>
      {!cardAuthUrl ? (
        <Payment
          cards={payment.cards.map((item) => ({
            ...item.card,
            ...{
              number: `**** **** **** ${item.card.last4}`,
              id: item.id,
              name: item.billing_details.name,
            },
          }))}
          companys={invoice.companys}
          onAbort={onAbort}
          onAddCard={onAddNewCard}
          onAddCompany={onAddNewCompany}
          onRemoveCompany={(id) => invoice.deleteCompany(id)}
          onRemoveCard={onRemoveCard}
          onPayWithCard={(card) => onCreatePayment(card.id)}
          onPayWithCompany={(company) => onPayWithInvoice(company.id)}
          loading={payment.fetching || invoice.fetching}
        />
      ) : (
        <CardAuth url={cardAuthUrl} onComplete={onValidate3DSecurePayment} />
      )}
    </>
  );
};
export default Payments;
