import cx from "classnames";
import React, { HTMLAttributes, ReactElement } from "react";
import styles from "./index.module.css";

export interface Props extends HTMLAttributes<HTMLDivElement> {}

const Label = ({ className, children, ...rest }: Props): ReactElement => {
  return <span className={cx(styles.label, className)}>{children}</span>;
};

export default Label;
