import React from "react";
import CopyManager from "../../utils/CopyManager";

type CardLayoutProps = {
  leftColSubheading: string;
  leftColHeading: string;
  flightNumber?: string;
  rightColSubheading?: string;
  rightColHeading: string;
  departureDate: string;
  reference: string;
  departureDateSubText: string;
  openActionSheet: Function;
  expanded: boolean;
  imageUrl: string;
};

export const CardLayout = (props: CardLayoutProps) => (
  <div className="grid">
    <div className="grid-row route">
      {props.leftColSubheading && (
        <div className="left-col">{props.leftColSubheading}</div>
      )}
      {props.flightNumber && (
        <div className="center-col">{props.flightNumber}</div>
      )}
      {props.rightColSubheading && (
        <div className="right-col">{props.rightColSubheading}</div>
      )}
    </div>
    <div className="grid-row routeCode">
      <div className="left-col">
        <div className="airport">{props.leftColHeading}</div>
      </div>
      <div className="center-col">
        <img className={"flight-icon"} src={props.imageUrl} alt="" />
      </div>
      <div className="right-col">
        <div className="airport">{props.rightColHeading}</div>
      </div>
    </div>
    <div className="grid-row reference">
      <div className="left-col">
        <div className="div">{props.departureDate}</div>
        <div className="bold-div">{props.departureDateSubText}</div>
      </div>
      <div className="center-col center-col-row2">
        <div className="div">{CopyManager.get("booking_reference_title")}</div>
        <div className="bold-div">{props.reference}</div>
      </div>
      <div className="right-col right-col-row2">
        <div onClick={() => props.openActionSheet()}>
          <div className="action-button">
            <div className="dot" />
            <div className="dot" />
            <div className="dot" />
          </div>
        </div>
      </div>
    </div>
  </div>
);
