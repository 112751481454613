import React, { createContext, ReactElement, ReactNode } from "react";

export type ValueType = string | ReadonlyArray<string> | number | undefined;
export type Style = "solid" | "button";

interface IRadioGroupContext {
  name: string;
  buttonStyle?: Style;
  singleLine?: boolean;
}
export interface Props {
  buttonStyle?: Style;
  name?: string;
  children?: ReactNode;
  align?: "left" | "right" | "center";
  singleLine?: boolean;
}

const RadioGroupContext = createContext<IRadioGroupContext | undefined>(undefined);

function RadioGroupProvider({ children, name = "", buttonStyle }: Props): ReactElement {
  return (
    <RadioGroupContext.Provider
      value={{
        name,
        buttonStyle: buttonStyle ?? "button",
        singleLine: false,
      }}>
      {children}
    </RadioGroupContext.Provider>
  );
}

export default RadioGroupContext;
export { RadioGroupProvider };
