import { useEffect, useState } from "react";

import {
  confirmPaymentIntent,
  confirmSetupIntent,
  createGroupPaymentIntent,
  createPaymentIntent,
  detachPaymentMethod,
  getCardToken,
  getCards,
  retrievePaymentIntent,
  setupIntent,
} from "../api/api";
import {
  Card,
  CardIntentResponse,
  ErrorResponse,
  IntentStatus,
  PaymentIntentResponse,
  ResponseCard,
} from "../api/types";
import { useFirebase } from "../config/context";

export type PaymentResponse = { ok: boolean; error?: string; nextAction?: { url: string | undefined } };

export const errorResponse = (errorResponse: ErrorResponse, userId?: string) => {
  const message = errorResponse && errorResponse.message ? errorResponse.message : "Something went wrong!";

  if (errorResponse && errorResponse.type) {
    switch (errorResponse.code) {
      case "setup_intent_authentication_failure":
      case "authentication_required":
      case "card_declined":
      case "subscription_payment_intent_requires_action":
        // case "expired_card":
        //   if (errorResponse.payment_method && errorResponse.payment_method.id) {
        //     detachPaymentMethod(errorResponse.payment_method.id, userId);
        //   } else if (userId) {
        //     detachPaymentMethod(undefined, userId);
        //   }
        //   break;
        return message;
    }
  }
  return message;
};

const responseParser = (
  response: CardIntentResponse | PaymentIntentResponse | null
): CardIntentResponse | PaymentIntentResponse | null => {
  switch (response?.status) {
    case IntentStatus.Canceled:
    case IntentStatus.RequiresPaymentMethod:
      if (response.hasOwnProperty("last_payment_error"))
        throw new Error(errorResponse((response as PaymentIntentResponse).last_payment_error ?? "", ""));
      if (response.hasOwnProperty("last_setup_error"))
        throw new Error(errorResponse((response as CardIntentResponse).last_setup_error ?? "", ""));
      return response;
    default:
      return response;
  }
};

export const usePayment = () => {
  const firebase = useFirebase();
  const [cards, setCards] = useState<ResponseCard[]>([]);
  const [fetching, isFetching] = useState(false);

  const updateCards = async () => {
    const response = await getCards(firebase.getCurrentUser()?.uid ?? "");
    if (response) setCards(response);
    return true;
  };

  const addCard = async (card: Card): Promise<PaymentResponse> => {
    isFetching(true);

    const response = await getCardToken(card, "sk_live_z3OXzq3UntmcrZeixmxAP2oa002iKQp02r")
      .then((token) => {
        if (token?.error) {
          throw new Error(token.error.message); // this is an error from stripe
        }
        if (token == null) {
          throw new Error("Something unexpected happend");
        }
        return token
      })
      .then(async (data) => {
        let res = await setupIntent(firebase.getCurrentUser()?.uid ?? "", data?.id ?? "")
        if (res?.message) {
          throw new Error(res.message)
        }
        return res
      })
      .then((data) => responseParser(data))
      .then(async (data) =>
        data?.status === IntentStatus.RequiresConfirmation
          ? await confirmSetupIntent(firebase.getCurrentUser()?.uid ?? "", data.id, data.payment_method.id)
          : data
      )
      .then((data) => responseParser(data))
      .then(async (data) => {
        await updateCards();
        return data;
      })
      .then((data) => ({ ok: data?.status === IntentStatus.Succeeded }))
      .catch((e) => ({ ok: false, error: e.message ?? "" }));

    isFetching(false);
    return response;
  };

  const removeCard = async (paymentId: string): Promise<PaymentResponse> => {
    isFetching(true);

    const response = await detachPaymentMethod(firebase.getCurrentUser()?.uid ?? "", paymentId)
      .then(async (data) => {
        await updateCards();
        return data;
      })
      .then(async (data) => ({ ok: true }))
      .catch((e) => ({ ok: false, error: e.message ?? "" }));

    isFetching(false);

    return response;
  };

  const createPayment = async (
    paymentId: string,
    bookingId: string,
    type: string,
    messageId: string
  ): Promise<PaymentResponse> => {
    isFetching(true);

    const response: PaymentResponse = await createPaymentIntent(
      firebase.getCurrentUser()?.uid ?? "",
      bookingId,
      type,
      paymentId,
      messageId
    )
      .then(async (data) => await responseParser(data))
      .then(async (data) => {
        if (data?.status === IntentStatus.RequiresConfirmation)
          return await confirmPaymentIntent(firebase.getCurrentUser()?.uid ?? "", data.id, paymentId);
        return data;
      })
      .then(async (data) => await responseParser(data))
      .then(async (data) => ({
        ok: !!(data?.status === IntentStatus.Succeeded || data?.status === IntentStatus.RequiresAction),
        nextAction: { url: data?.next_action?.redirect_to_url.url },
      }))
      .catch((e) => ({ ok: false, error: e.message ?? "" }));

    isFetching(false);

    return response;
  };

  const createGroupPayment = async (
    paymentId: string,
    bookingId: string,
    type: string,
    groupId: string,
    groupUserId: string,
    messageId: string
  ): Promise<PaymentResponse> => {
    isFetching(true);
    console.log("Group Payment");
    const response: PaymentResponse = await createGroupPaymentIntent(
      firebase.getCurrentUser()?.uid ?? "",
      type,
      bookingId,
      paymentId,
      groupId,
      groupUserId,
      messageId
    )
      .then(async (data) => await responseParser(data))
      .then(async (data) => {
        if (data?.status === IntentStatus.RequiresConfirmation)
          return await confirmPaymentIntent(firebase.getCurrentUser()?.uid ?? "", data.id, paymentId);
        return data;
      })
      .then(async (data) => await responseParser(data))
      .then(async (data) => ({
        ok: !!(data?.status === IntentStatus.Succeeded || data?.status === IntentStatus.RequiresAction),
        nextAction: { url: data?.next_action?.redirect_to_url.url },
      }))
      .catch((e) => ({ ok: false, error: e.message ?? "" }));

    isFetching(false);

    return response;
  };

  const getPayment = async (paymentId: string, type: string): Promise<PaymentResponse> => {
    isFetching(true);

    const response = await retrievePaymentIntent(firebase.getCurrentUser()?.uid ?? "", type, paymentId)
      .then(async (data) => await responseParser(data))
      .then(async (data) => ({ ok: data?.status === IntentStatus.Succeeded }))
      .catch((e) => ({ ok: false, error: e.message ?? "" }));

    isFetching(false);

    return response;
  };

  const getGroupPayment = async (groupUserId: string, paymentId: string, type: string): Promise<PaymentResponse> => {
    isFetching(true);

    const response = await retrievePaymentIntent(groupUserId, type, paymentId)
      .then(async (data) => await responseParser(data))
      .then(async (data) => ({ ok: data?.status === IntentStatus.Succeeded }))
      .catch((e) => ({ ok: false, error: e.message ?? "" }));

    isFetching(false);

    return response;
  };

  useEffect(() => {
    updateCards();
  }, [firebase.getCurrentUser()?.uid]);

  return {
    addCard,
    removeCard,
    getCards,
    createPayment,
    createGroupPayment,
    getPayment,
    getGroupPayment,
    cards,
    fetching,
  };
};
