import firebaseConfig from "../config/Firebase/firebaseConfig";
import { GroupInvite } from "./types";

const baseOptions = {
  "Content-Type": "application/json",
};

const api_base_url = `https://admin-dot-${firebaseConfig.projectId}.appspot.com`;

export async function createGroup(userID: string, groupName: string, invites: GroupInvite[]) {
  try {
    return await fetch(`${api_base_url}/groups`, {
      ...baseOptions,
      ...{ method: "POST", body: JSON.stringify({ userID, groupName, invites }) },
    });
  } catch (error) {
    return null;
  }
}

export async function inviteToGroup(groupID: string, invite: GroupInvite) {
  try {
    const { name, phoneNumber } = invite;
    return await fetch(`${api_base_url}/groups/${groupID}/invites`, {
      ...baseOptions,
      ...{
        method: "POST",
        body: JSON.stringify({
          invites: [{ name, phoneNumber }],
        }),
      },
    });
  } catch (error) {
    console.log("failed to invite to group for user", groupID, error);
    return null;
  }
}

export async function removeFromGroup(groupID: string, userId: string) {
  try {
    return await fetch(`${api_base_url}/groups/${groupID}/members/${userId}`, {
      ...baseOptions,
      ...{
        method: "DELETE",
      },
    });
  } catch (error) {
    console.log("failed to remove from group", groupID, error);
    return null;
  }
}
