import cx from "classnames";
import React, {
  ChangeEvent,
  InputHTMLAttributes,
  ReactElement,
  ReactNode,
  forwardRef,
  useContext,
  useState,
} from "react";
import CheckboxGroupContext from "../context";
import styles from "./index.module.css";

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  icon?: ReactNode;
}

const Checkbox = forwardRef<HTMLInputElement, Props>((props, ref): ReactElement => {
  const { className, children, name, icon, ...rest } = props;
  const checkboxgroupContext = useContext(CheckboxGroupContext);
  const [isChecked, setIsChecked] = useState(rest.checked || rest.defaultChecked);

  const checked = (event: ChangeEvent<HTMLInputElement>) => setIsChecked(event.target.checked);

  return (
    <div className={cx(styles.checkbox, styles[checkboxgroupContext?.buttonStyle ?? ""], className)}>
      <label className={cx(styles.label, { [styles.checked]: isChecked })}>
        <input
          ref={ref}
          className={styles.element}
          name={name}
          {...rest}
          type='checkbox'
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            checked(event);
            if (rest.onChange) rest.onChange(event);
          }}
        />
        <span className={styles.mark} />
        <span className={styles.children}>
          {icon && <span className={styles.icon}>{icon}</span>}
          {children && <span>{children}</span>}
        </span>
      </label>
    </div>
  );
});

export default Checkbox;
