import { useEffect, useState } from "react";
import { createGroup as createGroupReq, inviteToGroup, removeFromGroup as removeFromGroupReq } from "../api/api-groups";
import { GroupInvite } from "../api/types";
import { useFirebase } from "../config/context";
import { useApp } from "../config/context/app-contex";
import { GroupDetails, Invite } from "../lib/types/group";

export type Response = { ok: boolean; error?: string };

export const useGroups = () => {
  const firebase = useFirebase();
  const app = useApp();
  const [groups, setGroups] = useState<GroupDetails[]>([]);
  const [fetching, isFetching] = useState<boolean>(true);

  const createGroup = async (groupName: string, invites: GroupInvite[]): Promise<Response | null> => {
    isFetching(true);
    const respone = await createGroupReq(firebase.getCurrentUser()?.uid ?? "", groupName, invites)
      .then((data) => ({
        ok: !!data,
      }))
      .catch(() => ({ ok: false }));
    isFetching(false);
    return respone;
  };

  useEffect(() => {
    const uid = firebase.getCurrentUser()?.uid;
    if (uid) {
      const unsubscribe = firebase.getGroups(uid).onSnapshot(
        (snapshot) => {
          let groups: GroupDetails[] = [];
          snapshot.forEach((doc) => {
            const data = doc.data() as GroupDetails;
            data.id = doc.id;
            groups.push(data);
          });
          setGroups(groups);
          isFetching(false);
        },
        (error) => {
          console.log(error);
          isFetching(false);
        }
      );
      return unsubscribe;
    }
  }, [app.userLoggedIn]);

  return { createGroup, groups, fetching };
};

export const useGetGroup = (groupId?: string | null) => {
  const [group, setGroup] = useState<GroupDetails | null>(null);
  const [invites, setInvites] = useState<Invite[]>([])
  const [fetching, isFetching] = useState(false);
  const firebase = useFirebase();

  const removeFromGroup = async (groupId: string, userId: string) => {
    isFetching(true);
    const respone = await removeFromGroupReq(groupId, userId)
      .then((data) => ({ ok: true }))
      .then((data) => {
        updateGroup();
        return data;
      })
      .catch(() => ({ ok: false }));
    isFetching(false);
    return respone;
  };

  const addToGroup = async (groupId: string, invite: GroupInvite): Promise<Response> => {
    isFetching(true);
    const respone = await inviteToGroup(groupId, invite)
      .then((data) => {
        updateGroup();
        return data;
      })
      .then((data) => ({ ok: true }))
      .catch((e) => ({ ok: false }));
    isFetching(false);
    return respone;
  };

  const leaveGroup = async (groupId: string) => {
    isFetching(true);
    const respone = await removeFromGroupReq(groupId, firebase.getCurrentUser()?.uid ?? "")
      .then((data) => ({ ok: true }))
      .catch(() => ({ ok: false }));
    isFetching(false);
    return respone;
  };

  const updateGroup = () => {
    if (groupId) {
      firebase
        .getGroupDetails(groupId)
        .get()
        .then((groupDetails: any) => setGroup(groupDetails.data() as GroupDetails))
        .catch(() => []);
    }
  };

  const getInvites = () => {
    if (groupId) {
      const unsubscribe = firebase.getInvites(groupId).onSnapshot(
        (snapshot) => {
          const is: Invite[] = []
          snapshot.forEach((doc) => {
            const data = doc.data() as Invite;
            is.push(data);
          });
          setInvites(is);
        },
        (error) => {
          console.log(error);
        }
      );
      return unsubscribe;
    }
  }

  useEffect(() => {
    updateGroup();
    getInvites();
  }, [groupId]);

  return { group, invites, removeFromGroup, leaveGroup, addToGroup, fetching };
};

