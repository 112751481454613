export const getLocalDate = (date: Date, options?: Intl.DateTimeFormatOptions) => {
  return date.toLocaleString(
    Intl?.NumberFormat()?.resolvedOptions()?.locale ?? "sv-se",
    options ?? {
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }
  );
};
