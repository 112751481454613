import React, { ReactElement, ReactNode } from "react";
import styles from "./index.module.css";

interface Props {
  children?: ReactNode;
}

const LabelItem = ({ children }: Props): ReactElement => {
  return <span className={styles.labelItem}>{children}</span>;
};

export default LabelItem;
