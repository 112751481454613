import cx from "classnames";
import React, { HTMLAttributes, ReactElement, TransitionEvent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useFirebase } from "../../../config/context";
import { useApp } from "../../../config/context/app-contex";
import Bookings from "../../Bookings";
import { GroupDetails } from "../../ChatList";
import Panel from "../../generic/organisms/Panel";
import styles from "./index.module.css";

export type Settings = {
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  padding: "none" | "default";
  withPanel?: boolean;
  background?: string;
};

export interface Props extends HTMLAttributes<HTMLDivElement> {
  onStartTransition?: () => void;
  onEndTransition?: () => void;
  settings: Settings;
}

const Box = ({ className, children, settings, onEndTransition, ...rest }: Props): ReactElement => {
  const app = useApp();
  let firebase = useFirebase();
  let location = useLocation();


  const [groupID, setGroupID] = useState<string | null>(null)
  const [bookingUserID, setBookingsUserID] = useState<string | null>(null)

  const groupRoute = new RegExp('/chat/(.+)');

  useEffect(() => {
    const user = firebase.getCurrentUser()
    if (user) {
      setBookingsUserID(firebase.getCurrentUser()?.uid || "")
    }
    if (groupRoute.test(location.pathname)){
      let gID = groupRoute.exec(location.pathname)
      if (gID) {
        setGroupID(gID[1])
      }
    } else {
      setGroupID(null)
    }
  }, [location])

  useEffect(() => {
    if (groupID) {
      firebase.getGroupDetails(groupID)
        .get()
        .then((groupDetails: any) => {
          let grp = groupDetails.data() as GroupDetails
          setBookingsUserID(grp.groupUserID)
        })
    }
  }, [groupID])

  const defaults = {
    padding: "none",
    height: "100%",
    width: "100%",
    maxHeight: "100vh",
    maxWidth: "1280px",
    background: "#f1f2f3",
    withPanel: true,
  };
  const [boxStyle, setBoxStyle] = useState({
    ...defaults,
    ...settings,
  });

  useEffect(() => {
    const styles = {
      ...defaults,
      ...settings,
    };

    if (styles.maxWidth === boxStyle.maxWidth && onEndTransition) {
      onEndTransition();
    }

    setBoxStyle(styles);
  }, [settings]);

  const { height, width, maxHeight, maxWidth, withPanel, background } = boxStyle;

  return (
    <section className={styles.box} {...rest}>
      <div
        className={cx(styles.content, className)}
        style={{
          padding: boxStyle.padding === "default" ? "4.7rem 5.5rem" : "0rem",
          height,
          width,
          maxHeight,
          maxWidth,
          background,
        }}
        onTransitionEnd={(event: TransitionEvent<HTMLDivElement>) => {
          if (event.nativeEvent.propertyName === "max-width" && onEndTransition) {
            onEndTransition();
          }
        }}>
        <div className={cx(styles.container, { [styles.panel]: withPanel })}>
          <div className={styles.children}>{children}</div>

          <Panel>{app.userLoggedIn && <Bookings bookingsUserID={bookingUserID}/>}</Panel>
        </div>
      </div>
    </section>
  );
};
export default Box;
