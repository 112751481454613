import React, { ReactElement } from "react";
import styles from "./index.module.css";

const TypingIndicator = (): ReactElement => {
  return (
    <div className={styles.container}>
      <div className={styles.dot}></div>
      <div className={styles.dot} />
      <div className={styles.dot} />
    </div>
  );
};

export default TypingIndicator;
