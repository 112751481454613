import { zodResolver } from "@hookform/resolvers/zod";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import CopyManager from "../../../../../utils/CopyManager";
import Checkbox from "../../../atoms/Checkbox";
import Icon from "../../../atoms/Icon";
import Radio from "../../../atoms/Radio";
import Typography from "../../../atoms/Typography";
import AutoCompleteMulti from "../../../molecules/AutoCompleteMulti";
import { Item } from "../../../molecules/Dropdown";
import Form from "../../../molecules/Form";
import LabelButton from "./LabelButton/label-button";
import { Imperative, Props } from "./types";

export const schema = z.object({
  destination: z
    .object({
      name: z.string().array().default([]),
      regions: z.string().array().default([]),
    })
    .refine(
      (data) => {
        return !!data.name.length || !!data.regions.length;
      },
      { message: "Destination or Region is required" }
    ),
  payment: z.string({
    invalid_type_error: "Payment type is required",
    required_error: "Payment type is required",
  }),
  services: z.string().array().default([]),
});

export type DestinationSchema = z.infer<typeof schema>;

interface DestinationProps extends Props {
  airports: { label: string; value: string }[];
}

const DestinationForm = forwardRef<
  Imperative<DestinationSchema>,
  DestinationProps
>((props, ref) => {
  const { onShowInfo, ...rest } = props;
  const [selectedItems, setSelectedItems] = useState<Item[]>([]);
  const submitted = useRef(false);

  const {
    register,
    trigger,
    setValue,
    getValues,
    formState: { errors },
    reset,
  } = useForm<DestinationSchema>({
    mode: "onTouched",
    resolver: zodResolver(schema),
    defaultValues: {
      destination: { regions: [], name: [] },
      services: [],
    },
  });

  useImperativeHandle(ref, () => ({
    async validate() {
      submitted.current = true;
      return await trigger();
    },
    values() {
      submitted.current = true;
      return getValues();
    },
    resetForm() {
      reset();
    },
  }));

  useEffect(() => {
    if (getValues().destination.name.length === 0) setSelectedItems([]);
  }, [getValues().destination.name]);

  const onAirportSelect = (item: any) => {
    setValue("destination.name", [...getValues().destination.name, item.value]);
    setSelectedItems(
      getValues().destination.name.map((item) => ({ value: item, label: item }))
    );
  };

  const deleteAirport = (item: Item) => {
    setValue(
      "destination.name",
      getValues().destination.name.filter((i) => i !== item.value)
    );
    setSelectedItems(
      getValues()
        .destination.name.map((item) => ({ value: item, label: item }))
        .filter((i) => i.value !== item.value)
    );
  };

  const handleSelectedItems = (childData: Item[]) => {
    setSelectedItems(childData);
  };

  return (
    <Form.Styled columns={1} {...rest}>
      <Form.Item
        label={
          <LabelButton
            onClick={() =>
              onShowInfo(
                CopyManager.get("booking_request_destination_info_header"),
                <Typography.Label>{CopyManager.get("booking_request_destination_info_text")}</Typography.Label>
              )
            }
          >
            {CopyManager.get("booking_request_destination_label")}
          </LabelButton>
        }
        name="_"
      >
        <AutoCompleteMulti
          {...register("destination.name")}
          label={CopyManager.get("booking_request_destination_placeholder")}
          items={props.airports}
          error={errors.destination?.root?.message}
          autoComplete="off"
          onSelectItem={(item) => onAirportSelect(item)}
          onDeleteItem={(item) => deleteAirport(item)}
          selectedItems={selectedItems}
          handleSelectedItems={handleSelectedItems}
          onSearch={(value: String) => {
            const iataMatches = props.airports.filter((item) => {
              return item.label
                .toLowerCase()
                .slice(0, 3)
                .includes(value.toLowerCase());
            });
            const cityMatches = props.airports.filter((item) => {
              return item.label
                .toLowerCase()
                .slice(4)
                .includes(value.toLowerCase());
            });
            return iataMatches
              .concat(cityMatches)
              .filter((value, index, arr) => {
                return arr.indexOf(value) === index;
              });
          }}
        />
      </Form.Item>
      <Form.Item
        name="regions"
        label={CopyManager.get("booking_request_regions_label")}
        error={errors.destination?.root?.message}
      >
        <Checkbox.Group buttonStyle="button">
          <Checkbox.Item value="n-america" {...register("destination.regions")}>
            {CopyManager.get("booking_request_regions_n_america")}
          </Checkbox.Item>
          <Checkbox.Item
            value="c-america-caribbean"
            {...register("destination.regions")}
          >
            {CopyManager.get("booking_request_regions_c_america_caribbean")}
          </Checkbox.Item>
          <Checkbox.Item value="s-america" {...register("destination.regions")}>
            {CopyManager.get("booking_request_regions_s_america")}
          </Checkbox.Item>
          <Checkbox.Item value="africa" {...register("destination.regions")}>
            {CopyManager.get("booking_request_regions_africa")}
          </Checkbox.Item>
          <Checkbox.Item
            value="middle-east"
            {...register("destination.regions")}
          >
            {CopyManager.get("booking_request_regions_middle_east")}
          </Checkbox.Item>
          <Checkbox.Item value="c-asia" {...register("destination.regions")}>
            {CopyManager.get("booking_request_regions_c_asia")}
          </Checkbox.Item>
          <Checkbox.Item value="s-asia" {...register("destination.regions")}>
            {CopyManager.get("booking_request_regions_s_asia")}
          </Checkbox.Item>
          <Checkbox.Item value="e-asia" {...register("destination.regions")}>
            {CopyManager.get("booking_request_regions_e_asia")}
          </Checkbox.Item>
          <Checkbox.Item value="s-e-asia" {...register("destination.regions")}>
            {CopyManager.get("booking_request_regions_se_asia")}
          </Checkbox.Item>
          <Checkbox.Item value="oc-aus" {...register("destination.regions")}>
            {CopyManager.get("booking_request_regions_oc_aus")}
          </Checkbox.Item>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item
        label={
          <LabelButton
            onClick={() =>
              onShowInfo(
                CopyManager.get("booking_request_payment_info_header"),
                <Typography.Label>{CopyManager.get("booking_request_payment_info_text")}</Typography.Label>
              )
            }
          >
            {CopyManager.get("booking_request_payment_label")}
          </LabelButton>
        }
        name="payment"
        error={errors.payment?.message}
      >
        <Radio.Group name="payment" buttonStyle="solid">
          <Radio.Button
            value="pointsEurobonus"
            {...register("payment")}
            icon={<Icon.Points />}
          >
            {CopyManager.get("booking_request_payment_eurobonus")}
          </Radio.Button>
          <Radio.Button
            value="pointsAmex"
            {...register("payment")}
            icon={<Icon.Money />}
          >
            {CopyManager.get("booking_request_payment_amex")}
          </Radio.Button>
          <Radio.Button
            value="pointsOther"
            {...register("payment")}
            icon={<Icon.MoneyAndPoints />}
          >
            {CopyManager.get("booking_request_payment_other")}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={
          <LabelButton
            onClick={() =>
              onShowInfo(
                CopyManager.get("booking_request_additional_info_header"),
                <Typography.Label>{CopyManager.get("booking_request_additional_info_text")}</Typography.Label>
              )
            }
          >
            {CopyManager.get("booking_request_additional_service_label")}
          </LabelButton>
        }
        name="services"
        error={errors.services?.message}
      >
        <Checkbox.Group buttonStyle="button" name="services">
          <Checkbox.Item
            value="transfer"
            {...register("services")}
            icon={<Icon.Points />}
          >
            {CopyManager.get("booking_request_additional_service_transfer")}
          </Checkbox.Item>
          <Checkbox.Item
            value="hotell"
            {...register("services")}
            icon={<Icon.Points />}
          >
            {CopyManager.get("booking_request_additional_service_hotel")}
          </Checkbox.Item>
          <Checkbox.Item
            value="rental-cars"
            {...register("services")}
            icon={<Icon.Points />}
          >
            {CopyManager.get("booking_request_additional_service_rental")}
          </Checkbox.Item>
        </Checkbox.Group>
      </Form.Item>
    </Form.Styled>
  );
});

export default DestinationForm;
