import cx from "classnames";
import React, {
  HTMLAttributes,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useFirebase } from "../../config/context";
import { useApp } from "../../config/context/app-contex";
import { useChatfligtsSession } from "../../hooks/useChatflightsSession";
import Passcode from "../generic/templates/Login/Passcode";
import NoSupport from "../generic/templates/NoSupport";
import ChatPage from "../pages/ChatPage";
import DashboardPage from "../pages/DashboardPage";
import LoginPage from "../pages/LoginPage";
import Box, { Settings } from "./Box";
import styles from "./index.module.css";

export interface Props extends HTMLAttributes<HTMLDivElement> {}

const Router = ({ className, children, ...rest }: Props): ReactElement => {
  const app = useApp();
  const location = useLocation();
  const navigate = useNavigate();
  const firebase = useFirebase();

  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");

  const { sessionValid, generatatePin, validatePin } = useChatfligtsSession();
  const userId = firebase.getCurrentUser()?.uid;

  useEffect(() => {
    if (sessionValid === false && userId) {
      generatatePin(userId);
    }
  }, [sessionValid]);

  const resendPin = () => {
    if (userId) generatatePin(userId);
  };

  const boxSettings: Settings = useMemo((): Settings => {
    if (displayLocation.pathname === "/" || sessionValid === false) {
      return {
        padding: "default",
        maxWidth: "630px",
        maxHeight: "780px",
        withPanel: false,
        background: "#fff",
      };
    }

    return {
      padding: "none",
      maxWidth: "1280px",
      withPanel: !(displayLocation.pathname === "/dashboard"),
    };
  }, [displayLocation, sessionValid]);

  useEffect(() => {
    if (location.pathname !== displayLocation.pathname) {
      setTransistionStage("fadeOut");
    }
  }, [location]);

  useEffect(() => {
    if (app.userLoggedIn === null) return;
    if (app.userLoggedIn) {
      if (location.pathname === "/") navigate("/dashboard");
    } else {
      navigate("/");
    }
  }, [app.userLoggedIn]);

  const handleSubmit = (pin: number) => {
    if (validatePin(pin)) {
      setTransistionStage("fadeOut");
      setTimeout(() => {
        setTransistionStage("fadeIn");
      }, 700);
    }
    return validatePin(pin);
  };

  return (
    <>
      <Box
        className={styles.box}
        settings={boxSettings}
        onEndTransition={() => {
          setTransistionStage("fadeIn");
        }}
      >
        <div
          className={cx(styles.content, styles[transitionStage])}
          onAnimationEnd={() => {
            if (transitionStage !== "fadeIn") {
              setDisplayLocation(location);
            }
          }}
        >
          {sessionValid ? (
            <Routes location={displayLocation}>
              <Route path="/" element={<LoginPage />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/chat" element={<ChatPage />} />
              <Route path="/chat/:id" element={<ChatPage />} />
            </Routes>
          ) : (
            <Passcode
              onComplete={(enteredPin) => handleSubmit(enteredPin)}
              resendPin={resendPin}
            />
          )}
        </div>
      </Box>

      <NoSupport className={styles.noSupport} />
    </>
  );
};

export default Router;
