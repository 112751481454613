import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./components/Router";
import Firebase, { FirebaseProvider } from "./config/Firebase";
import firebase from "./config/Firebase/firebase";
import { AppProvider } from "./config/context/app-contex";
import "./index.css";
import { INameToValueMap } from "./lib/types";
import CopyManager from "./utils/CopyManager";

export let StoreGlobal: INameToValueMap = {};

interface IAppProps {}

interface IAppState {}

class App extends Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);
    const languageCode: string = CopyManager.getLanguageCode();
    firebase.setLanguageCode(languageCode);
    firebase.getAppLocale(languageCode).on(
      "value",
      (snapshot) => {
        const json = snapshot.toJSON();
        if (!json) return;
        const localeObject: INameToValueMap = {};
        localeObject[languageCode] = json;
        StoreGlobal = localeObject;
      },
      (err: any) => {
        console.log("Error", err);
        const data = localStorage.getItem(languageCode);
        if (data !== null) {
          const localeObject: INameToValueMap = {};
          const json: Object = JSON.parse(data);
          localeObject[languageCode] = json;
          StoreGlobal = localeObject;
        }
      }
    );
  }

  render() {
    return (
      <FirebaseProvider value={Firebase}>
        <AppProvider>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </AppProvider>
      </FirebaseProvider>
    );
  }
}

export default App;
