import { useEffect, useState } from "react";
import {
  PaymentType,
  createGroupInvoice as createGroupOrgInvoice,
  createCompany as createOrg,
  createInvoice as createOrgInvoice,
} from "../api/api-invoices";
import { useFirebase } from "../config/context";
import { InvoiceOrg } from "../lib/types/invoices";

export type Response = { ok: boolean; error?: string };
export type Company = {
  id: string;
};

export const useInvoice = () => {
  const firebase = useFirebase();
  const [fetching, isFetching] = useState(false);
  const [companys, setCompanys] = useState<InvoiceOrg[]>([]);

  const createCompany = async (
    orgNr: string,
    email: string
  ): Promise<Response & { company?: { id: string; name: string } }> => {
    isFetching(true);
    const response = await createOrg(firebase.getCurrentUser()?.uid ?? "", orgNr, email)
      .then((data) => ({ ok: !!data, company: { id: data?.data.id ?? "", name: data?.data.name ?? "" } }))
      .then((data) => {
        updateCompanys();
        return data;
      })
      .catch((e) => ({ ok: false, error: e.message ?? "" }));
    isFetching(false);
    return response;
  };

  const createInvoice = async (payment: PaymentType, companyId: string): Promise<Response> => {
    isFetching(true);
    const response = await createOrgInvoice(firebase.getCurrentUser()?.uid ?? "", payment, companyId)
      .then((data) => ({ ok: !!data }))
      .catch((e) => ({ ok: false, error: e.message ?? "" }));
    isFetching(false);
    return response;
  };

  const createGroupInvoice = async (
    groupId: string,
    groupUserId: string,
    payment: PaymentType,
    companyId: string,
    messageId: string
  ): Promise<Response> => {
    isFetching(true);
    const response = await createGroupOrgInvoice(
      firebase.getCurrentUser()?.uid ?? "",
      groupId,
      groupUserId,
      payment,
      companyId,
      messageId
    )
      .then((data) => ({ ok: !!data }))
      .catch((e) => ({ ok: false, error: e.message ?? "" }));
    isFetching(false);
    return response;
  };

  const deleteCompany = async (id: string) => {
    isFetching(true);
    const uid = firebase.getCurrentUser()?.uid;
    if (uid) {
      firebase.removeInvoiceOrganization(id, uid);
      updateCompanys();
    }
    isFetching(false);
  };

  const updateCompanys = async () => {
    const id = firebase.getCurrentUser()?.uid;
    if (id) return firebase.getInvoiceOrganizations(id).then((orgs) => setCompanys(orgs));
  };

  useEffect(() => {
    updateCompanys();
  }, []);

  return { createCompany, createInvoice, createGroupInvoice, deleteCompany, companys, fetching };
};
