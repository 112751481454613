import cx from "classnames";
import React, { HTMLAttributes, ReactElement } from "react";
import styles from "./index.module.css";

export interface Props extends HTMLAttributes<HTMLDivElement> {}

const Panel = ({ className, children, ...rest }: Props): ReactElement => {
  return <div className={cx(styles.panel, className)}>{children}</div>;
};

export default Panel;
