import moment from "moment";
import React, { Component, CSSProperties } from "react";
import { withFirebaseHOC } from "../../config/Firebase";
import { IFirebase } from "../../config/Firebase/type";
import { Flight, INameToValueMap, Trip } from "../../lib/types";
import CopyManager from "../../utils/CopyManager";
import { CardLayout } from "./CardLayout";
import serviceLogos from "./ServiceInfo";
import Services from "./Services.json";
import { Travellers } from "./Travellers";

export interface ITripInfoProps {
  firebase: IFirebase;
  data: Trip;
  expanded: boolean;
  onTravellerChange: () => void;
}

export interface ITripInfoState {
  routes: Array<{ key: string; title: string }>;
  index: number;
  serviceName: string;
  serviceSupportInfo: string;
  logo: string;
  color: string;
  navBarShade: "bright" | "dark";
  actionTitle: string;
  actionMessage?: string;
  actionOptions: Array<string>;
  actionCancelButtonIndex: number;
  expanded: boolean;
  isPrimaryTab: boolean;
}

const icons = {
  flight: require("../../images/flight.png"),
  rental: require("../../images/car.png"),
  hotel: require("../../images/hotel.png"),
};

class TripInfo extends Component<ITripInfoProps, ITripInfoState> {
  constructor(props: ITripInfoProps) {
    super(props);

    this.state = {
      routes: [
        { key: "primary", title: CopyManager.get("booking_tab_receipt") },
        { key: "secondary", title: CopyManager.get("booking_tab_bill") },
      ],
      index: 0,
      serviceName: "",
      serviceSupportInfo: "",
      logo: "",
      color: "#f5f5f5",
      navBarShade: "bright",
      actionTitle: "",
      actionOptions: [],
      actionCancelButtonIndex: 2,
      expanded: false,
      isPrimaryTab: true,
    };
  }

  componentDidMount = () => {
    this.setStatusBarAttr();
  };

  setStatusBarAttr = () => {
    const { data } = this.props;
    const { expanded } = this.props;
    const offWhiteColor = "#f5f5f5";
    let luma: number = 255;
    if (data.flights && data.flights.length > 0) {
      this.props.firebase
        .getService(data.flights[0].serviceID)
        .once("value")
        .then((res) => {
          const json: INameToValueMap = res.val();

          if (!json) {
            this.setState({
              color: offWhiteColor,
              navBarShade: "bright",
            });
            return;
          }

          if (expanded && json.color) {
            let hex: string = json.color;
            hex = hex.substring(1);

            const rgb: number = parseInt(hex, 16);
            const r: number = (rgb >> 16) & 0xff;
            const g: number = (rgb >> 8) & 0xff;
            const b: number = (rgb >> 0) & 0xff;

            luma = Math.floor(0.2126 * r + 0.7152 * g + 0.0722 * b); // 0 - 255
          }

          this.setState({
            color: json.color || offWhiteColor,
            logo:
              json.hasOwnProperty("logoFilename") &&
              serviceLogos[json.logoFilename]
                ? serviceLogos[json.logoFilename]
                : "",
            serviceName: json.name,
            serviceSupportInfo: json.supportInfo || "",
            navBarShade: luma > 128 ? "bright" : "dark",
          });
        })
        .catch((err) => {
          this.setState({
            color: offWhiteColor,
            navBarShade: "bright",
          });
        });
    } else {
      this.setState({
        navBarShade: luma > 128 ? "bright" : "dark",
      });

      // setServiceName for Hotel and Car
      if (data.hotel) {
        this.setState({ serviceName: data.hotel.name });
      }

      if (data.rental) {
        const { carType, carFirm } = data.rental;
        this.setState({
          serviceName: carType.concat(" - ", carFirm),
        });
      }
    }
  };

  openActionSheet = () => {
    this.setExpanded();
  };

  setExpanded = () => {
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  };

  setReceiptTab = () => {
    this.setState(() => ({
      isPrimaryTab: true,
    }));
  };

  setCorporateTab = () => {
    this.setState(() => ({
      isPrimaryTab: false,
    }));
  };

  renderTabHeader = () => {
    return (
      <div className="receipt-tabs">
        <div
          className={
            this.state.isPrimaryTab ? "receipt-tab active" : "receipt-tab"
          }
          onClick={this.setReceiptTab}
        >
          {CopyManager.get("booking_tab_receipt")}
        </div>
        <div
          className={
            this.state.isPrimaryTab ? "receipt-tab" : "receipt-tab active"
          }
          onClick={this.setCorporateTab}
        >
          {CopyManager.get("corporate_travel_tab")}
        </div>
      </div>
    );
  };

  renderTab = () => {
    const { payment } = this.props.data;
    const paymentDate: string = moment(payment.payBeforeTimestamp).format(
      "DD MMM YYYY"
    );
    return this.state.isPrimaryTab ? (
      <div className="tab-container">
        <div className="text" style={{ marginBottom: 25 }}>
          {paymentDate}
        </div>
        <div className="receipt-wrapper">
          <div className="receipt-left-col">
            <div className="text">
              {CopyManager.get("booking_receipt_cost")}
            </div>
            <div className="text">{CopyManager.get("booking_receipt_fee")}</div>
            <div className="text">{CopyManager.get("booking_receipt_vat")}</div>
            <div
              className="info-bold"
              style={{ fontWeight: "bold", marginTop: 30, marginBottom: 10 }}
            >
              {CopyManager.get("booking_receipt_total")}
            </div>
          </div>
          <div className="receipt-right-col">
            <div className="text">{payment.priceSek} kr</div>
            <div className="text">{payment.bookingFeeSek} kr</div>
            <div className="text">{payment.vat * 100} %</div>
            <div
              className="info-bold"
              style={{ width: "auto", marginTop: 30, marginBottom: 10 }}
            >
              {payment.totalPriceSek} kr
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="tab-container">
        <div className="travel-bill">
          <div className="bill-title">
            {CopyManager.get("booking_bill_title")}
          </div>
          <div className="bill-text">
            {CopyManager.get("booking_bill_body")}
          </div>
        </div>
        <a
          title={CopyManager.get("booking_bill_cta")}
          href={CopyManager.get("CHATFLIGHTS_PLUS_URL")}
          target="_blank"
          rel="noopener noreferrer"
        >
          <button>{CopyManager.get("booking_bill_cta")}</button>
        </a>
      </div>
    );
  };

  getLocalDateTime = (string: moment.Moment) =>
    moment(string).utcOffset(moment.parseZone(string).utcOffset());

  getWeekDay = (string: moment.Moment) =>
    moment(string).format("ddd").toUpperCase();

  getFormattedDepDate = (string: moment.Moment) =>
    moment(string).format("DD MMM YYYY");

  getFormattedTime = (string: moment.Moment) => moment(string).format("HH:mm");

  renderCardLayoutInfo = () => {
    const { data } = this.props;
    const { expanded } = this.state;
    if (data.flights && data.flights.length > 0) {
      const { bookingReference, flights } = data;

      const departureFlight: Flight = flights[0];
      const arrivalFlight: Flight = flights[flights.length - 1];

      const { flightNumber, departure } = departureFlight;

      // Make the date-object into local time at the airport of departure
      const departureDateTime: moment.Moment = this.getLocalDateTime(
        departure.time
      );
      const departureDate: string = departureDateTime.format("DD MMM YYYY");
      const departureTime: string = this.getFormattedTime(departureDateTime);

      return (
        <>
          <CardLayout
            leftColSubheading={departureFlight.departure.city}
            leftColHeading={departureFlight.departure.iata}
            flightNumber={flightNumber}
            rightColSubheading={arrivalFlight.destination.city}
            rightColHeading={arrivalFlight.destination.iata}
            departureDate={departureDate}
            departureDateSubText={departureTime}
            reference={bookingReference}
            openActionSheet={this.openActionSheet}
            expanded={expanded}
            imageUrl={icons.flight}
          />
          <Travellers
            travellers={data.passengers}
            travellerSelected={this.props.onTravellerChange}
          />
        </>
      );
    }

    if (data.rental) {
      const { rentalReference } = data;
      const { carType, carFirm, pickUpDate, returnDate } = data.rental;
      return (
        <>
          <CardLayout
            leftColSubheading={carType.concat(" - ", carFirm)}
            leftColHeading={this.getWeekDay(pickUpDate)}
            rightColHeading={this.getWeekDay(returnDate)}
            departureDate={this.getFormattedDepDate(pickUpDate)}
            departureDateSubText={this.getFormattedTime(pickUpDate)}
            reference={rentalReference}
            openActionSheet={this.openActionSheet}
            expanded={expanded}
            imageUrl={icons.rental}
          />
          <Travellers
            travellers={data.passengers}
            travellerSelected={this.props.onTravellerChange}
          />
        </>
      );
    }

    if (data.hotel) {
      const { hotelReference } = data;
      const { name, checkInDate, checkOutDate, nights } = data.hotel;
      return (
        <>
          <CardLayout
            leftColSubheading={name}
            leftColHeading={this.getWeekDay(checkInDate)}
            rightColHeading={this.getWeekDay(checkOutDate)}
            departureDate={this.getFormattedDepDate(checkInDate)}
            departureDateSubText={`${nights} ${CopyManager.get(
              "tripInfo_nights"
            )}`}
            reference={hotelReference}
            openActionSheet={this.openActionSheet}
            expanded={expanded}
            imageUrl={icons.hotel}
          />
          <Travellers
            travellers={data.passengers}
            travellerSelected={this.props.onTravellerChange}
          />
        </>
      );
    }
  };

  flightsWholeLayout = () => {
    const { flights } = this.props.data;
    const { serviceName } = this.state;

    return (
      flights &&
      flights.map((flight, i) => {
        return (
          <div className="row" key={i}>
            <div className="info-bold">Flight {i + 1}</div>
            <div className="text info-text">
              {serviceName}
              <br />
              Flight: {flight.flightNumber}
              <br />
              <br />
              {flight.departure.city} {flight.departure.iata}
              <br />
              {this.getLocalDateTime(flight.departure.time).format(
                "HH:mm - DD MMM YYYY"
              )}
              <br />
              <br />
              {flight.destination.city} {flight.destination.iata}
              <br />
              {this.getLocalDateTime(flight.destination.time).format(
                "HH:mm - DD MMM YYYY"
              )}
            </div>
          </div>
        );
      })
    );
  };

  rentalWholeLayout = () => {
    if (this.props.data.rental) {
      const {
        carType,
        carFirm,
        pickUpAddress,
        pickUpDate,
        returnAddress,
        returnDate,
      } = this.props.data.rental;
      return (
        <div>
          <div className="row-container row">
            <div className="info-bold">
              {CopyManager.get("tripInfo_car_type")}
            </div>
            <div className="text info-text">
              {carType}
              <br />
              {carFirm}
              <br />
            </div>
          </div>
          <div className="row-container">
            <div className="info-bold">
              {CopyManager.get("tripInfo_car_PickUp")}
            </div>
            <div className="text info-text">
              {pickUpAddress}
              <br />
              {this.getFormattedDepDate(pickUpDate)}
              <br />
              {this.getFormattedTime(pickUpDate)}
              <br />
            </div>
          </div>
          <div className="row-container">
            <div className="info-bold">
              {CopyManager.get("tripInfo_car_Return")}
            </div>
            <div className="text info-text">
              {returnAddress}
              <br />
              {this.getFormattedDepDate(returnDate)}
              <br />
              {this.getFormattedTime(returnDate)}
            </div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  };

  hotelWholeLayout = () => {
    if (this.props.data.hotel) {
      const { name, checkInDate, checkOutDate, rooms, address } =
        this.props.data.hotel;
      return (
        <div>
          <div className="row-container row">
            <div className="info-bold">
              {CopyManager.get("tripInfo_hotel_name")}
            </div>
            <div className="text info-text">
              {address ? address : name}
              <br />
            </div>
          </div>
          <div className="row-container">
            <div className="info-bold">
              {CopyManager.get("tripInfo_hotel_checkinDate")}
            </div>
            <div className="text info-text">
              {this.getFormattedDepDate(checkInDate)}
              <br />
            </div>
          </div>
          <div className="row-container">
            <div className="info-bold">
              {CopyManager.get("tripInfo_hotel_checkoutDate")}
            </div>
            <div className="text info-text">
              {this.getFormattedDepDate(checkOutDate)}
              <br />
            </div>
          </div>
          <div className="row-container">
            <div className="info-bold">
              {CopyManager.get("tripInfo_hotel_room")}
            </div>
            <div className="text info-text">{rooms}</div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  };

  renderWholeLayoutInfo = () => {
    const { data } = this.props;

    if (data.flights && data.flights.length > 0) {
      return this.flightsWholeLayout();
    }

    if (data.rental) {
      return this.rentalWholeLayout();
    }

    if (data.hotel) {
      return this.hotelWholeLayout();
    }
  };

  renderPassengerInfo = () => {
    const { passengers } = this.props.data;

    return passengers
      ? passengers.map((passenger, i) => {
          return (
            <div className="row" key={i}>
              <div className="info-bold">Name {i + 1}</div>
              <div className="text info-text">
                {passenger.firstname} {passenger.lastname}
                {passenger.notes && `\n${passenger.notes || ""}`}
              </div>
            </div>
          );
        })
      : null;
  };

  renderHeader = (statusBarHeight: number) => {
    const { data, expanded } = this.props;
    const offWhiteColor = "#f5f5f5";
    // const { logo, color, serviceName } = this.state; Not using state as it's not updating instantly

    let serviceName: string = "";
    let logo: string = "";
    let color: string = "";

    const services: INameToValueMap = Services;

    if (data.flights && data.flights.length > 0) {
      const json: INameToValueMap = services[data.flights[0].serviceID];
      if (!json) {
        color = offWhiteColor;
        return;
      }
      color = json.color || offWhiteColor;
      logo =
        json.hasOwnProperty("logoFilename") && serviceLogos[json.logoFilename]
          ? serviceLogos[json.logoFilename]
          : "";
      serviceName = json.name;
    } else {
      // setServiceName for Hotel and Car
      if (data.hotel) {
        serviceName = data.hotel.name;
      }

      if (data.rental) {
        const { carType, carFirm } = data.rental;
        serviceName = carType.concat(" - ", carFirm);
      }
    }

    const headerStyle: CSSProperties = {
      position: expanded ? "absolute" : "relative",
      height: expanded ? statusBarHeight + 50 : 45,
      backgroundColor: color ? color : "",
    };

    const logoStyle: CSSProperties = {
      top: expanded ? 34 : 0,
    };

    const serviceStyle: CSSProperties = {
      paddingTop: expanded ? 44 : 12,
    };

    return (
      <div className="header" style={headerStyle}>
        {logo ? (
          <img src={logo} className="logo" style={logoStyle} alt="" />
        ) : (
          <div className="logo-text" style={serviceStyle}>
            {serviceName}
          </div>
        )}
      </div>
    );
  };

  render() {
    const { expanded } = this.state;
    return (
      <>
        {this.renderHeader(20)}
        <div className={expanded ? "card open" : "card"}>
          {this.renderCardLayoutInfo()}
          <div className={expanded ? "info info-open" : "info"}>
            <div className="whole-info">
              {this.renderWholeLayoutInfo()}
              {this.renderPassengerInfo()}
            </div>
            {this.renderTabHeader()}
            {this.renderTab()}
          </div>
        </div>
      </>
    );
  }
}

export default withFirebaseHOC(TripInfo);
