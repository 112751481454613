import { zodResolver } from "@hookform/resolvers/zod";
import React, { ReactElement, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import CopyManager from "../../../../utils/CopyManager";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import Input from "../../atoms/Input";
import Form, { Props as FormProps } from "../../molecules/Form";
import Member, { Imperative, Schema as MemberSchema } from "./forms/member";
import styles from "./index.module.css";

export const schema = z.object({
  group_name: z.string().nonempty("Please enter a group name."),
});

export type Schema = z.infer<typeof schema>;
export interface GroupSchema {
  group_name: string;
  members: MemberSchema[];
}

export interface Props extends FormProps {
  onComplete: (values: GroupSchema) => void;
  onAbort: () => void;
}

const CreateGroupForm = ({ children, className, onComplete, onAbort, ...rest }: Props): ReactElement => {
  const [count, setCount] = useState(0);
  const [members, setMembers] = useState<string[]>([count.toString()]);
  const [formRefs] = useState(new Map<string, Imperative | null>());

  const save = async () => {
    let isValid = await trigger();

    for (let key of formRefs.keys()) {
      if (formRefs.get(key) && !(await formRefs.get(key)?.validate())) isValid = false;
    }

    if (isValid) {
      const members: MemberSchema[] = [];
      for (let key of formRefs.keys()) {
        const ref = formRefs.get(key);
        if (ref) members.push(ref.values());
      }
      onComplete({ group_name: getValues("group_name"), members });
    }
  };

  const deleteMember = (key: string) => {
    setMembers(members.filter((id, index) => key !== id));
    formRefs.delete(key);
  };

  const addMember = () => {
    const c = count + 1;
    setCount(c);
    setMembers([...members, c.toString()]);
  };

  const {
    trigger,
    getValues,
    register,
    formState: { errors },
  } = useForm<Schema>({
    mode: "onTouched",
    resolver: zodResolver(schema),
  });

  return (
    <div className={styles.createGroupForm}>
      <Form.Styled className={styles.form} columns={1}>
        <Input label={CopyManager.get("group_name")} {...register("group_name")} error={errors.group_name?.message} />
      </Form.Styled>
      <div className={styles.members}>
        {members.map((key: string, index: number) => {
          return (
            <div key={key} className={styles.member}>
              <Member
                ref={(el) => formRefs.set(key, el)}
                index={index + 1}
                onDelete={index ? () => deleteMember(key) : undefined}
              />
            </div>
          );
        })}
        <Button
          icon={<Icon.Add className={styles.addIcon} />}
          className={styles.addBtn}
          onClick={addMember}
          grow={false}>
          {CopyManager.get("add_more_members")}
        </Button>
      </div>
      <div className={styles.footer}>
        <Button variant='secondary' onClick={onAbort} grow={false}>
          {CopyManager.get("cancel")}
        </Button>
        <Button onClick={save} grow={false}>
          {CopyManager.get("save")}
        </Button>
      </div>
    </div>
  );
};
export default CreateGroupForm;
