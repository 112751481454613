import React, { ReactElement, useRef, useState } from "react";
import useClickOutside from "../../../../hooks/useClickOutside";
import CopyManager from "../../../../utils/CopyManager";
import Burger from "../../atoms/Burger";
import Button from "../../atoms/Button";
import Divider from "../../atoms/Divider";
import Icon from "../../atoms/Icon";
import Loader from "../../atoms/Loader";
import Typography from "../../atoms/Typography";
import Nav, { User } from "../../organisms/Nav";
import ChatPreview, { Props as Chat } from "./ChatPreview";
import styles from "./index.module.css";

export interface Props {
  conversations: Chat[];
  user: User;
  fetchingGroups?: boolean;
  onCreateNewGroup: () => void;
  onLogout: () => void;
}

const Dashboard = ({
  conversations,
  user,
  onLogout,
  onCreateNewGroup,
  fetchingGroups = false,
}: Props): ReactElement => {
  const single = conversations.filter((chat) => !chat.group);
  const group = conversations.filter((chat) => chat.group);
  const menueRef = useRef<HTMLDivElement | null>(null);
  const [navExpanded, setIsNavExapanded] = useState(false);

  useClickOutside(menueRef, () => {
    if (navExpanded) setIsNavExapanded(false);
  });

  return (
    <section className={styles.dashboard}>
      <header className={styles.heading}>
        <Typography.Title>{CopyManager.get("chat_list_header")}</Typography.Title>
        <div ref={menueRef}>
          <Burger onClick={() => setIsNavExapanded(!navExpanded)} />
          <nav className={styles.menu} onClick={() => setIsNavExapanded(false)}>
            <Nav className={styles.nav} user={user} expanded={navExpanded} onLogout={onLogout}>
            </Nav>
          </nav>
        </div>
      </header>
      <Divider />
      {single.map((chat, index) => (
        <div className={styles.chat} key='private'>
          <ChatPreview {...chat} />
          {index < single.length - 1 && <Divider />}
        </div>
      ))}

      <div className={styles.groupHeading}>
        <Typography.Title level={2}>{CopyManager.get("chat_list_group_header")}</Typography.Title>
        <Button icon={<Icon.Add />} className={styles.addBtn} grow={false} onClick={onCreateNewGroup}>
          {CopyManager.get("create_group")}
        </Button>
      </div>

      <div className={styles.groups}>
        <>
          {!fetchingGroups ? (
            group.map((chat, index) => (
              <div className={styles.chat} key={chat.id} style={{ animationDelay: `${index * 0.1}s` }}>
                <ChatPreview {...chat} />
                {index < group.length - 1 && <Divider />}
              </div>
            ))
          ) : (
            <div className={styles.loader}>
              <Loader />
            </div>
          )}
        </>
      </div>
    </section>
  );
};
export default Dashboard;
