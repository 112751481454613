import cx from "classnames";
import React, { HTMLAttributes, ReactElement } from "react";
import { Invite } from "../../../../lib/types/group";
import CopyManager from "../../../../utils/CopyManager";
import Button from "../../atoms/Button";
import Divider from "../../atoms/Divider";
import Icon from "../../atoms/Icon";
import Typography from "../../atoms/Typography";
import Label from "./Label";
import styles from "./index.module.css";

export type GroupMember = {
  id: string;
  name: string;
  isAdmin: boolean;
};

export interface Props extends HTMLAttributes<HTMLDivElement> {
  groupImage?: string;
  members: GroupMember[];
  invites: Invite[];
  name: string;
  isAdmin?: boolean;
  onAddGroupMember: () => void;
  onDeleteGroupMember: (memberId: string, memberName: string, groupName: string) => void;
  onLeaveGroup: () => void;
}

const GroupSettings = ({
  className,
  children,
  groupImage,
  members,
  invites,
  name,
  onAddGroupMember,
  onDeleteGroupMember,
  onLeaveGroup,
  isAdmin = false,
  ...rest
}: Props): ReactElement => {

  const pendingInvites = invites.filter((invite) => { return invite.status !== "processed" });

  return (
    <div className={cx(styles.groupSettings, className)}>
      <header className={styles.header}>
        <div className={styles.image}>{groupImage && <img src={groupImage} alt='' />}</div>
        <Typography.Title className={styles.title}>{name}</Typography.Title>
      </header>
      <div className={styles.users}>
        <div className={styles.usersTitle}>
          <Typography.Title className={styles.title} level={2}>
            {members.length + pendingInvites.length}{" "}
            {CopyManager.get("group_settings_members")}
          </Typography.Title>
          <Button icon={<Icon.Add />} className={styles.addBtn} onClick={onAddGroupMember}>
            {CopyManager.get("group_settings_add_member")}
          </Button>
        </div>
        <Divider />
        <ul className={styles.content}>
          {members.map((member) => (
            <React.Fragment key={member.name}>
              <li className={styles.user}>
                <Typography.Text as='span' className={styles.username}>
                  {member.name}
                </Typography.Text>
                {member.isAdmin && <Label>Admin</Label>}
                {!member.isAdmin && isAdmin && (
                  <Button
                    icon={<Icon.Occupied className={styles.deleteIcon} />}
                    onClick={() => onDeleteGroupMember(member.id, member.name, name)}
                  />
                )}
              </li>
              <Divider />
            </React.Fragment>
          ))}
          {pendingInvites.map((invite) => (
            <React.Fragment key={invite.name}>
              <li className={styles.user}>
                <Typography.Text as='span' className={styles.username}>
                  {invite.name !== "" ? invite.name : invite.phonenumber}
                </Typography.Text>
                <Typography.Text>{CopyManager.get("group_settings_member_invited")}</Typography.Text>
              </li>
              <Divider />
            </React.Fragment>
          ))}
        </ul>
      </div>
      <div className={styles.content}>
        <Typography.Title level={4}>{CopyManager.get("group_settings_header")}</Typography.Title>
        <Divider />
        <Button icon={<Icon.Leave className={styles.leaveIcon} />} className={styles.leaveBtn} onClick={onLeaveGroup}>
          <span className={styles.leaveBtnLabel}>{CopyManager.get("group_settings_leave_group_button")}</span>
        </Button>
      </div>
    </div>
  );
};

export default GroupSettings;
