import { useEffect, useState } from "react";
import { useFirebase } from "../config/context";
import translationsOnFile from "../lib/en.json";

export const useTranslations = () => {
  const firebase = useFirebase();
  let lang = navigator.language || "en"
  lang = lang.substring(0, 2);
  if(["en", "sv"].indexOf(lang) === -1) {
    lang = "en";
}
  const [language] = useState<string>(lang);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [translations, setTranslations] = useState<any>();
  const englishFileTranslations: { [key: string]: any } = translationsOnFile;

  useEffect(() => {
    const languageCode = language.substring(0, 2);
    firebase
      .getAppLocale(languageCode)
      .once("value")
      .then((snapshot) => {
        const json = snapshot.toJSON();
        if (!json) return;

        setTranslations(json);
        setLoaded(true)
      })

  }, [language]);

  const getCopy = (id: string) => {
    if (translations && translations[id]) {
      return translations[id];
    } else if (englishFileTranslations[id]) {
      return englishFileTranslations[id];
    }
    return id;
  }
  
  return { getCopy, loaded };
};
