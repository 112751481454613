import React, { HTMLAttributes, ReactElement } from "react";

export interface Props extends HTMLAttributes<HTMLOrSVGElement> {
  /**
   * Custom className
   */
  className?: string;
}

export const Logo = ({ className = "", ...rest }: Props): ReactElement => (
  <svg width='1em' height='1em' viewBox='0 0 48 39' fill='none' className={className} {...rest}>
    <g clipPath='url(#clip0_882_122)'>
      <path
        d='M38.8469 6.67273L35.446 10.0837C32.0785 6.70715 27.5116 4.81061 22.7499 4.81124C17.9882 4.81188 13.4218 6.70964 10.0552 10.0871C6.68863 13.4645 4.79766 18.0449 4.7983 22.8207C4.79893 27.5964 6.69111 32.1763 10.0586 35.5528L6.65543 38.9751C4.54068 36.8551 2.86295 34.3382 1.71806 31.5679C0.573167 28.7976 -0.0164792 25.8283 -0.0172112 22.8295C-0.0186896 16.7731 2.3787 10.9642 6.64755 6.68063C10.9164 2.39707 16.707 -0.0102354 22.7456 -0.0117181C28.7841 -0.0132008 34.5759 2.39127 38.8469 6.67273Z'
        fill='currentColor'
        fillOpacity='0.85'
      />
      <path
        d='M44.5946 12.3447L33.7838 23.1875L28.0878 17.477L24.6824 20.9037L30.3761 26.6165L28.4527 28.5456C26.9398 30.0599 24.8897 30.9104 22.7522 30.9104C20.6148 30.9104 18.5647 30.0599 17.0518 28.5456L13.6509 31.9588C16.0665 34.3764 19.3397 35.7341 22.7522 35.7341C26.1648 35.7341 29.4379 34.3764 31.8536 31.9588L47.9977 15.767L44.5946 12.3447Z'
        fill='currentColor'
        fillOpacity='0.85'
      />
      <path
        d='M38.964 36.7162H38.1397V38.9977H37.6892V36.7162H36.8716V36.3457H38.964V36.7162Z'
        fill='currentColor'
        fillOpacity='0.85'
      />
      <path
        d='M40.0226 36.3477L40.7861 38.3807L41.5473 36.3477H42.1397V38.9996H41.6892V38.1254L41.7343 36.9553L40.955 38.9996H40.6262L39.8469 36.9666L39.8919 38.1345V39.0087H39.4415V36.3477H40.0226Z'
        fill='currentColor'
        fillOpacity='0.85'
      />
    </g>
    <defs>
      <clipPath id='clip0_882_122'>
        <rect width='48' height='39' fill='currentColor' />
      </clipPath>
    </defs>
  </svg>
);

export default Logo;
