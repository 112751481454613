import cx from "classnames";
import React, { HTMLAttributes, ReactElement } from "react";
import styles from "./index.module.css";

export interface Props extends HTMLAttributes<HTMLButtonElement> {
  rotation?: number;
}

const IconButton = ({ className, children, rotation = 0, ...rest }: Props): ReactElement => (
  <button
    className={cx(styles.iconButton, className)}
    type='button'
    style={{ transform: `rotate(${rotation}deg)` }}
    {...rest}>
    {children}
  </button>
);

export default IconButton;
