import React, { createContext, useContext } from "react";
import { IFirebase } from "../Firebase/type";

const FirebaseContext = createContext({});

export const FirebaseProvider = FirebaseContext.Provider;

export const FirebaseConsumer = FirebaseContext.Consumer;

export const withFirebaseHOC = (Component: any) => (props: any) =>
  <FirebaseConsumer>{(state) => <Component {...props} firebase={state} />}</FirebaseConsumer>;

export const useFirebase = (): IFirebase => {
  const context = useContext(FirebaseContext) as IFirebase;

  if (context === undefined) {
    throw new Error("useFirebase must be used within a FirebaseProvider");
  }

  return context;
};
