import React, { ReactElement, useMemo } from "react";
import {
  ActionName,
  AuthorType,
  MessageType,
  ProcessState,
} from "../../../../lib/types/enums";
import { IMessageAction } from "../../../../lib/types/messages";
import CopyManager from "../../../../utils/CopyManager";
import ChatItem from "../../../generic/organisms/Chat/ChatItem";
import ImageItem, {
  ImageItemPending,
} from "../../../generic/organisms/Chat/ImageItem";
import LabelItem from "../../../generic/organisms/Chat/LabelItem";
import PriorityItem from "../../../generic/organisms/Chat/PriorityItem";
import QueueItem from "../../../generic/organisms/Chat/QueItem";
import AltsItem from "../../../generic/organisms/Chat/TextWithAlts";
import Time from "../../../generic/organisms/Chat/Time";
import TypingIndicator from "../../../generic/organisms/Chat/TypingIndicator";

export type QueItem = {
  index: number;
};

export interface ChatIMessage {
  id: string;
  authorName?: string;
  authorType: string;
  isUser: boolean;
  messageType: string;
  text: string;
  markdown?: string;
  hiddenText?: boolean;
  timestamp: Date;
  imageUrl?: {
    mobile: string;
    real: string;
    web: string;
    thumb: string;
  };
  attachment?: {
    name: string;
    type: string;
    url: string;
  };
  processState?: ProcessState;
  queItem?: QueItem;
  prio?: boolean;
  action?: IMessageAction;
  alts?: IOnboardingAlternate[];
  typing?: boolean
}

export interface IOnboardingAlternate {
  keyboardVisible: boolean;
  nextMessageID?: string;
  tag: string;
  text: string;
  state: boolean;
  showArrow?: boolean;
  hidden?: boolean;
  selected?: boolean;
}

export interface Props {
  messages: ChatIMessage[];
  onActionClick: (action: IMessageAction, messageId: string) => void;
  onImageClick: (images: string[]) => void;
  onGetHelpClick: () => void;
}

export interface MessageProps extends Props {
  message: ChatIMessage;
}

const imageMimeType = /image\/(png|jpg|jpeg)/i;

const ChatMessage = ({
  message,
  onImageClick,
  onGetHelpClick,
  onActionClick,
}: MessageProps) => {
  if (
    message.messageType === MessageType.Image ||
    (message.attachment && message.attachment.type.match(imageMimeType))
  ) {
    if (message.attachment)
      return (
        <ImageItem
          id={message.id}
          isUser={message.isUser}
          images={[message.attachment.url]}
          onClick={() => onImageClick([message.imageUrl?.real ?? ""])}
        />
      );
    if (message.imageUrl?.web)
      return (
        <ImageItem
          id={message.id}
          isUser={message.isUser}
          images={[message.imageUrl?.real]}
          onClick={() => onImageClick([message.imageUrl?.real ?? ""])}
        />
      );
    if (message.imageUrl?.mobile)
      return (
        <ImageItem
          id={message.id}
          isUser={message.isUser}
          images={[message.imageUrl?.mobile]}
          onClick={() => onImageClick([message.imageUrl?.mobile ?? ""])}
          small
        />
      );
    if (message.processState === ProcessState.Pending)
      return <ImageItemPending isUser={message.isUser} />;
    return <></>;
  }
  if (message.messageType === MessageType.TextAlts)
  return (
    <AltsItem
      id={message.id}
      text={message.text}
      alts={message.alts}
    />
  )
  if (message.authorType === AuthorType.System) {
    return <LabelItem>{message.text}</LabelItem>;
  }
  if (message.prio) {
    return <PriorityItem>{CopyManager.get("priority_chat")}</PriorityItem>;
  }
  if (message.queItem) {
    return (
      <QueueItem index={message.queItem.index} onActionClick={onGetHelpClick} />
    );
  }
  if (message.typing) {
    return <TypingIndicator />
  }

  return (
    <ChatItem
      message={{
        name: message.authorName,
        isUser: message.isUser,
        content: message.text,
        markdown: message.markdown,
        id: message.id,
        action: message.action,
        isAgent: message.authorType === AuthorType.Agent,
        hiddenText: message.hiddenText,
      }}
      onActionClick={onActionClick}
    />
  );
};

const ChatMessages = ({ messages, ...rest }: Props): ReactElement => {
  let lastDate: Date | null = null;

  // Remove all action that are not defined in Action enum. This is to not show un-implemented actions that will not be resolved in UI.
  const items = useMemo(() => {
    return messages.filter((message) => {
      if (message.action && message.action.name) {
        return Object.values(ActionName).includes(message.action.name);
      }
      return true;
    });
  }, [messages]);

  return (
    <>
      {items.map((message) => {
        let messageDate: Date | null = null;
        let renderDate = false;

        if (message.timestamp) {
          messageDate = new Date(message.timestamp);

          if (
            (message.queItem === undefined && message.prio === undefined && message.typing === undefined) &&
            (messageDate.getHours() !== lastDate?.getHours() ||
            messageDate.getMinutes() !== lastDate?.getMinutes())
          )
          renderDate = true;

          lastDate = messageDate;
        }

        return (
          <React.Fragment key={message.id}>
            {renderDate && messageDate && <Time date={messageDate} />}
            <ChatMessage message={message} messages={messages} {...rest} />
          </React.Fragment>
        );
      })}
    </>
  );
};
export default ChatMessages;
