const prodConfig = {
  apiKey: "AIzaSyDfxbmhCq7rI3g3znDHaXhPtSnSFqtrxu8",
  authDomain: "chatflights.firebaseapp.com",
  databaseURL: "https://chatflights.firebaseio.com",
  projectId: "chatflights",
  storageBucket: "chatflights.appspot.com",
  messagingSenderId: "327361599486",
  appId: "1:327361599486:web:0355e1f6efd99cbb6912af"
};

const devConfig = {
  apiKey: "AIzaSyB38lJMA8Lmgy4kUZsDL1OgG76-ZC9_pBY",
  authDomain: "chatflights-dev-e63b2.firebaseapp.com",
  databaseURL: "https://chatflights-dev-e63b2.firebaseio.com",
  projectId: "chatflights-dev-e63b2",
  storageBucket: "chatflights-dev-e63b2.appspot.com",
  messagingSenderId: "679582536235",
  appId: "1:679582536235:web:22835a4bc6d6bcb4e2d06e"
};

const isDev =
  window.location.host.includes("localhost") ||
  window.location.host.split(".")[0].includes("chatflights-dev-e63b2") ||
  window.location.host.split(".")[0].includes("appdev");
const firebaseConfig = isDev ? devConfig : prodConfig;

export default firebaseConfig;