import { INameToValueMap } from "../../lib/types/index";


const serviceLogos: INameToValueMap = [];
serviceLogos['sas.png'] = require('../../images/service-logos/sas.png');
serviceLogos['norwegian.png'] = require('../../images/service-logos/norwegian.png');
serviceLogos['lufthansa.png'] = require('../../images/service-logos/lufthansa.png');
serviceLogos['british_airways.png'] = require('../../images/service-logos/british_airways.png');
serviceLogos['finnair.png'] = require('../../images/service-logos/finnair.png');
serviceLogos['klm.png'] = require('../../images/service-logos/klm.png');
serviceLogos['turkish_airways.png'] = require('../../images/service-logos/turkish_airways.png');
serviceLogos['air_france.png'] = require('../../images/service-logos/air_france.png');
serviceLogos['brussels_airways.png'] = require('../../images/service-logos/brussels_airways.png');
serviceLogos['austrian_airlines.png'] = require('../../images/service-logos/austrian_airlines.png');
serviceLogos['ryanair.png'] = require('../../images/service-logos/ryanair.png');
serviceLogos['swiss.png'] = require('../../images/service-logos/swiss.png');
serviceLogos['tap_portugal.png'] = require('../../images/service-logos/tap_portugal.png');
serviceLogos['tap_portugal.png'] = require('../../images/service-logos/singapore_airlines.png');
serviceLogos['agean.png'] = require('../../images/service-logos/agean.png');
serviceLogos['air_baltic.png'] = require('../../images/service-logos/air_baltic.png');
serviceLogos['united.png'] = require('../../images/service-logos/united.png');
serviceLogos['thai.png'] = require('../../images/service-logos/thai.png');
serviceLogos['bra.png'] = require('../../images/service-logos/bra.png');
serviceLogos['american_airlines.png'] = require('../../images/service-logos/american_airlines.png');
serviceLogos['air_berlin.png'] = require('../../images/service-logos/air_berlin.png');
serviceLogos['iceland_air.png'] = require('../../images/service-logos/iceland_air.png');
serviceLogos['LOT.png'] = require('../../images/service-logos/LOT.png');
serviceLogos['nextjet.png'] = require('../../images/service-logos/nextjet.png');
serviceLogos['quatar_airways.png'] = require('../../images/service-logos/quatar_airways.png');
serviceLogos['wideroe.png'] = require('../../images/service-logos/wideroe.png');
serviceLogos['vueling.png'] = require('../../images/service-logos/vueling.png');
serviceLogos['wizz.png'] = require('../../images/service-logos/wizz.png');
serviceLogos['easy_jet.png'] = require('../../images/service-logos/easy_jet.png');
serviceLogos['adria.png'] = require('../../images/service-logos/adria.png');
serviceLogos['aeroflot.png'] = require('../../images/service-logos/aeroflot.png');
serviceLogos['air_arabia.png'] = require('../../images/service-logos/air_arabia.png');
serviceLogos['air_china.png'] = require('../../images/service-logos/air_china.png');
serviceLogos['air_europa.png'] = require('../../images/service-logos/air_europa.png');
serviceLogos['air_india.png'] = require('../../images/service-logos/air_india.png');
serviceLogos['air_serbia.png'] = require('../../images/service-logos/air_serbia.png');
serviceLogos['ais_airlines.png'] = require('../../images/service-logos/ais_airlines.png');
serviceLogos['alba_star.png'] = require('../../images/service-logos/alba_star.png');
serviceLogos['allitalia.png'] = require('../../images/service-logos/allitalia.png');
serviceLogos['all_nippon.png'] = require('../../images/service-logos/all_nippon.png');
serviceLogos['atlas_global.png'] = require('../../images/service-logos/atlas_global.png');
serviceLogos['bangkok_airways.png'] = require('../../images/service-logos/bangkok_airways.png');
serviceLogos['belavia.png'] = require('../../images/service-logos/belavia.png');
serviceLogos['blue_air.png'] = require('../../images/service-logos/blue_air.png');
serviceLogos['Bulgarian_air_charter.png'] = require('../../images/service-logos/Bulgarian_air_charter.png');
serviceLogos['corendon.png'] = require('../../images/service-logos/corendon.png');
serviceLogos['croatia_airlines.png'] = require('../../images/service-logos/croatia_airlines.png');
serviceLogos['czech_airlines.png'] = require('../../images/service-logos/czech_airlines.png');
serviceLogos['danish_air_transport.png'] = require('../../images/service-logos/danish_air_transport.png');
serviceLogos['delta.png'] = require('../../images/service-logos/delta.png');
serviceLogos['emirates.png'] = require('../../images/service-logos/emirates.png');
serviceLogos['ethiopean.png'] = require('../../images/service-logos/ethiopean.png');
serviceLogos['etihad.png'] = require('../../images/service-logos/etihad.png');
serviceLogos['eurowings.png'] = require('../../images/service-logos/eurowings.png');
serviceLogos['freebird.png'] = require('../../images/service-logos/freebird.png');
serviceLogos['germania.png'] = require('../../images/service-logos/germania.png');
serviceLogos['germanwings.png'] = require('../../images/service-logos/germanwings.png');
serviceLogos['iberia.png'] = require('../../images/service-logos/iberia.png');
serviceLogos['iranian.png'] = require('../../images/service-logos/iranian.png');
serviceLogos['iragi_airways.png'] = require('../../images/service-logos/iragi_airways.png');
serviceLogos['jet_time.png'] = require('../../images/service-logos/jet_time.png');
serviceLogos['luxair.png'] = require('../../images/service-logos/luxair.png');
serviceLogos['monarch.png'] = require('../../images/service-logos/monarch.png');
serviceLogos['neos.png'] = require('../../images/service-logos/neos.png');
serviceLogos['novair.png'] = require('../../images/service-logos/novair.png');
serviceLogos['pegasus.png'] = require('../../images/service-logos/pegasus.png');
serviceLogos['primera_air.png'] = require('../../images/service-logos/primera_air.png');
serviceLogos['royal_air_maroc.png'] = require('../../images/service-logos/royal_air_maroc.png');
serviceLogos['south_african_airways.png'] = require('../../images/service-logos/south_african_airways.png');
serviceLogos['sparrow.png'] = require('../../images/service-logos/sparrow.png');
serviceLogos['sun_express.png'] = require('../../images/service-logos/sun_express.png');
serviceLogos['tarom.png'] = require('../../images/service-logos/tarom.png');
serviceLogos['thomas_cook_airlines.png'] = require('../../images/service-logos/thomas_cook_airlines.png');
serviceLogos['thomson.png'] = require('../../images/service-logos/thomson.png');
serviceLogos['transavia.png'] = require('../../images/service-logos/transavia.png');
serviceLogos['tui.png'] = require('../../images/service-logos/tui.png');
serviceLogos['ukraine_international.png'] = require('../../images/service-logos/ukraine_international.png');
serviceLogos['wow_air.png'] = require('../../images/service-logos/wow_air.png');
serviceLogos['zagrosjet.png'] = require('../../images/service-logos/zagrosjet.png');

export default serviceLogos;
