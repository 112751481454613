import { useEffect, useRef } from 'react';

export default function useInterval(
	callback: (...args: any[]) => void,
	delay?: number | null,
	loop_delay?: number | null,
	...args: any[]
) {
	const savedCallback = useRef<(...args: any[]) => void>();

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		function tick() {
			if (savedCallback.current) {
				savedCallback.current(...args);
			}
		}
		if (loop_delay !== null && loop_delay !== undefined) {
			if (delay === null || delay === undefined) {
				delay = 0;
			}
			setTimeout(() => {
				let id = setInterval(tick, loop_delay);
				return () => clearInterval(id);
			}, delay);
		}
	}, [loop_delay]);
}
