import cx from "classnames";
import React, { ReactElement } from "react";
import { useFirebase } from "../../../../../config/context";
import { IOnboardingAlternate } from "../../../../pages/ChatPage/Chats";
import styles from "./index.module.css";

interface Props {
  id: string;
  text: string;
  alts?: IOnboardingAlternate[];
}

const AltsItem = ({ id, text, alts }: Props): ReactElement => {
  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null);
  const [isHovered, setIsHovered] = React.useState<boolean>(false);
  const firebase = useFirebase();

  const handleClick = (index: number) => {
    const user = firebase.getCurrentUser();
    if (user && user.uid) {
      firebase.addAltsSelected(user?.uid, id, index);
    }
  };

  const selectedAlt = alts?.find((alt) => alt.selected);

  if (selectedAlt) {
    return (
      <>
        <div className={styles.textItem}>
          <span className={styles.labelItem}>{text}</span>
        </div>
        <div className={cx(styles.altsItem, styles.altSelected)}>
          <span className={styles.labelItem}>{selectedAlt.text}</span>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className={styles.textItem}>
          <span className={styles.labelItem}>{text}</span>
        </div>
        {alts?.map((alt, index) => (
          <div
            onPointerOver={() => {
              setIsHovered(true);
              setSelectedIndex(index);
            }}
            onPointerOut={() => {
              setIsHovered(false);
              setSelectedIndex(null);
            }}
            className={
              isHovered && index === selectedIndex
                ? styles.altSelected
                : styles.altsItem
            }
            key={index}
            onClick={() => handleClick(index)}
          >
            <span className={styles.labelItem}>{alt.text}</span>
          </div>
        ))}
      </>
    );
  }
};

export default AltsItem;

interface ArrowProps {
  arrowColor: string;
  // w: number;
  // h: number;
}
const Arrow = (props: ArrowProps) => (
  <svg width="0.875em" height="1em" viewBox="0 0 448 512" fill="none">
    <path
      fill={props.arrowColor}
      d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"
    />
  </svg>
);

const ArrowUp = (props: ArrowProps) => (
  <svg
    width="0.875em"
    height="1em"
    fill="none"
    style={{ left: 1 }}
    viewBox="0 0 448 512"
  >
    <path
      fill={props.arrowColor}
      d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
    />
  </svg>
);

export const colors = {
  blue: "#439fe2",
  white: "#ffffff",
};

export const ArrowRight = () => <Arrow arrowColor={colors.white} />;

export const ArrowRightSmall = () => <Arrow arrowColor={colors.white} />;

export const ArrowUpBlue = () => <ArrowUp arrowColor={colors.blue} />;

export const ArrowUpWhite = () => <ArrowUp arrowColor={colors.white} />;

export const ArrowRightBlue = () => <Arrow arrowColor={colors.blue} />;
