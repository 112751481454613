import React, { HTMLAttributes, ReactElement, ReactNode, useRef, useState } from "react";

import cx from "classnames";
import { useFirebase } from "../../../../config/context";
import useClickOutside from "../../../../hooks/useClickOutside";
import Burger from "../../../generic/atoms/Burger";
import Button from "../../../generic/atoms/Button";
import Icon from "../../../generic/atoms/Icon";
import Typography from "../../../generic/atoms/Typography";
import Nav from "../../../generic/organisms/Nav";
import styles from "./index.module.css";

export interface Props extends HTMLAttributes<HTMLDivElement> {
  name?: string | null;
  navLinks?: ReactNode;
  padding?: boolean;
  onBack: () => void;
}

const Header = ({ name, navLinks, padding = true, onBack, ...rest }: Props): ReactElement => {
  const firebase = useFirebase();
  const menueRef = useRef<HTMLDivElement | null>(null);
  const [navExpanded, setIsNavExapanded] = useState(false);

  useClickOutside(menueRef, () => {
    if (navExpanded) setIsNavExapanded(false);
  });

  return (
    <div className={cx(styles.heading, { [styles.padding]: padding })}>
      <Button
        icon={<Icon.Chevron className={styles.chevron} />}
        type='button'
        onClick={onBack}
        className={styles.backBtn}
        grow={false}>
      </Button>
      <Typography.Title level={4} className={styles.title}>
        {name ?? "Chatflights"}
      </Typography.Title>

      {navLinks && (
        <div ref={menueRef} className={styles.burger}>
          <Burger onClick={() => setIsNavExapanded(!navExpanded)} />
          <nav className={styles.menu} ref={menueRef} onClick={() => setIsNavExapanded(false)}>
            <Nav
              className={styles.nav}
              user={{ firstName: firebase.getCurrentUser()?.displayName ?? "", lastName: "", email: firebase.getCurrentUser()?.email || "" }}
              expanded={navExpanded}
              onLogout={() => firebase.signOut()}>
              {navLinks}
            </Nav>
          </nav>
        </div>
      )}
    </div>
  );
};
export default Header;
