import React, { useState } from "react";
import CopyManager from "../../../utils/CopyManager";
import CreateGroupForm, { GroupSchema } from "../../generic/organisms/CreateGroupForm";
import Modal from "../../generic/organisms/Modal";

export interface Props<T = unknown, U = unknown> {
  onConfirm: (
    values: T,
    showError: (error?: string | null) => void,
    isLoading: (loading: boolean) => void,
    ...args: U[]
  ) => void;
  onClose: () => void;
}

export const NewCreateGroup = ({ onConfirm, onClose }: Props<GroupSchema>) => {
  const [loading, isLoading] = useState(false);
  const [error, showError] = useState<string | null | undefined>(null);

  return (
    <Modal title={CopyManager.get("new_group_title_message")} onClose={onClose} loading={loading} error={error}>
      <CreateGroupForm
        onComplete={(values) => {
          onConfirm(values, showError, isLoading);
        }}
        onAbort={onClose}
      />
    </Modal>
  );
};
