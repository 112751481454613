import cx from "classnames";
import React, { ReactElement, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { getLocalDate } from "../../../../../utils/date";
import Typography from "../../../atoms/Typography";
import styles from "./index.module.css";

export interface Props {
  id: string;
  group: boolean;
  name: string;
  preamble: string;
  update: Date;
  img?: ReactNode;
  unread?: boolean;
  link: string;
}

const ChatPreview = ({ group, name, preamble, update, img, unread, link, ...rest }: Props): ReactElement => {
  const navigate = useNavigate();

  return (
    <button className={styles.chatPreview} type='button' onClick={() => navigate(link)}>
      <span className={cx(styles.icon, { [styles.unread]: unread })}>
        <span className={styles.image}>{img}</span>
      </span>
      <span className={styles.details}>
        <Typography.Title level={4} className={styles.groupTitle}>
          {name}
        </Typography.Title>
        <Typography.Text className={styles.groupPreamble}>{preamble ?? ""}</Typography.Text>
      </span>
      <span className={styles.date}>
        <Typography.Text className={styles.dateTime}>
          {update.getDate() === new Date().getDate() ? (
            <time>{getLocalDate(update, { hour: "2-digit", minute: "2-digit" })}</time>
          ) : (
            <time>{getLocalDate(update, { year: "2-digit", month: "2-digit", day: "2-digit" })}</time>
          )}
        </Typography.Text>
      </span>
    </button>
  );
};
export default ChatPreview;
