import React, { ReactElement, useEffect, useState } from "react";
import { useFirebase } from "../../../../config/context";
import { useAgentTyping } from "../../../../hooks/useAgentTyping";
import { useGetSingleChatMessages } from "../../../../hooks/useChatMessages";
import { useOnboarding } from "../../../../hooks/useOnboarding";
import { usePriority } from "../../../../hooks/usePriority";
import { useQueuePosition } from "../../../../hooks/useQueuePosition";
import Chat from "../../../generic/organisms/Chat";
import ChatMessages, { ChatIMessage, Props as IMessages } from "./";

export interface Props extends Omit<IMessages, "messages"|"onFetchOlderClick"|"hasMore"> {
  userId: string;
}

const SingleChat = ({ userId, ...rest }: Props): ReactElement => {
  const { messages, fetchOlderMessages, hasMore } = useGetSingleChatMessages();
  const { queuePosition } = useQueuePosition(userId);
  const { priority } = usePriority(userId);
  const firebase = useFirebase();
  const { showKeyboard } = useOnboarding(userId)
  const { typing } = useAgentTyping(userId)
  const [showTyping, setShowTyping] = useState<boolean>(true);

  useEffect(() => {
    setShowTyping(typing)
  }, [typing])

  useEffect(() => {
    if (messages.length > 0) {
      if (messages[messages.length - 1].authorID !== userId && showTyping) {
        setShowTyping(false)
      }
    }
  }, [messages]);

  const sendText = (text: string) => {
    return firebase.sendTextMessage(userId, text);
  };

  const sendImage = (file: File) => {
    return firebase.sendImageMessage(userId, file);
  };

  const messagesWithQueuePosition = (): ChatIMessage[] => {
    const msgs: ChatIMessage[] = messages.map((message) => ({
      ...message,
      ...{
        isUser: userId === message.authorID,
        timestamp: new Date(message.timestamp),
        authorName: undefined,
        markdown: message.textMarkdown,
      },
    }));
    if (queuePosition !== undefined || priority) {
      if (queuePosition && !priority) {
        msgs.push({
          isUser: false,
          timestamp: new Date(),
          authorName: undefined,
          authorType: "",
          messageType: "system",
          text: "",
          id: "queItem",
          queItem: {
            index: queuePosition,
          },
        });
      }
      if (priority) {
        msgs.push({
          isUser: false,
          timestamp: new Date(),
          authorName: undefined,
          authorType: "",
          messageType: "system",
          text: "",
          id: "prio",
          prio: true,
        });
      }
    }
    if (showTyping) {
      msgs.push({
        isUser: false,
        timestamp: new Date(),
        authorName: undefined,
        authorType: "",
        messageType: "system",
        text: "",
        id: "typeindicator",
        typing: true
      })
    }
    return msgs;
  };

  const msgs = messagesWithQueuePosition()
  const ts = messages[messages.length - 1]

  return (
    <>
      <Chat
        userID= {userId}
        groupId="1"
        latestMessageTime={ts && ts.timestamp}
        sendImage={sendImage}
        sendText={sendText}
        dataLength={msgs.length}
        fetchMore={fetchOlderMessages}
        hasMoreData={hasMore}
        batchSize={5}
        showLoader={true}
        showEndMessage={true}
        showKeyboard={showKeyboard}
      >
        <ChatMessages {...rest} messages={msgs}/>
      </Chat>
    </>
  );
};
export default SingleChat;
