import cx from "classnames";
import React, { HTMLAttributes, ReactElement, ReactNode, cloneElement, isValidElement } from "react";
import styles from "./index.module.css";

export interface Props extends HTMLAttributes<HTMLDivElement> {
  name?: string;
  label?: ReactNode;
  error?: ReactNode;
}

const Item = ({ name, label, error, className, children, ...rest }: Props): ReactElement => (
  <div className={cx(styles.item, className)} {...rest}>
    <div className={cx(styles.content)}>
      {label && (
        <label className={cx({ [styles.label]: label instanceof String || typeof label === "string" })} htmlFor={name}>
          {label}
        </label>
      )}
      {/* @ts-expect-error */}
      {isValidElement(children) && cloneElement(children, { id: name ?? "" })}
    </div>

    {error && (
      <div role='alert' className={styles.error}>
        {error}
      </div>
    )}
  </div>
);

export default Item;
