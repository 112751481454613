import cx from "classnames";
import React, { ReactElement, ReactNode } from "react";
import styles from "./index.module.css";

export interface Props<T> extends React.HTMLAttributes<T> {
  children?: ReactNode;
  className?: string;
}

export interface ParagraphProps extends Props<HTMLParagraphElement> {
  /**
   * Level (size) to render paragraph in
   */
  level?: number;
  /**
   * Render with no opacity
   */
  light?: boolean;
  /**
   * Define tag for node
   */
  as?: string;
}

export interface TitleProps
  extends Props<HTMLHeadingElement | HTMLQuoteElement | HTMLParagraphElement | HTMLSpanElement | HTMLLabelElement> {
  /**
   * Level (size) to render title in
   */
  level?: number;
  /**
   * Define tag for node (h1,h2 etc)
   */
  as?: string;
  /**
   * In case of as being blockquote
   */
  cite?: string;
}

const Tag = ({ as, children, cite, ...rest }: TitleProps): ReactElement => {
  switch (as) {
    case "h1":
      return <h1 {...rest}>{children}</h1>;
    case "h2":
      return <h2 {...rest}>{children}</h2>;
    case "h3":
      return <h3 {...rest}>{children}</h3>;
    case "h4":
      return <h4 {...rest}>{children}</h4>;
    case "p":
      return <p {...rest}>{children}</p>;
    case "a":
      return <a {...rest}>{children}</a>;
    case "span":
      return <span {...rest}>{children}</span>;
    case "label":
      return <label {...rest}>{children}</label>;
    case "blockquote":
      if (cite !== null)
        return (
          <blockquote cite={cite} {...rest}>
            {children}
          </blockquote>
        );
      break;
    default:
      return <span>Not permitted as-element</span>;
  }
  return <></>;
};

export const Text = ({ children, className, as, level = 2, light = true, ...rest }: ParagraphProps): ReactElement => {
  const asTag = as ?? `p`;
  return (
    <Tag
      as={asTag}
      className={cx(styles.paragraph, styles[`paragraphLevel${level}`], className, { [styles.light]: light })}
      {...rest}>
      {children}
    </Tag>
  );
};
export const Label = ({ children, className, as, level = 1, light = true, ...rest }: ParagraphProps): ReactElement => {
  const asTag = as ?? `p`;
  return (
    <Tag
      as={asTag}
      className={cx(styles.label, styles.paragraph, styles[`paragraphLevel${level}`], className, {
        [styles.light]: light,
      })}
      {...rest}>
      {children}
    </Tag>
  );
};
export const Bold = ({ children, className, ...rest }: ParagraphProps): ReactElement => (
  <strong className={cx(styles.bold, className)} {...rest}>
    {children}
  </strong>
);
export const Italic = ({ children, className, ...rest }: ParagraphProps): ReactElement => (
  <em className={cx(styles.italic, className)} {...rest}>
    {children}
  </em>
);
export const Underline = ({ children, className, ...rest }: ParagraphProps): ReactElement => (
  <span className={cx(styles.underline, className)} {...rest}>
    {children}
  </span>
);

export const Title = ({ children, className = "", as, cite, level = 1, ...rest }: TitleProps): ReactElement => {
  const asTag = as ?? `h${level}`;
  return (
    <Tag as={asTag} className={cx(styles.title, styles[`titleLevel${level}`], className)} cite={cite} {...rest}>
      {children}
    </Tag>
  );
};

const Typography = {
  Text,
  Title,
  Bold,
  Italic,
  Label,
};

export default Typography;
