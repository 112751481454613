import cx from "classnames";
import React, { HTMLAttributes, ReactElement } from "react";
import styles from "./index.module.css";

export interface Props extends HTMLAttributes<HTMLOrSVGElement> {
  className?: string;
}

export const EyeShow = ({ className = "", ...rest }: Props): ReactElement => (
  <svg width='1em' height='1em' viewBox='0 0 18 14' fill='none' className={cx(styles.icon, className)} {...rest}>
    <path
      opacity='0.54'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.00049 1C4.58303 1 1.00049 5.84873 1.00049 6.81818C1.00049 7.78764 4.58303 12.6364 9.00049 12.6364C13.4179 12.6364 17.0005 7.78764 17.0005 6.81818C17.0005 5.84873 13.4179 1 9.00049 1Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      opacity='0.54'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.00018 9.00035C7.79509 9.00035 6.81836 8.02363 6.81836 6.81854C6.81836 5.61345 7.79509 4.63672 9.00018 4.63672C10.2053 4.63672 11.182 5.61345 11.182 6.81854C11.182 8.02363 10.2053 9.00035 9.00018 9.00035Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const EyeHide = ({ className = "", ...rest }: Props): ReactElement => (
  <svg width='1em' height='1em' viewBox='0 0 18 18' fill='none' className={cx(styles.icon, className)} {...rest}>
    <path
      d='M15.9832 10.8403C16.629 10.0199 16.9999 9.31012 16.9999 8.99885C16.9999 8.02939 13.4181 3.18066 8.9999 3.18066C8.78609 3.18066 8.5759 3.20175 8.36572 3.22285'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.01576 7.1582C1.37067 7.97784 1.00049 8.68838 1.00049 8.99893C1.00049 9.96838 4.58231 14.8171 9.00049 14.8171C9.21285 14.8171 9.42303 14.796 9.63249 14.7749'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M1.00049 1L17.0005 17' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

export const Close = ({ className = "", ...rest }: Props): ReactElement => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 8 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={cx(styles.icon, className)}
    {...rest}>
    <path d='M1.00049 1L7.00049 7' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M1.00049 7L7.00049 1' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

export const Female = ({ className = "", ...rest }: Props): ReactElement => (
  <svg width='1em' height='1.7em' viewBox='0 0 14 26' fill='none' className={cx(styles.icon, className)}>
    <g clipPath='url(#clip0_1204_2055)'>
      <path
        d='M7.79719 6.81361C9.59207 6.37668 10.7037 4.52173 10.2801 2.67048C9.85643 0.819234 8.05797 -0.327319 6.26309 0.109616C4.46822 0.546551 3.35661 2.40152 3.78024 4.25277C4.20387 6.10402 6.00232 7.25055 7.79719 6.81361Z'
        fill='currentColor'
      />
      <path
        d='M14 21.1656L9.89443 8.41968H4.10557L0 21.1656H4.0303L4.06452 26H10.0518L9.9697 21.1656H14Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_1204_2055'>
        <rect width='14' height='26' fill='currentColor' />
      </clipPath>
    </defs>
  </svg>
);

export const Male = ({ className = "", ...rest }: Props): ReactElement => (
  <svg width='1em' height='1.7em' viewBox='0 0 14 26' fill='none' className={cx(styles.icon, className)}>
    <g clipPath='url(#clip0_1204_2049)'>
      <path
        d='M6.0001 6.88816C7.96497 6.88816 9.55781 5.3462 9.55781 3.44408C9.55781 1.54197 7.96497 0 6.0001 0C4.03523 0 2.44238 1.54197 2.44238 3.44408C2.44238 5.3462 4.03523 6.88816 6.0001 6.88816Z'
        fill='currentColor'
      />
      <path d='M12 8.41968H0V18.5614H2.44228V25.9436L9.73269 26L9.60875 18.5614H12V8.41968Z' fill='currentColor' />
    </g>
    <defs>
      <clipPath id='clip0_1204_2049'>
        <rect width='12' height='26' fill='currentColor' />
      </clipPath>
    </defs>
  </svg>
);

export const Information = ({ className = "", ...rest }: Props): ReactElement => (
  <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' className={cx(styles.icon, className)}>
    <circle cx='10' cy='10' r='9' stroke='currentColor' strokeWidth='2' />
    <rect x='9' y='9' width='2' height='6' fill='currentColor' />
    <rect x='9' y='5' width='2' height='2' fill='currentColor' />
  </svg>
);

export const Direction = ({ className = "", ...rest }: Props): ReactElement => (
  <svg width='1em' height='1em' viewBox='0 0 34 34' fill='none' className={cx(styles.icon, className)}>
    <circle cx='17' cy='17' r='16.5' stroke='currentColor' />
    <path
      d='M15.5877 23.7144L16.8963 22.3594L12.4922 17.816L25.2894 17.816L25.2894 15.9022L12.6034 15.9022L17.0075 11.355L15.6952 10.0001L9 16.9127L15.5877 23.7144Z'
      fill='currentColor'
    />
  </svg>
);

export const Points = ({ className = "", ...rest }: Props): ReactElement => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' className={cx(styles.icon, className)}>
    <mask id='path-1-inside-1_1689_1755' fill='white'>
      <path d='M30 16C30 18.7689 29.1789 21.4757 27.6406 23.778C26.1022 26.0803 23.9157 27.8747 21.3576 28.9343C18.7994 29.9939 15.9845 30.2712 13.2687 29.731C10.553 29.1908 8.05844 27.8574 6.10051 25.8995C4.14257 23.9416 2.8092 21.447 2.26901 18.7313C1.72881 16.0155 2.00606 13.2006 3.06569 10.6424C4.12531 8.08427 5.91973 5.89777 8.22202 4.35943C10.5243 2.82109 13.2311 2 16 2L16 5.09342C13.8429 5.09342 11.7342 5.73308 9.94063 6.93151C8.14705 8.12994 6.74913 9.83332 5.92364 11.8262C5.09814 13.8192 4.88216 16.0121 5.30299 18.1278C5.72382 20.2434 6.76257 22.1868 8.28789 23.7121C9.8132 25.2374 11.7566 26.2762 13.8722 26.697C15.9879 27.1178 18.1808 26.9019 20.1738 26.0764C22.1667 25.2509 23.8701 23.8529 25.0685 22.0594C26.2669 20.2658 26.9066 18.1571 26.9066 16H30Z' />
    </mask>
    <path
      d='M30 16C30 18.7689 29.1789 21.4757 27.6406 23.778C26.1022 26.0803 23.9157 27.8747 21.3576 28.9343C18.7994 29.9939 15.9845 30.2712 13.2687 29.731C10.553 29.1908 8.05844 27.8574 6.10051 25.8995C4.14257 23.9416 2.8092 21.447 2.26901 18.7313C1.72881 16.0155 2.00606 13.2006 3.06569 10.6424C4.12531 8.08427 5.91973 5.89777 8.22202 4.35943C10.5243 2.82109 13.2311 2 16 2L16 5.09342C13.8429 5.09342 11.7342 5.73308 9.94063 6.93151C8.14705 8.12994 6.74913 9.83332 5.92364 11.8262C5.09814 13.8192 4.88216 16.0121 5.30299 18.1278C5.72382 20.2434 6.76257 22.1868 8.28789 23.7121C9.8132 25.2374 11.7566 26.2762 13.8722 26.697C15.9879 27.1178 18.1808 26.9019 20.1738 26.0764C22.1667 25.2509 23.8701 23.8529 25.0685 22.0594C26.2669 20.2658 26.9066 18.1571 26.9066 16H30Z'
      stroke='currentColor'
      strokeWidth='6'
      mask='url(#path-1-inside-1_1689_1755)'
    />
    <path
      d='M15.0767 10.2199C15.4183 9.39864 16.5817 9.39864 16.9233 10.2199L17.8815 12.5236C18.0255 12.8698 18.3511 13.1064 18.7249 13.1363L21.2119 13.3357C22.0985 13.4068 22.458 14.5133 21.7825 15.092L19.8877 16.7151C19.6029 16.9591 19.4785 17.3418 19.5655 17.7066L20.1444 20.1335C20.3508 20.9987 19.4096 21.6825 18.6505 21.2189L16.5213 19.9184C16.2012 19.7229 15.7988 19.7229 15.4787 19.9184L13.3495 21.2189C12.5904 21.6825 11.6492 20.9987 11.8556 20.1335L12.4345 17.7066C12.5215 17.3418 12.3971 16.9591 12.1123 16.7151L10.2175 15.092C9.54197 14.5133 9.90149 13.4068 10.7881 13.3357L13.2751 13.1363C13.6489 13.1064 13.9745 12.8698 14.1185 12.5236L15.0767 10.2199Z'
      fill='currentColor'
    />
  </svg>
);

export const Money = ({ className = "", ...rest }: Props): ReactElement => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' className={cx(styles.icon, className)}>
    <rect x='5' y='7' width='22' height='3' fill='currentColor' />
    <path
      d='M5 13H27V22H15.4363L12.3427 27.3582L9.73657 25.8535L10.116 25.1963C10.1372 25.1627 10.1578 25.1286 10.1779 25.0939L12.8248 20.5092C13.6532 19.0743 13.1616 17.2396 11.7267 16.4111C10.2919 15.5827 8.45709 16.0743 7.62866 17.5092L5.0359 22H5V13Z'
      fill='currentColor'
    />
  </svg>
);

export const MoneyAndPoints = ({ className = "", ...rest }: Props): ReactElement => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' className={cx(styles.icon, className)}>
    <path d='M10 19L30 19L30 27L10 27L10 19Z' fill='currentColor' />
    <path d='M17.5 13L30 13L30 16L18 16L17.5 13Z' fill='currentColor' />
    <path
      d='M9.07668 4.71993C9.41827 3.89864 10.5817 3.89864 10.9233 4.71993L11.8815 7.02358C12.0255 7.36981 12.3511 7.60638 12.7249 7.63635L15.2119 7.83573C16.0985 7.90681 16.458 9.01332 15.7825 9.59199L13.8877 11.2151C13.6029 11.4591 13.4785 11.8418 13.5655 12.2066L14.1444 14.6335C14.3508 15.4987 13.4096 16.1825 12.6505 15.7189L10.5213 14.4184C10.2012 14.2229 9.79876 14.2229 9.47875 14.4184L7.34954 15.7189C6.59044 16.1825 5.64919 15.4987 5.85558 14.6335L6.43448 12.2066C6.52148 11.8418 6.39711 11.4591 6.11232 11.2151L4.2175 9.59199C3.54197 9.01332 3.90149 7.90681 4.78815 7.83573L7.27513 7.63635C7.64892 7.60638 7.97453 7.36981 8.11854 7.02358L9.07668 4.71993Z'
      fill='currentColor'
    />
  </svg>
);

export const Add = ({ className = "", ...rest }: Props): ReactElement => (
  <svg width='1em' height='1em' viewBox='0 0 34 34' fill='none' className={cx(styles.icon, className)}>
    <circle cx='17' cy='17' r='17' fill='currentColor' />
    <rect x='16' y='10' width='2' height='14' fill='white' />
    <rect x='24' y='16' width='2' height='14' transform='rotate(90 24 16)' fill='white' />
  </svg>
);

export const Remove = ({ className = "", ...rest }: Props): ReactElement => (
  <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' className={cx(styles.icon, className)}>
    <circle cx='10' cy='10' r='10' fill='currentColor' />
    <rect x='6' y='9' width='8' height='2' fill='white' />
  </svg>
);

export const Chevron = ({ className = "", ...rest }: Props): ReactElement => (
  <svg width='0.625em' height='1em' viewBox='0 0 10 16' fill='none' className={cx(styles.icon, className)} {...rest}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.77817 0L0 7.77817L1.79205e-05 7.77819L2.12132 9.8995L7.77819 15.5564L9.89951 13.435L4.24264 7.77817L9.8995 2.12132L7.77817 0Z'
      fill='currentColor'
    />
  </svg>
);

export const Arrow = ({ className = "", ...rest }: Props): ReactElement => (
  <svg width='1em' height='0.83em' viewBox='0 0 18 15' fill='none' className={cx(styles.icon, className)} {...rest}>
    <g clipPath='url(#clip0_1204_1857)'>
      <path
        d='M10.5551 0.571899L9.24649 1.92687L13.6506 6.47023L0.853401 6.47023L0.853401 8.38403L13.5394 8.38403L9.13528 12.9312L10.4476 14.2862L17.1428 7.37354L10.5551 0.571899Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_1204_1857'>
        <rect width='13.7143' height='16.2857' fill='currentColor' transform='translate(17.1428 0.571899) rotate(90)' />
      </clipPath>
    </defs>
  </svg>
);

export const Leave = ({ className = "", ...rest }: Props): ReactElement => (
  <svg width='1em' height='1em' viewBox='0 0 27 26' fill='none' className={cx(styles.icon, className)} {...rest}>
    <rect x='11' width='3' height='9' fill='currentColor' />
    <rect x='11' y='20' width='3' height='6' fill='currentColor' />
    <rect x='24' width='3' height='26' fill='currentColor' />
    <rect x='18' y='13' width='3' height='14' transform='rotate(90 18 13)' fill='currentColor' />
    <rect x='6.36395' y='8' width='3' height='9' transform='rotate(45 6.36395 8)' fill='currentColor' />
    <rect x='8.48526' y='18.6064' width='3' height='9' transform='rotate(135 8.48526 18.6064)' fill='currentColor' />
    <rect x='27' width='3' height='16' transform='rotate(90 27 0)' fill='currentColor' />
  </svg>
);

export const Occupied = ({ className = "", ...rest }: Props): ReactElement => (
  <svg width='1em' height='1em' viewBox='0 0 19 18' fill='none' className={cx(styles.icon, className)} {...rest}>
    <ellipse cx='9.5' cy='9' rx='9.5' ry='9' fill='currentColor' />
    <rect x='12.6666' y='8' width='2' height='6.33333' transform='rotate(90 12.6666 8)' fill='white' />
  </svg>
);

export const Delete = ({ className = "", ...rest }: Props): ReactElement => (
  <svg width='1em' height='1em' viewBox='0 0 18 18' fill='none' className={cx(styles.icon, className)} {...rest}>
    <circle cx='9' cy='9' r='9' transform='rotate(90 9 9)' fill='currentColor' />
    <g clipPath='url(#clip0_1162_3566)'>
      <path
        d='M13 6.43103L11.573 5L9.00202 7.571L6.43103 5L5 6.43103L7.571 9.00202L5 11.573L6.43103 13L9.00202 10.429L11.573 13L13 11.573L10.429 9.00202L13 6.43103Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_1162_3566'>
        <rect width='8' height='8' fill='white' transform='translate(5 5)' />
      </clipPath>
    </defs>
  </svg>
);

export const Invoice = ({ className = "", ...rest }: Props): ReactElement => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' className={cx(styles.icon, className)} {...rest}>
    <path
      d='M20.4697 28.8002H6.3999V3.2002H26.4634V22.7964L20.4697 28.8002ZM8.93958 26.2605H19.4132L23.9237 21.75V5.73988H8.93958V26.2605Z'
      fill='currentColor'
    />
    <path d='M19.1897 26.6468V21.5166H24.3097L19.1897 26.6468Z' fill='currentColor' />
    <path d='M21.0996 8.49316H11.459V10.5249H21.0996V8.49316Z' fill='currentColor' />
    <path d='M21.0996 12.5464H11.459V14.5781H21.0996V12.5464Z' fill='currentColor' />
    <path d='M16.9549 16.4878H11.459V18.5195H16.9549V16.4878Z' fill='currentColor' />
  </svg>
);

export const Checkmark = ({ className = "", ...rest }: Props): ReactElement => (
  <svg width='1em' height='1em' viewBox='0 0 12 12' fill='none' className={cx(styles.icon, className)} {...rest}>
    <rect
      x='-0.000488281'
      y='8.57129'
      width='2.42438'
      height='4.84876'
      transform='rotate(-45 -0.000488281 8.57129)'
      fill='currentColor'
    />
    <rect
      x='10.2856'
      y='1.71436'
      width='2.42438'
      height='12.1219'
      transform='rotate(45 10.2856 1.71436)'
      fill='currentColor'
    />
  </svg>
);

export const PointArrow = ({ className = "", ...rest }: Props): ReactElement => (
  <svg width='0.875em' height='1em' viewBox='0 0 16 14' fill='none' className={cx(styles.icon, className)} {...rest}>
    <path
      d='M9.52789 -2.82905e-07L8.24221 1.3832L12.5691 6.02121L-0.00363948 6.02121L-0.00363957 7.97488L12.4598 7.97488L8.13294 12.6168L9.42226 14L16 6.94334L9.52789 -2.82905e-07Z'
      fill='currentColor'
    />
  </svg>
);

const Icon = {
  Close,
  EyeShow,
  EyeHide,
  Male,
  Female,
  Information,
  Direction,
  Points,
  Money,
  MoneyAndPoints,
  Add,
  Remove,
  Chevron,
  Arrow,
  Leave,
  Occupied,
  Delete,
  Invoice,
  Checkmark,
  PointArrow,
};

export default Icon;
