import cx from "classnames";
import React, {
  ChangeEvent,
  HTMLAttributes,
  ReactElement,
  useRef,
  useState,
} from "react";
import styles from "./index.module.css";

export interface Props extends HTMLAttributes<HTMLDivElement> {
  items?: number;
  onValueChange?: (values: string[]) => void;
  validPin?: boolean;
  resetValidation: () => void;
}

const DigitsInput = ({
  children,
  className,
  items = 4,
  validPin,
  onValueChange,
  resetValidation,
  ...rest
}: Props): ReactElement => {
  const inputRef = useRef<HTMLInputElement[] | null[]>([]);
  const [enteredValues, setEnteredValue] = useState<string[]>([]);

  const onChange = (index: number, value: string) => {
    if (value.length > 1) {
      value = value[0];
    }
    if (value.length) {
      inputRef.current[index + 1]?.focus();
    }
    const values: string[] = [];
    inputRef.current.forEach((element) => {
      if (element?.value.length) values.push(element.value);
    });

    if (onValueChange) onValueChange(values);

    setEnteredValue(values);
  };

  const onFocus = (enteredValue: string) => {
    if (enteredValue.length) {
      setEnteredValue(["", "", "", ""]);
      resetValidation();
    }
  };

  return (
    <div className={styles.digitsInput}>
      {[...Array(items).keys()].map((index) => (
        <input
          type="text"
          className={cx(styles.input, { [styles.invalid]: !validPin })}
          placeholder=""
          key={index}
          ref={(el) => (inputRef.current[index] = el)}
          maxLength={1}
          name={`digit_${index}`}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            onChange(index, event.target.value);
          }}
          onFocus={(event: ChangeEvent<HTMLInputElement>) => {
            onFocus(event.target.value);
          }}
          value={enteredValues[index]}
        />
      ))}
    </div>
  );
};

export default DigitsInput;
