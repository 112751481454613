import { zodResolver } from "@hookform/resolvers/zod";
import React, { ReactElement, ReactNode } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import CopyManager from "../../../../utils/CopyManager";
import Button from "../../atoms/Button";
import Input from "../../atoms/Input";
import PasswordInput from "../../atoms/PasswordInput";
import Form, { Props as FormProps } from "../../molecules/Form";

export const schema = z.object({
  email: z.string().email("Email is invalid.").nonempty("Email is required."),
  password: z.string().nonempty("Password is required."),
});

export type Schema = z.infer<typeof schema>;

export interface Props extends FormProps {
  children?: ReactNode;
  className?: string;
  onComplete: (values: Schema) => void;
}

const LoginForm = ({ children, className, onComplete, ...rest }: Props): ReactElement => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Schema>({
    mode: "onTouched",
    resolver: zodResolver(schema),
  });

  return (
    <Form.Styled
      onSubmit={handleSubmit(onComplete)}
      footer={<Button type='submit'>{CopyManager.get("login_action_login")}</Button>}
      {...rest}>
      <Input label={CopyManager.get("login_label_email")} {...register("email")} error={errors.email?.message} />
      <PasswordInput label={CopyManager.get("login_label_password")} {...register("password")} error={errors.password?.message} />
    </Form.Styled>
  );
};
export default LoginForm;
