import cx from "classnames";
import React, { HTMLAttributes, ReactElement } from "react";
import styles from "./index.module.css";

export interface Props extends HTMLAttributes<HTMLButtonElement> {
  className?: string;
}

export const Burger = ({ className = "", ...rest }: Props): ReactElement => (
  <button className={cx(styles.burger, className)} type='button' {...rest} />
);

export default Burger;
