import { zodResolver } from "@hookform/resolvers/zod";
import React, { forwardRef, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import CopyManager from "../../../../../utils/CopyManager";
import Button from "../../../atoms/Button";
import Icon from "../../../atoms/Icon";
import Input from "../../../atoms/Input";
import Radio from "../../../atoms/Radio";
import Typography from "../../../atoms/Typography";
import AutoComplete from "../../../molecules/AutoComplete";
import Form from "../../../molecules/Form";
import styles from "./index.module.css";

export const schema = z.object({
  firstname: z.string().nonempty("First name required"),
  lastname: z.string().nonempty("Last name required"),
  birthdate: z.date({
    invalid_type_error: "Invalid date",
    required_error: "Invalid date",
  }),
  gender: z
    .string({
      invalid_type_error: "Sex is required",
      required_error: "Sex is required",
    })
    .nonempty("Sex is required"),
  bonus_program: z.string().optional(),
  bonus_program_number: z.number().optional(),
  bonus_number: z.string().optional(),
});

export type Schema = z.infer<typeof schema>;

export interface Imperative {
  validate: () => Promise<boolean>;
  values: () => Schema;
}

export interface Props {
  index: number;
  onDelete?: (index: number) => void;
  bonusPrograms: Array<{ name: string }>;
}

const Traveller = forwardRef<Imperative, Props>((props, ref) => {
  const { index, onDelete, ...rest } = props;

  const {
    trigger,
    getValues,
    setValue,
    register,
    formState: { errors },
  } = useForm<Schema>({
    mode: "onTouched",
    resolver: zodResolver(schema),
  });

  useImperativeHandle(ref, () => ({
    async validate() {
      return await trigger();
    },
    values() {
      return getValues();
    },
  }));

  return (
    <>
      <Typography.Title level={4} className={styles.title}>
        {CopyManager.get("traveller_title")} {index}
        {onDelete && (
          <Button
            icon={<Icon.Remove className={styles.removeIcon} />}
            className={styles.removeBtn}
            onClick={() => onDelete(index)}
          >
            {CopyManager.get("delete")}
          </Button>
        )}
      </Typography.Title>
      <Form.Styled columns={2} {...rest}>
        <Input
          label={CopyManager.get("traveller_label_firstname")}
          {...register("firstname")}
          error={errors.firstname?.message}
        />
        <Input
          label={CopyManager.get("traveller_label_lastname")}
          {...register("lastname")}
          error={errors.lastname?.message}
        />
        <Input
          label={CopyManager.get("traveller_label_birthdate")}
          {...register("birthdate", { valueAsDate: true })}
          type="date"
          max="2999-12-31"
          error={errors.birthdate?.message}
        />

        <Form.Item error={errors.gender?.message}>
          <div className={styles.gender}>
            <Typography.Label as="span">{CopyManager.get("sex")}</Typography.Label>
            <Radio.Group name="gender" buttonStyle="solid">
              <Radio.Button
                value="female"
                {...register("gender")}
                icon={<Icon.Female />}
                className={styles.genderBtn}
              />
              <Radio.Button
                value="male"
                {...register("gender")}
                icon={<Icon.Male />}
                className={styles.genderBtn}
              />
            </Radio.Group>
          </div>
        </Form.Item>

        <AutoComplete
          label={CopyManager.get("traveller_label_bonusprogram")}
          {...register("bonus_program")}
          error={errors.bonus_program?.message}
          items={props.bonusPrograms.map((bp, index) => {
            return { label: bp.name, value: index };
          })}
          onSelectItem={(item) => {
            setValue("bonus_program", item.label);
            setValue("bonus_program_number", item.value as number);
          }}
          autoComplete="off"
          secondary={true}
        />
        <Input
          label={CopyManager.get("traveller_label_bonuscode")}
          {...register("bonus_number")}
          error={errors.bonus_number?.message}
        />
      </Form.Styled>
    </>
  );
});

export default Traveller;
