import React, { ChangeEvent, FocusEvent, forwardRef, ReactElement, useRef, useState } from "react";
import useClickOutside from "../../../../hooks/useClickOutside";
import Input, { Props as InputProps } from "../../atoms/Input";
import Dropdown, { Props as DropdownProps, Item } from "../Dropdown";
import styles from "./index.module.css";

export interface Props extends InputProps, DropdownProps {}

const AutoComplete = forwardRef<HTMLInputElement, Props>((props, ref): ReactElement => {
  const { items, onChange, open, onSelectItem, onFocus, ...rest } = props;
  const [dropdownOpen, setDropdownOpen] = useState(open);
  const [dropdownItems, setDropdownItems] = useState(items);
  const [currentValue, setCurrentValue] = useState(rest.value);

  const wrapperRref = useRef<HTMLDivElement | null>(null);

  const searchValues = (value: string) => {
    if (value) {
      setDropdownItems(
        items.filter((item) => {
          return item.label.toLowerCase().includes(value.toLowerCase());
        })
      );
      setDropdownOpen(true);
    } else {
      setDropdownOpen(false);
    }
  };

  const onChangeInputValues = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCurrentValue(value);
    searchValues(value);
    if (onChange) onChange(event);
  };

  const onFocusInput = (event: FocusEvent<HTMLInputElement>) => {
    searchValues((currentValue ?? "")?.toString());
    if (onFocus) onFocus(event);
  };

  const onDropdownSelectItem = (item: Item) => {
    setDropdownOpen(false);
    onSelectItem(item);
  };

  useClickOutside(wrapperRref, () => setDropdownOpen(false));

  return (
    <div className={styles.autoComplete} ref={wrapperRref}>
      <Input
        ref={ref}
        className={styles.autoCompleteInput}
        onChange={onChangeInputValues}
        onFocus={onFocusInput}
        {...rest}
      />
      <div className={styles.dropdown}>
        <Dropdown items={dropdownItems} onSelectItem={onDropdownSelectItem} {...rest} open={dropdownOpen} />
      </div>
    </div>
  );
});

export default AutoComplete;
